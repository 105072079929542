import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { BehaviorSubject, merge, of as observableOf } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { fadeInRight400ms } from "src/@core/animations/fade-in-right.animation";
import { fadeInUp400ms } from "src/@core/animations/fade-in-up.animation";
import { stagger40ms } from "src/@core/animations/stagger.animation";
import { Attendance } from "src/@core/interfaces/job/attendance.model";
import { PaymentAttendance } from "src/@core/interfaces/payment/payment-attendance.model";
import { Payment } from "src/@core/interfaces/payment/payment.model";
import { Rating } from "src/@core/interfaces/rating/rating.model";
import { Search } from "src/@core/interfaces/search";
import { AppService } from "src/@core/services/app.service";
import { AttendanceService } from "src/@core/services/job/attendance.service";
import { NotificationService } from "src/@core/services/notification.service";
import { PaymentService } from "src/@core/services/payment/payment.service";
import { JobSeekerRatingService } from "src/@core/services/rating/job-seeker-rating.service";
import { PaymentStatusDialogComponent } from "src/app/modules/payments/payment-status-dialog/payment-status-dialog.component";
import { SelectionModel } from "@angular/cdk/collections";
import { JobApplicationService } from "src/@core/services/job/job-application.service";
import { STATUS } from "src/app/shared/helpers/status";
import { roles } from "src/app/shared/helpers/roles";
import { EmploymentService } from "src/@core/services/employment/employment.service";
import { Employment } from "src/@core/interfaces/employment/employment.model";

@Component({
  selector: 'hour4u-admin-view-job-seeker-payments',
  templateUrl: './view-job-seeker-payments.component.html',
  styleUrls: ['./view-job-seeker-payments.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms,
    fadeInRight400ms]
})
export class ViewJobSeekerPaymentsComponent implements OnInit {
  employmentId: string;
  employmentTitle: string;
  employerId: string;
  jobTitle: string;
  date: any;
  searchParams: Search[] = [];
  searchFilters: Search[] = [];
  dataSourcePayment: MatTableDataSource<Payment> | null;
  dataSource: MatTableDataSource<PaymentAttendance> | null;
  dataChange: BehaviorSubject<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  isLoading: boolean = false;
  mode = 'indeterminate';
  diameter = 30;
  color: string;
  applicationPendingSectionDisplayStyle: string = "none";

  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  resultsLength = 0;
  selection = new SelectionModel<PaymentAttendance>(true, []);
  columns = [
    // { columnDef: 'checkbox', visible: true, label: '' },
    { columnDef: 'jobSeeker', visible: true, label: 'Job Seeker' },
    { columnDef: 'checkIn', visible: true, label: 'Login Time' },
    { columnDef: 'checkOut', visible: true, label: 'Logout Time' },
    { columnDef: 'totalRecordedTime', visible: true, label: 'Hours Worked' },
    { columnDef: 'amount', visible: true, label: 'Actual Amount' },
    { columnDef: 'expectedAmount', visible: true, label: 'Expected Amount' },
    { columnDef: 'settledAmount', visible: true, label: 'Settled Amount' },
    { columnDef: 'status', visible: true, label: 'Status' },
    { columnDef: 'rate', visible: true, label: 'Rating' },
    { columnDef: 'action', visible: true, label: 'Action' },
  ];
  displayedColumns = this.columns.map(x => x.columnDef);
  currentUserRole: any = null;
  starList: boolean[] = [true, true, true, true, true];
  level = '';
  rate: number;
  status = STATUS;
  roles = roles;
  employment: Employment;
  disablePaidBtn: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentService,
    private attendanceService: AttendanceService,
    private jobSeekerRatingService: JobSeekerRatingService,
    private notify: NotificationService,
    private dialog: MatDialog,
    private appService: AppService,
    private jobApplicationService: JobApplicationService,
    private employmentService: EmploymentService
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.employmentId = this.route.snapshot.paramMap.get('id');
    this.loadEmploymentData(this.employmentId);
    this.route.queryParams.subscribe(params => {
      this.jobTitle = params['jobTitle'];
      this.employmentTitle = params['employmentTitle'];
      this.employerId = params['employerId'];
      this.date = params['date'];
    });

    this.searchParams = [];
    this.searchParams.push(new Search('employmentId', this.employmentId));
    this.dataChange = new BehaviorSubject(0);
    this.dataSource = new MatTableDataSource();
    this.dataSourcePayment = new MatTableDataSource();

    this.appService.currentUser.subscribe(currentUser => {
      currentUser.roles.forEach(ele => {
        this.currentUserRole = ele;
      })
    })
  }

  async loadEmploymentData(id: string) {
    await this.employmentService.getById(id).toPromise()
      .then(data => {
        this.employment = data;
        console.log(this.employment)
      })
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.dataSource.sort = this.sort;
    var paySize = 0;
    merge(this.sort.sortChange, this.paginator.page, this.dataChange)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.paymentService.paymentTrackingByStatus(this.paginator.pageIndex, this.paginator.pageSize, 'all', [...this.searchParams]);
        }),
        map(data => {
          paySize = data.totalElements;
          return data.content;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.dataSource.data = data;
        let dl = this.dataSource.data.length;

        data.forEach(row => {
          if (row.jobApplicationStatus == 'APPROVED' || row.jobApplicationStatus == 'COMPLETED') {
            let pa: PaymentAttendance = new PaymentAttendance();
            pa.jobSeekerId = row.jobSeekerId;
            pa.jobSeekerName = row.jobSeekerName;
            pa.employmentId = row.employmentId;
            pa.employerId = this.employerId;
            pa.checkIn = row.checkIn;
            pa.checkOut = row.checkOut;
            pa.totalRecordedTime = row.totalRecordedTime;
            pa.id = row.id;
            pa.amount = row.amount;
            pa.expectedAmount = row.expectedAmount;
            pa.settledAmount = row.settledAmount;
            pa.status = row.status;
            pa.rate = row.rate;
            pa.ratingId = row.ratingId;
            this.dataSource.data.push(pa);
          }
        });
        console.log(this.dataSource.data);
        this.resultsLength = this.dataSource.data.length;
        this.applicationPendingSectionDisplayStyle = "none";
        //document.getElementById('application-pending-section').style.display = 'none';
        if (this.dataSource.data && this.dataSource.data.length == 0) {
          this.applicationPendingSectionDisplayStyle = "block";
          //document.getElementById('application-pending-section').style.display = 'block';
        }
        this.dataSource._updateChangeSubscription();
        this.isLoading = false;
      });

  }




  /*this.jobApplicationService.getAppsWAttendance(this.employmentId).subscribe(res => {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.dataSource.sort = this.sort;

    var paySize = 0;
    merge(this.sort.sortChange, this.paginator.page, this.dataChange).pipe(
      startWith({}),
      switchMap(() => {
        return this.paymentService!.searchAndSort(
          this.paginator.pageIndex, this.paginator.pageSize,
          this.sort.active, this.sort.direction, [...this.searchParams, ...this.searchFilters]);
      }),
      map(data => {
        paySize = data.totalElements;
        return data.content;
      }),
      catchError(() => {
        return observableOf([]);
      })
    ).subscribe(data => {
      this.dataSourcePayment.data = data;

      let dl = this.dataSource.data.length;

      res.forEach(ja => {
        for (let x = 0; x < dl; x++) {
          if (ja.jobSeekerId == this.dataSource.data[x].jobSeekerId) {
            this.dataSource.data.splice(x, 1);
            break;
          }
        }

        if (ja.jobApplicationStatus == 'APPROVED' ||
          ja.jobApplicationStatus == 'COMPLETED') {
          let pa: PaymentAttendance = new PaymentAttendance();
          pa.jobSeekerId = ja.jobSeekerId;
          pa.employmentId = ja.employmentId;
          pa.employerId = this.employerId;
          pa.checkIn = ja.checkIn;
          pa.checkOut = ja.checkOut;
          pa.totalRecordedTime = ja.totalRecordedTime;

          for (let i = 0; i < paySize; i++) {
            if (ja.jobSeekerId == this.dataSourcePayment.data[i].jobSeekerId) {
              pa.id = this.dataSourcePayment.data[i].id;
              pa.amount = this.dataSourcePayment.data[i].amount;
              pa.expectedAmount = this.dataSourcePayment.data[i].expectedAmount;
              pa.settledAmount = this.dataSourcePayment.data[i].settledAmount;
              pa.status = this.dataSourcePayment.data[i].status;
              break;
            }
          }
          this.jobSeekerRatingService.getJSRatingForShift(pa.employmentId, pa.jobSeekerId).subscribe(rdata => {
            if (pa.id) {
              pa.rate = rdata != null ? rdata.rating : 5;
              pa.ratingId = rdata != null ? rdata.id : null;
            }
          });

          this.dataSource.data.push(pa);
        }
      });
      this.resultsLength = this.dataSource.data.length;
        document.getElementById('application-pending-section').style.display = 'none';
      if (this.dataSource.data && this.dataSource.data.length == 0) {
        document.getElementById('application-pending-section').style.display = 'block';
      }
      this.dataSource._updateChangeSubscription();
    });
  }); 

} */

  async updatePaymentStatus(row, status: string, forCash?: boolean) {
    this.disablePaidBtn = true;
    await this.paymentService.updatePaymentStatus(row.id, status, row.settledAmount, forCash).toPromise().then(data => {
      this.notify.showMessage('Payment Status Updated Successsfully !', 2000);
      this.ngAfterViewInit();
      this.disablePaidBtn = false;
    }).catch(err => {
      this.disablePaidBtn = false;
    });
  }

  openPaymentStatusDialog(data) {

    const dialogRef = this.dialog.open(PaymentStatusDialogComponent, {
      width: '400px',
      disableClose: true,
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngAfterViewInit();
    });
  }

  sortChange(event) {
    this.sort.active = 'createdOn';
    this.sort.direction = event.value;
    this.dataChange.next(1);
  }

  // Select all rows 
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  logSelection() {
    this.selection.selected.forEach(s => console.log(s.jobSeekerId));
  }

  // set single record rating
  setStar(index: any, data?: any) {
    for (var i = 0; i <= 4; i++) {
      if (i <= index) {
        data.starList[i] = false;
      }
      else {
        data.starList[i] = true;
      }
    }
    if (index > -1 && index < 2) this.level = 'Fair'
    else if (index > 1 && index < 4) this.level = 'Good'
    else this.level = 'Excellent'
    data.rating = index + 1;
  }

  dataRefresh() {
    this.router.navigateByUrl('/employment/all/all');
  }
}
