import { environment } from "src/environments/environment";

export const PREFIX = '/api';

const GAI_SERVICE = '/gaim-service';

// Using URLs from environment variables
const ORDER_SERVICE = environment.orderService;
const RATING_SERVICE = environment.ratingService;
export const API_AUTH_LOGIN = environment.authServiceLogin
export const API_AUTH = environment.authService;
const JOB_SERVICE = environment.jobService;
const NOTIFICATION_SERVICE = environment.notificationService;
const JOB_SEEKER_SERVICE = environment.jobSeekerService;
export const API_ENTERPRISE_ADMIN = environment.enterpriseAdminService;


export const API_ACCOUNT = API_AUTH + '/v1/account';
export const API_ACCOUNT_CREDIT = API_AUTH + '/v1/enterprise/entity/usage';
export const API_EMPLOYER = API_AUTH + '/v1/employer';
export const API_EMPLOYER_UPLOAD = API_AUTH + '/v1/fileupload/employer';
export const API_EMPLOYER_CONTRACT = API_AUTH + '/v1/fileupload/EMPLOYER_CONTRACT';
export const API_EMPLOYER_SIGNATURE = API_AUTH + '/v1/fileupload/employer/signature';
export const API_EMPLOYER_SIGN_CONTRACT = API_AUTH + '/v1/employer/sign-contract';
export const API_ENTERPRISE_SIGN_CONTRACT = API_AUTH + '/v1/enterprise/sign-contract';

export const API_CREATE_ENTITY = API_AUTH + '/v1/enterprise/entity';
export const API_UPDATE_ENTITY_PROFILE = API_AUTH + '/v1/enterprise/edit-entity-profile';
export const API_UPDATE_ENTITY = API_AUTH + '/v1/enterprise/entity/update';
export const API_ADD_AGENCY = API_AUTH + '/v1/enterprise/add-entity/';
export const API_ADD_CHAMP = API_AUTH + '/v1/enterprise/add-entity';
export const API_ADD_TEAM_MEMBER = API_AUTH + '/v1/enterprise/user-mapping';
export const API_APPROVE_TEAM_MEMBER = API_AUTH + '/v1/enterprise/user_mapping';
export const API_APPROVE_AGENCY = API_AUTH + '/v1/enterprise/entity_mapping/';
export const API_FETCH_AGENCY = API_AUTH + '/v1/enterprise/entity_mapping';
export const API_FETCH_TEAM_MEMBER = API_AUTH + '/v1/enterprise/user_mapping';
export const API_FETCH_TEAM_MEMBER_V2 = API_AUTH + '/v1/enterprise/user_mapping_v2';
export const API_FETCH_VENDOR = API_AUTH + '/v1/enterprise/entity_mapping';
export const SEND_OTP = API_AUTH + '/v1/enterprise/otp';
export const VERIFY_OTP_WITH_DETAILS = API_AUTH + '/v1/enterprise/v2/otp/verify';
export const CHANGE_RESET_PASSWORD = API_AUTH + '/v1/enterprise/verify-reset-password';

// Job SERVICE

export const API_JOB = JOB_SERVICE + '/v1/job';
export const API_MULTIPLE_PICTURE_UPLOAD = JOB_SERVICE + '/v1/fileupload/workOrderhistory/mulitple-uploads';
export const API_JOB_APPLICATION = JOB_SERVICE + '/v1/jobapplication';
export const API_JOB_APPLICATION_V2 = JOB_SERVICE + '/v2/jobapplication';
export const API_EMPLOYEE_ATTENDANCE = JOB_SERVICE + '/v1/employmentAttendance';
export const API_TIMESHEET = JOB_SERVICE + '/v1/employmentAttendance/time-sheet';
export const API_TIMESHEET_FILTER = JOB_SERVICE + '/v1/employmentAttendance/time-sheet/filter';
export const API_JOB_CATEGORY = JOB_SERVICE + '/v1/jobCategory';
export const API_JOB_TYPE = JOB_SERVICE + '/v1/jobType';
export const API_MY_JOB = JOB_SERVICE + '/v1/mobile/myjobs';
export const API_STATS = JOB_SERVICE + '/v1/stats';
export const API_WORKLOG = JOB_SERVICE + '/v1/worklog';
export const API_CANCEL_SHIFT = JOB_SERVICE + '/v1/employment/cancel';
export const API_CHECKOUT_SHIFT = JOB_SERVICE + '/v1/employment/checkout-link';
export const API_FETCH_DASHBOARD = JOB_SERVICE + '/v1/enterprise/workOrder/getAllWorkOrder';
export const API_ACTIVATION_PROOF = JOB_SERVICE + '/v1/workOrder/activation-proof/';
export const UploadAttendancePicture = JOB_SERVICE + '/v1/fileupload/workOrderhistory';
export const SaveAttendanceImgProof = JOB_SERVICE + '/v1/employmentHistory/imageproof';
export const SaveAttendanceImgProofs = JOB_SERVICE + '/v1/employmentHistory/imageproofs';
export const UpdateAttendanceImgProof = JOB_SERVICE + '/v1/employmentHistory/updateimageproof';
export const UpdateAttendanceImgProofStatus = JOB_SERVICE + '/v1/employmentHistory/update-status';
export const DeleteAttendanceImgProof = JOB_SERVICE + '/v1/employmentHistory/deleteimageproof';
export const GET_QUOTATION = JOB_SERVICE + '/v1/job/quotation';
export const GET_PO = JOB_SERVICE + '/v1/job/purchase-order';
export const GET_PROJECTS_BY_ENTITYID = JOB_SERVICE + '/v1/job/assigned-jobs';
export const UPDATE_QUOTATION_STATUS = JOB_SERVICE + '/v1/job/quotation/updateStatus';
export const UPDATE_PO_STATUS = JOB_SERVICE + '/v1/job/purchase-order/updateStatus';
export const GET_BILLINGS = JOB_SERVICE + '/v1/billing';
export const FETCH_BILLING_SUMMARY = JOB_SERVICE + '/v1/billing/summary';
export const FETCH_SIGNED_DOCUMENT = JOB_SERVICE + '/v1/job/create-contract-doc';

// Employment
export const API_EMPLOYMENT = JOB_SERVICE + '/v1/employment';
export const API_EMPLOYMENT_LIST = JOB_SERVICE + '/v1/employment/getAllEmployment';
export const API_EMPLOYMENT_HISTORY = JOB_SERVICE + '/v1/employmentHistory';
export const API_EMPLOYMENT_HISTORY_COORDINATES = API_EMPLOYMENT_HISTORY + '/coordinates';
export const API_EMPLOYMENT_ADMINISTRATION = JOB_SERVICE + '/v1/employment/admin';
export const API_EMPLOYMENT_ASSIGN_JOB_ADMIN = JOB_SERVICE + '/v1/job/assignment/fulfiler';
export const API_ASSIGN_ENTITY_IN_PROJECT = JOB_SERVICE + '/v1/job/link/jobentity';

export const API_EMPLOYMENT_GET_JOB_ADMIN = JOB_SERVICE + '/v1/job/assignment/fulfilment';
export const API_GET_ASSIGNED_ADMINS = JOB_SERVICE + '/v1/job/assignment/details';

// GAI API

export const API_GAI = PREFIX + GAI_SERVICE + '/v1/customai/chat';

// Job Seeker SERVICE

export const API_JOB_SEEKER = JOB_SEEKER_SERVICE + '/v1/jobseeker';
export const API_SEEKER_PREFERENCE = JOB_SEEKER_SERVICE + '/v1/jobpreference';
export const CREATE_EMPLOYEMENT_ORDER = ORDER_SERVICE + '/api/v1/order';
// Payment
export const API_PAYMENT = JOB_SEEKER_SERVICE + '/v1/payment';
export const API_RECHARGE_REQUESTS = ORDER_SERVICE + PREFIX + '/v1/payment/enterprise/wallet/recharge';
export const API_WALLET_BALANCE = ORDER_SERVICE + PREFIX + '/v1/payment/enterprise/wallet';
export const API_PAYOUT = ORDER_SERVICE + '/api/v1/payment/enterprise/payout';
export const BANK_ACCOUNT_DETAILS = API_AUTH + '/v1/enterprise/account-details';
export const PAYMENT_VERIFY_OTP = API_AUTH + '/v1/enterprise/payment/verify/otp';
export const PAYMENT_VIA_CREDIT = ORDER_SERVICE + PREFIX + '/v1/payment/enterprise/wallet-transfer';
export const DOWNLOAD_CREDITS = ORDER_SERVICE + PREFIX + '/v1/payment/enterprise/wallet/recharge/download';
export const DOWNLOAD_WITHDRAWAL_STATEMENT = ORDER_SERVICE + PREFIX + '/v1/payment/enterprise/payout/download';


// Work Log
export const API_FETCH_WORKLOG = JOB_SERVICE + '/v1/worklog';

// Profile Status Detail
export const API_PROFILE_STATUS_DETAIL = JOB_SEEKER_SERVICE + '/v1/profileStatusDetail';

// Rating

export const API_EMPLOYER_RATING = PREFIX + RATING_SERVICE + '/v1/employer';
export const API_JOB_SEEKER_RATING = PREFIX + RATING_SERVICE + '/v1/jobseeker';

/** Notification SERVICE */

export const API_NOTIFICATION = NOTIFICATION_SERVICE + '/v1/notification';

//Invoice
export const API_INVOICE = JOB_SERVICE + '/v1/invoice';
export const API_INVOICE_SUMMARY = JOB_SERVICE + '/v1/invoice/summary';
export const API_INVOICE_BY_ID = JOB_SERVICE + '/v1/invoice/unsecured';
export const API_BULK_INVOICES_PAY = ORDER_SERVICE + '/api/v1/order/bulk/invoice';
export const API_INVOICE_SETTLE = JOB_SERVICE + '/v1/invoice/settle';

//Supervisor Admin
export const REFERRAL_SERVICE = '/referral-service';
export const API_CHAMP = PREFIX + REFERRAL_SERVICE + '/v1/champ';
export const API_REFERRAL = PREFIX + REFERRAL_SERVICE + '/v1/referral';
export const API_COMMISSION = PREFIX + REFERRAL_SERVICE + '/v1/commission';
export const API_GET_JOBSUPERVISORS = API_AUTH + '/v2/account';
export const API_GET_JOBSUPERVISOR_BY_ID = JOB_SEEKER_SERVICE + '/v1/jobseeker/fetch-job-seeker-summary-by-ref-ids';
export const API_GET_JOBSUPERVISOR_EARNING_COMMISSION = PREFIX + REFERRAL_SERVICE + '/v1/commission/summary';


// JOB ADMIN 
export const ASSIGN_CHAMP = JOB_SERVICE + '/v1/job/assignment/fulfilment';


//Property
export const API_PROPERTY = JOB_SERVICE + '/v1/enterprise/property';
export const API_PROPERTY_FOR_ENTERPRISES = JOB_SERVICE + '/v1/enterprise/property/search-societies';
export const API_BOOKING = API_PROPERTY + '/booking';
export const API_PROPERTY_DOCUMENT = API_AUTH + '/v1/fileupload/PROPERTY_DOCUMENTS';
export const API_PROPERTY_EXPORT = API_PROPERTY + '/download';
export const API_BOOK = API_PROPERTY + '/enquiry';
export const API_FETCH_BOOKINGS = API_PROPERTY + '/fetch-booking';
export const ADD_QUOTATION = API_PROPERTY + '/add-quotation';


// WORK ORDER
export const API_WORK_ORDER = API_ENTERPRISE_ADMIN + '/enterprise/workOrder';
export const API_DOWNLOAD_WO_FULFILMENT = API_ENTERPRISE_ADMIN + '/enterprise/workOrder/download-wo-fulfilment';
export const API_ADD_WO_FULFILMENT = API_ENTERPRISE_ADMIN + '/enterprise/workOrder/bulk-fulfillment';
export const API_WORK_ORDER_APPROVE = API_ENTERPRISE_ADMIN + '/workOrder/approve';
export const API_WORK_ORDER_AGENCY_APPROVE = API_ENTERPRISE_ADMIN + '/enterprise/workOrder/changeStatus';
export const API_WORK_ORDER_REJECT = API_ENTERPRISE_ADMIN + '/workOrder/reject';
export const API_WORK_ORDER_EXPORT = API_ENTERPRISE_ADMIN + '/enterprise/workOrder/download';
export const API_WORK_ORDER_GOOGLE_AUTHORIZE = API_ENTERPRISE_ADMIN + '/google-sheets/authorize';
export const API_WORK_ORDER_GOOGLE_CALLBACK = API_ENTERPRISE_ADMIN + '/google-sheets/oauth2callback';
export const API_FETTCH_ASSIGNED_VENDORS = API_ENTERPRISE_ADMIN + '/job/assigned-vendors';
export const API_BULK_WORK_ORDER = API_WORK_ORDER + '/excel'


export const API_GET_ROLES_BY_ENTITY_NAME = API_AUTH + '/v1/enterprise/role';
export const API_GET_SUMMARY = API_ENTERPRISE_ADMIN + '/enterprise/workOrder/summary';

export const UPLOAD_SIGNATURE = JOB_SERVICE + '/v1/fileupload/signature';

export const UploadAttendancePictureChunk = JOB_SERVICE + '/v1/fileupload/workOrderhistory/chunks/';
export const ProcessAttendancePictureChunk = JOB_SERVICE + '/v1/fileupload/workOrderhistory/processChunks/';

export const API_ADD_VENDOR = API_AUTH + '/v1/enterprise/add-entity/';


//Subscription-Plans
export const FETCH_ALL_SUBSCRIPTION_PLANS = API_AUTH + '/v1/subscription/plan';
export const API_SUBSCRIBE_PLAN = API_AUTH + '/v1/subscription';
export const API_ACTIVATE_SUBSCRIPTION = API_AUTH + '/v1/subscription/activate';
export const FETCH_CURRENT_USER_SUBSCRIPTION = API_AUTH + '/v1/subscription';


// Roles & Permissions
export const FETCH_PERMISSIONS = API_AUTH + '/v1/enterprise/role/permissions';
export const CRETE_ROLE_WITH_PERMISSIONS = API_AUTH + '/v1/enterprise/role';

// Project Type
export const API_PROJECT_TYPE = API_ENTERPRISE_ADMIN + '/enterprise/projectType';

// Services
export const API_SERVICES = API_ENTERPRISE_ADMIN + '/enterprise/services';
export const API_SERVICETYPES = API_SERVICES + '/service-type';

// Breifing Form 
export const API_BRIEFING_FORM = API_ENTERPRISE_ADMIN + '/enterprise/briefingForm';

// Breifing Form Values
export const API_BRIEFING_FORM_VALUES = API_ENTERPRISE_ADMIN + '/enterprise/briefingFormValues';

// Digital Portfolio
export const API_GET_MARKETPLACES = API_AUTH + '/v1/enterprise/digital-portfolios';
export const API_GET_DIGITAL_PORTFOLIOS_SERVICES = JOB_SERVICE + '/v1/enterprise/services/digital-portfolio-services';