import { Address } from '../address.model';
import { JobTypePreference } from './job-seeker-preference.model';

export class JobSeeker {
  id: string;
  name: string;
  email: string;
  mobile: string;
  dob: string;
  gender: string;
  profilePhoto: string;
  photos: string[];
  emergencyContact: EmergencyContact;
  profilePicture: string;
  address: Address;
  status: string;
  averageRating: number;
  deviceToken: string;
  referralCode?: string;

  notes?: string;
  createdBy?: string;
  updatedBy?: string;
  createdOn?: Date;
  updatedOn?: Date;

  workExperiences: WorkExperience[];
  phone?: string;

  //non api
  selected: boolean;
  jobTypePreferences: JobTypePreference[];
}

export interface WorkExperience {
  summary: string;
  workLink: string[];
}

export interface EmergencyContact {
  address: string;
  email: string;
  mobile: string;
}
