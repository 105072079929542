import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NotificationService } from 'src/@core/services/notification.service';
import { environment } from 'src/environments/environment';
import { AddEditEmploymentComponent } from '../employment/add-edit-employment/add-edit-employment.component';
import { MatDialog } from '@angular/material';
import { EmploymentService } from 'src/@core/services/employment/employment.service';
import { EmploymentAdministration } from 'src/@core/interfaces/employment/employment-administration.model';
import { AssignAdminDialogComponent } from '../assign-admin-dialog/assign-admin-dialog.component';
import { Router } from '@angular/router';
import { JobPipe } from 'src/@core/pipes/data/job.pipe';
import { WorkLogService } from 'src/@core/services/employment/worklog.service';
import { CancelEmployementComponent } from '../employment/cancel-employement/cancel-employement.component';
import { AssignJobAdminComponent } from '../assign-job-admin/assign-job-admin.component';

@Component({
  selector: 'hour4u-admin-shift-burger-menu',
  templateUrl: './shift-burger-menu.component.html',
  styleUrls: ['./shift-burger-menu.component.scss']
})
export class ShiftBurgerMenuComponent implements OnInit {
  @Input() row: any;
  @Input() status: any;
  @Input() roles: any;

  @Output() dataRefresh = new EventEmitter();

  copyTitleInvoiceShare: string = 'Share Invoice Payment';
  copiedShareInvoice: boolean = false;
  screenWidth = window.innerWidth;

  constructor(
    private notify: NotificationService,
    private dialog: MatDialog,
    private service: EmploymentService,
    private router: Router,
    private jobPipe: JobPipe,
    private workLogService: WorkLogService,
    private employmentService: EmploymentService
  ) { }

  ngOnInit() {
  }

  // View employement
  goToView(row) {
    this.router.navigateByUrl('/employment/view/' + row.id);
  }

  // UPDATE EMPLOYEMENT
  update(object: any): void {
    // let data = null;
    // data = Object.assign({}, object);
    // const dialogRef = this.dialog.open(AddEditEmploymentComponent, {
    //   width: '770px',
    //   panelClass: 'add-dialog',
    //   disableClose: true,
    //   data: data
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     this.dataRefresh.emit();
    //   }
    // });
    this.router.navigate(['/create-shift'], { queryParams: { mode: 'edit', employmentId: object.id } });

  }

  // CLONE EMPLOYEMENT/SHIFT
  clone(object: any): void {
    // let data = Object.assign({}, object);
    // data.id = null;
    // data.status = null;
    // data.cloned = true;
    // const dialogRef = this.dialog.open(AddEditEmploymentComponent, {
    //   width: '770px',
    //   panelClass: 'add-dialog',
    //   disableClose: true,
    //   data: data
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     this.dataRefresh.emit();
    //   }
    // });

    this.router.navigate(['/create-shift'], { queryParams: { mode: 'clone', employmentId: object.id } });
  }

  // SHIFT DELETE
  delete(id: string) {
    this.notify.showMessage('Deleting employment #' + id, 3000);
    this.service.delete(id).toPromise()
      .then(() => {
        this.notify.showMessage('Employment deleted', 3000);
      }).catch(error => {
        this.notify.showMessage('Could not delete employment ', 3000);
      }).finally(() => {
        this.dataRefresh.emit();
      });
  }

  // ASSIGN ADMIN DIALOG
  assignJobAdmin(data): void {
    // const employmentAdmin = new EmploymentAdministration();
    // employmentAdmin.employmentId = id;
    const dialogRef = this.dialog.open(AssignJobAdminComponent, {
      width: '600px',
      panelClass: 'assign-dialog',
      disableClose: true,
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataRefresh.emit();
      }
    });
  }

  // ASSIGN JOB SUPERVISOR DIALOG
  assignJobSupervisor(row): void {
    // const employmentAdmin = new EmploymentAdministration();
    // employmentAdmin.employmentId = id;
    const dialogRef = this.dialog.open(AssignAdminDialogComponent, {
      width: '600px',
      panelClass: 'assign-dialog',
      disableClose: true,
      data: row
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataRefresh.emit();
      }
    });
  }

  // REDIRECT TO JOBSEEKER LIST PAGE
  redirectToJobSeekerList(id, date) {
    this.router.navigate(['/employment/active/' + id, {
      date: date
    }])
  }

  // GO TO PAYMENT REQUEST PAGE OF EMPLOYER ADMIN
  async getPaymentReqs(data) {
    let jobt: string;
    await this.jobPipe.transform(data.jobId).then(v => jobt = v);
    this.router.navigate(['/employment/payment/' + data.id], {
      queryParams: {
        jobTitle: jobt,
        employmentTitle: data.employmentTitle,
        employerId: data.employerId,
        date: data.dates[0].date
      }
    });
  }

  // Show view payment reqs to super admin
  async showAllPaymentReqs(data: any) {
    let jobt: string;
    await this.jobPipe.transform(data.jobId).then(v => jobt = v);
    this.router.navigate(['/employment/job-seeker-payment/' + data.id], {
      queryParams: {
        jobTitle: jobt,
        employmentTitle: data.employmentTitle,
        employerId: data.employerId,
        date: data.dates[0].date
      }
    });
  }

  // SEND WORKLOG PAGE OPEN
  async sendWorkLog(job) {
    let jobt: string;
    await this.jobPipe.transform(job.jobId).then(v => jobt = v);
    this.router.navigate(['/employment/work-log/' + job.id], {
      queryParams: {
        jobTitle: jobt,
        employmentTitle: job.employmentTitle,
        employerId: job.employerId,
        date: job.dates[0].date
      }
    });
  }

  // DOWNLOAD WORKLOG
  async downloadWorkLog(id) {
    const blob = await this.workLogService.getWorklogAttPay(id)
      .catch(error => {
        this.notify.showMessage('Unable to download work log.', 2000);
      });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'work_log_' + id + '.xlsx';
    link.click();

    window.URL.revokeObjectURL(url);
  }

  // cancel employemnet
  cancelEmployement(employment: any) {
    console.log(employment)
    const dialogRef = this.dialog.open(CancelEmployementComponent, {
      width: '700px',
      data: employment,
    });
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.dataRefresh.emit();
        }
      }
    );
  }

  // SHARE INVOICE PAYMENT
  shareInvoicePayment(row) {
    let copyUrl = null;
    this.copiedShareInvoice = true;
    this.copyTitleInvoiceShare = 'Copied to your clipboard!';
    if (environment.api_url == 'https://uatapi.hour4u.com') {
      copyUrl = 'https://uatemployer.hour4u.com/invoice-pay/' + row.id
    }
    if (environment.api_url == 'https://api.hour4u.com') {
      copyUrl = 'https://business.hour4u.com/invoice-pay/' + row.id
    }

    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = copyUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notify.showMessage('Link copied!', 3000)
    setTimeout(() => {
      this.copiedShareInvoice = false;
      this.copyTitleInvoiceShare = 'Share Invoice Payment';
    }, 7000);
  }

  // SHARE PROOF OF WORK
  async shareProofOfWork(row) {
    // console.log(row)
    // let copyUrl = null;
    // this.copiedShareInvoice = true;
    // this.copyTitleInvoiceShare = 'Copied to your clipboard!';
    // if (environment.api_url == 'https://uatapi.hour4u.com') {
    //   copyUrl = 'https://uatemployer.hour4u.com/job-service/v1/employmentHistory/exportOrdersToExcel' + row.id
    // }
    // if (environment.api_url == 'https://api.hour4u.com') {
    //   copyUrl = 'https://business.hour4u.com/job-service/v1/employmentHistory/exportOrdersToExcel' + row.id
    // }

    // const selBox = document.createElement('textarea');
    // selBox.style.position = 'fixed';
    // selBox.style.left = '0';
    // selBox.style.top = '0';
    // selBox.style.opacity = '0';
    // selBox.value = copyUrl;
    // document.body.appendChild(selBox);
    // selBox.focus();
    // selBox.select();
    // document.execCommand('copy');
    // document.body.removeChild(selBox);
    // this.notify.showMessage('Link copied!', 3000)
    // setTimeout(() => {
    //   this.copiedShareInvoice = false;
    //   this.copyTitleInvoiceShare = 'Share Invoice Payment';
    // }, 7000);

    const blob = await this.workLogService.getProofOfWork(row.id)
      .catch(error => {
        this.notify.showMessage('Unable to download work log.', 2000);
      });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'proof_of_work_' + row.id + '.xlsx';
    link.click();

    window.URL.revokeObjectURL(url);
  }
}
