import { ActivatedRoute, Router } from '@angular/router';
import { Search } from 'src/@core/interfaces/search';
import { roles } from 'src/app/shared/helpers/roles';
import { SelectionModel } from "@angular/cdk/collections";
import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { MatFormFieldDefaultOptions, MatPaginator, MatSort, MatTableDataSource, MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material";
import { BehaviorSubject, merge, of as observableOf } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { fadeInRight400ms } from "src/@core/animations/fade-in-right.animation";
import { fadeInUp400ms } from "src/@core/animations/fade-in-up.animation";
import { stagger40ms } from "src/@core/animations/stagger.animation";
import { EmployementPaymentOrder } from "src/@core/interfaces/employment/employement-payment-order.model";
import { Attendance } from "src/@core/interfaces/job/attendance.model";
import { PaymentAttendance } from "src/@core/interfaces/payment/payment-attendance.model";
import { Payment } from "src/@core/interfaces/payment/payment.model";
import { Rating } from "src/@core/interfaces/rating/rating.model";
import { AppService, CURRENT_EMPLOYER } from "src/@core/services/app.service";
import { EmployeerPaymentOrderService } from "src/@core/services/employment/employment-payment-order.service";
import { LocalStorageService } from "src/@core/services/local-storage.service";
import { NotificationService } from "src/@core/services/notification.service";
import { PaymentService } from "src/@core/services/payment/payment.service";
import { environment } from "src/environments/environment";
import { EmploymentService } from 'src/@core/services/employment/employment.service';
import { HttpClient } from '@angular/common/http';
import { API_INVOICE } from 'src/@core/services/util/api.endpoints';
declare var Razorpay: any;


@Component({
  selector: 'hour4u-admin-order-and-invoices',
  templateUrl: './order-and-invoices.component.html',
  styleUrls: ['./order-and-invoices.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms,
    fadeInRight400ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class OrderAndInvoicesComponent implements OnInit {
  orders: any;
  roles = roles;
  searchParams: Search[] = [];
  term: string = '';
  resultsLength: number = 0;
  pageIndex: number = 0;
  pageSize: number = 10;
  pageSizeOptions: number[] = [10, 30, 50];
  public userEmail: string = '';
  currentUserRole: any = null;


  searchFilters: Search[] = [];
  dataSourcePayment: MatTableDataSource<Payment> | null;
  dataSourceRating: MatTableDataSource<Rating> | null;
  dataSourceAttendance: MatTableDataSource<Attendance> | null;
  dataSource: MatTableDataSource<PaymentAttendance> | null;
  dataChange: BehaviorSubject<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  selection = new SelectionModel<PaymentAttendance>(true, []);
  columns = [
    { columnDef: 'checkbox', visible: true, label: '' },
    { columnDef: 'employmentTitle', visible: true, label: 'Employment Title' },
    { columnDef: 'date', visible: true, label: 'Date' },
    { columnDef: 'time', visible: true, label: 'Time' },
    { columnDef: 'totalAmount', visible: true, label: 'Total Amount' },
    { columnDef: 'paidAmount', visible: true, label: 'Paid Amount' },
    { columnDef: 'amount', visible: true, label: 'Actual Amount' },
    { columnDef: 'status', visible: true, label: 'Status' },
    { columnDef: 'invoice', visible: true, label: 'Invoice' },
    { columnDef: 'action', visible: true, label: 'Action' },
  ];
  displayedColumns = this.columns.map(x => x.columnDef);
  invoiceDetails: any = null;
  allRowsSelected: boolean = false;
  isLoading: boolean = false;
  mode = 'indeterminate';
  diameter = 30;
  rezorpay_order_id: string = null;
  color: string;
  row: any;
  copyTitleEmployer: string = 'Copy Link to Share';
  copiedEmployer: boolean = false;
  selectedInvoicesForPay: any[] = [];
  isSubmitted: boolean = false;
  loginEmployerDetails: any = null;
  isEmployerLogin = this.appService.getIsEmployerLogin();
  profile = this.appService.getCurrentAccount();
  invoices: any[];




  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private notify: NotificationService,
    public cdr: ChangeDetectorRef,
    private employeerPaymentOrderService: EmployeerPaymentOrderService,
    private appService: AppService,
    private _localStorageService: LocalStorageService,
    private cdRef: ChangeDetectorRef,
    private employmentService: EmploymentService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.loginEmployerDetails = JSON.parse(localStorage.getItem(CURRENT_EMPLOYER));
    if (!this.loginEmployerDetails) {
      this.loginEmployerDetails =
      {
        advanceAmountPercentage: 20
      }
    }
    this.searchParams = [];
    this.dataChange = new BehaviorSubject(0);
    this.dataSource = new MatTableDataSource();
    this.dataSourcePayment = new MatTableDataSource();
    this.dataSourceAttendance = new MatTableDataSource();
    this.getCurrentRole();
    this.loadEmployments();
  }

  ngAfterViewInit() {
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }


  // get current user role
  getCurrentRole() {
    this.appService.currentUser.subscribe(currentUser => {
      currentUser.roles.forEach(ele => {
        this.currentUserRole = ele;
      })
    })
  }




  viewOrder(orderId: string) {
    this.router.navigate(['/order-and-invoices/view-order', orderId]);
  }

  search(data: string) {
    this.term = data;
    this.pageIndex = 0;
    this.searchParams = [];

    this.loadEmployments();
  }

  onPaginateChange(event: any) {
    // Update the page index and page size when the user changes the page
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    // Update the URL with the new page number using query parameters
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: this.pageIndex, size: this.pageSize }, // Add size parameter
      queryParamsHandling: 'merge' // Preserve existing query parameters
    });

    // Load employments with the updated page size
    this.loadEmployments();
  }

  async loadEmployments() {
    const empSearchParams: Search[] = [];
    if (this.isEmployerLogin || this.profile.user_details.employerId != null) {
      empSearchParams.push(new Search('employerId', this.profile.user_details.employerId));
    }

    if (this.term) {
      empSearchParams.push(new Search('employmentTitle', this.term)); // Add search term to filter employments
    }

    try {
      const response = await this.employmentService.searchAndSortEmployment(
        this.pageIndex, this.pageSize, null, null, empSearchParams, 'Employment'
      ).toPromise();

      this.orders = response.content;
      this.resultsLength = response.totalElements;
      this.dataSource = new MatTableDataSource(this.orders);
    } catch (error) {
      console.error('Error fetching employments', error);
    }
  }




  // Payment option open razorpay
  payAmount(order: any) {
    this.isSubmitted = true;
    this.isLoading = true;
    let paymentOrderRequest = new EmployementPaymentOrder();

    console.log("order", order)

    const calculatedAmount = (order.totalAmount * 1.18) * (this.loginEmployerDetails.advanceAmountPercentage / 100);
    paymentOrderRequest.amount = parseFloat(calculatedAmount.toFixed(2));
    paymentOrderRequest.employer_id = order.employerId;
    paymentOrderRequest.employment_id = order.id;

    if (!order.razorPayOrderId) {
      if (paymentOrderRequest.amount >= 0) {
        this.employeerPaymentOrderService.create(paymentOrderRequest).toPromise().then(
          (createdOrder: any) => {
            this.isSubmitted = false;
            this.isLoading = false;
            this.openRezorpay(createdOrder);
          }).catch(err => {
            this.isSubmitted = false;
            this.isLoading = false;
            console.log(err);
          });
      } else {
        this.isSubmitted = false;
        this.notify.showMessage("Amount is incorrect. Kindly check it once!", 3000);
        this.isLoading = false;
      }
    } else {
      let orderData = {
        status: 'SUCCESS',
        result: {
          amount: paymentOrderRequest.amount,
          razorPayOrderId: order.razorPayOrderId
        }
      };
      this.isSubmitted = false;
      this.rezorpay_order_id = order.razorPayOrderId;
      this.openRezorpay(orderData);
      this.isLoading = false;
    }
  }


  openRezorpay(order, fullPay?: boolean) {
    if (order != null && order.status == 'SUCCESS') {
      if (!fullPay) {
        this.razorPayOptions.amount = order.result.amount * 100; // Adjust based on employment details
      } else {
        this.razorPayOptions.amount = order.result.amount; // Adjust based on employment details
      }
      this.razorPayOptions.currency = 'INR';
      this.razorPayOptions.key = environment.rezorpayKey;
      this.razorPayOptions.order_id = order.result.razorPayOrderId; // Adjust based on employment details

      var rzp = new Razorpay(this.razorPayOptions);
      rzp.open()
      rzp.on('payment.failed', (response) => {
        console.log("response--", response)
        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
      });
    } else {
      this.notify.showMessage("Something went wrong!", 3000);
    }
  }



  // Payment options
  razorPayOptions = {
    "key": '',
    "amount": 0,
    "currency": '',
    "name": '',
    "discription": "hours4u payment",
    "order_id": '',
    "handler": (response) => {
      let paymentOrderRequest = new EmployementPaymentOrder();
      paymentOrderRequest.razorpay_order_id = response.razorpay_order_id ? response.razorpay_order_id : this.rezorpay_order_id;
      paymentOrderRequest.razorpay_payment_id = response.razorpay_payment_id;
      paymentOrderRequest.razorpay_signature = response.razorpay_signature;
      paymentOrderRequest.razorpay_invoice_id = response.razorpay_invoice_id;
      this.employeerPaymentOrderService.update(paymentOrderRequest.razorpay_order_id, paymentOrderRequest).toPromise().then(res => {
        this.notify.showMessage("Paid Advance amount successfully!", 3000);
        this.selectedInvoicesForPay = [];
        this.loadEmployments();
      })
    }
  };


  // download invoice
  async downloadInvoice(order: any) {
    try {
      const employmentId = order.id; // Get the employmentId from the selected order

      if (!employmentId) {
        console.error('No employmentId found in the order.');
        return;
      }

      const url = `${API_INVOICE}?employerId=${this.profile.user_details.employerId}&page=${this.pageIndex}&size=${1000}&sort=createdOn,desc`;
      const response = await this.http.get<any>(url).toPromise();

      if (response && 'content' in response) {
        const invoices = response.content || [];


        // Find the invoice with matching employmentId
        const invoiceWithMatchingEmploymentId = invoices.find((invoice: any) => invoice.employment.id === employmentId);

        if (invoiceWithMatchingEmploymentId && invoiceWithMatchingEmploymentId.invoiceShortUrl) {
          const invoiceShortUrl = invoiceWithMatchingEmploymentId.invoiceShortUrl;
          console.log('Invoice Short URL:', invoiceShortUrl);

          // opening the URL in a new tab
          window.open(invoiceShortUrl, '_blank');
        } else {
          console.error('Invoice short URL not found for the employment.');
        }
      } else {
        console.error('Invalid response format or missing content property.');
      }
    } catch (error) {
      console.error('Error fetching/download invoice:', error);
      // Handle error scenarios here
    }
  }

}
