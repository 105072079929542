import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/@core/services/notification.service';
import { JobService } from 'src/@core/services/job/job.service';
import { Job } from 'src/@core/interfaces/job/job.model';
import { HttpErrorResponse } from '@angular/common/http';
import { JobType } from 'src/@core/interfaces/job/job-type.model';
import { JobTypeService } from 'src/@core/services/job/job-type.service';
import { Search } from 'src/@core/interfaces/search';
import { Employer } from 'src/@core/interfaces/employer/employer.model';
import { EmployerService } from 'src/@core/services/employer/employer.service';
import { AppService } from 'src/@core/services/app.service';
import { roles } from 'src/app/shared/helpers/roles'
import { validator } from 'validator';
import { GaiService } from "src/@core/services/gai/gai.service";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'hour4u-admin-add-edit-job',
  templateUrl: './add-edit-job.component.html',
  styleUrls: ['./add-edit-job.component.scss']
})

export class AddEditJobComponent implements OnInit {

  selectedData: any = null;
  data = new Job();
  form: FormGroup;

  submitted = false;
  isUpdate = false;
  jobTypes = [];
  employers = [];
  roles = roles;
  employer: Employer;
  profile = this.appService.getCurrentAccount();
  fromWhere: string = null;
  isSuper: boolean = false;
  dialogData: any = {};
  dataFromPopup: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddEditJobComponent>,
    @Inject(MAT_DIALOG_DATA) public popupData: any,
    private _fb: FormBuilder,
    private jobTypeService: JobTypeService,
    private employerService: EmployerService,
    private service: JobService,
    private notify: NotificationService,
    private gaiService: GaiService,
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router) {
    this.createForm();

    // Clone template
    const navigation = this.router.getCurrentNavigation();
    if (navigation && navigation.extras && navigation.extras.state) {
      console.log(navigation.extras.state)
      this.selectedData = JSON.parse(navigation.extras.state.data).data;
      this.dialogData.data = this.selectedData;
      this.dialogData.title = JSON.parse(navigation.extras.state.data).title;
      console.log(this.selectedData)
      this.setupFormValues();
      this.form.patchValue(this.dialogData.data);
    }
  }

  // ngOnInit(): void {

  // }

  async ngOnInit() {
    this.appService.currentUser.subscribe(curruser => {
      if (curruser && curruser.roles)
        curruser.roles.forEach(role => {
          if (role == 'SUPER_ADMIN') {
            this.isSuper = true;
          }
        });
    });

    // Modal data
    if (this.popupData && this.popupData.title) {
      this.dataFromPopup = true;
      this.selectedData = this.popupData;
      this.dialogData = this.popupData;
      this.setupFormValues();
    }

    // Add/Edit template
    this.route.params.subscribe(data => {
      if (data && data.id) {
        this.dataFromPopup = false;
        this.selectedData = data;
        this.setupFormValues();
        this.dialogData.title = 'Modify Job'
        this.loadData()
      } else {
        this.setupFormValues();
        if (!this.dialogData.title)
          this.dialogData.title = 'Create Job Template'
      }
    })
  }

  setupFormValues() {
    // if (this.isSuper)
    this.loadJobType();
    this.loadEmployers();

    if (this.dialogData.description) {
      this.form.controls['description'].setValue(this.dialogData.description);
    }

    // if employer is logged in
    console.log(this.profile.user_details)
    if (this.profile.user_details.employerId) {
      this.employerService.forceGetEmp(this.profile.user_details.employerId).toPromise()
        .then(employer => {
          this.employer = employer;
          console.log(this.employer)
          this.form.controls['employerId'].setValue(employer.id);
          this.form.controls['employerId'].updateValueAndValidity();
          console.log(this.form.value)
        })
    }

    if (this.dialogData && this.dialogData.data && (this.dialogData.data.id || this.dialogData.data.cloned)) {
      this.isUpdate = true;
      this.form.controls['employerId'].setValue(this.dialogData.data.employerId ? this.dialogData.data.employerId : 'all');
      this.form.controls['employerId'].updateValueAndValidity();
    }
    console.log(this.dialogData)
    if (this.dialogData) {
      if (this.dialogData.from) {
        this.fromWhere = this.dialogData.from;
      }
      if (this.dialogData.jobTypeId) {
        if (this.fromWhere == 'Shifts') {
          this.form.controls['jobTypeId'].setValue(this.dialogData.jobTypeId);
          this.form.controls['jobTypeId'].updateValueAndValidity();
          if (this.isSuper) {
            console.log("callllllllllll")
            this.form.controls['employerId'].setValue(this.dialogData.employerId ? this.dialogData.employerId : 'all');
            this.form.controls['employerId'].updateValueAndValidity();
          }
        }
      }
    }
  }

  loadData() {
    this.service.getById(this.selectedData.id).subscribe(job => {
      this.dialogData.data = job;
      console.log(this.dialogData.data)

      this.form.patchValue(this.dialogData.data);

      // if (this.dialogData.description) {
      //   this.form.controls['description'].setValue(this.dialogData.description);
      // }
      this.loadEmployers();
      this.loadJobType();
    })
  }

  /**
   * Creating for using form builder for Job
   */
  createForm() {
    this.form = this._fb.group({
      id: [''],
      jobTypeId: [this.isUpdate ? this.dialogData.data.jobTypeId : '', Validators.required],
      employerId: [this.isUpdate ? this.dialogData.data.employerId : '', Validators.required],
      description: [''],
      title: ['', [Validators.required, Validators.minLength(5)]],
      tasks: [''],
      requirements: [''],
      prerequisites: [''],
      dressCodeFemale: ['', Validators.required],
      dressCodeMale: ['', Validators.required],
    });
    console.log(this.form.value)
  }

  // onCancel() {
  //   this.dialogRef.close();
  // }

  /**
   * Save Job
   */
  onSave(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.submitted = false;
      return;
    } else {
      if (this.dialogData && this.dialogData.data && this.dialogData.data.id) {
        this._update(this.form.value);
      } else {
        this._create(this.form.value);
      }
    }

  }

  /**
   * Creating job
   * @param job 
   */
  _create(job): void {
    if (this.isSuper)
      job.employerName = this.employers.filter(e => e.id == job.employerId).map(e => e.name)[0];
    else {
      //for employer users
      job.employerId = job.employerId;
      job.employerName = this.employer.name;
    }

    // remove after correction
    if (typeof (job.tasks) === 'string') {
      job.tasks = [job.tasks];
    }

    if (job.employerId == 'all') {
      delete job.employerId
      delete job.employerName
      job.globalTemplate = true;
    }
    this.service.create(job).subscribe(
      data => {
        this.submitted = false;
        this.data = data;
      }, (error: HttpErrorResponse) => {
        this.submitted = false;
        this.notify.showMessage("Error while creating job! ", 2000);
      }
      , () => {
        this.submitted = false;
        this.notify.showMessage("Job created successfully! ", 2000);
        // this.dialogRef.close(this.data);
        this.goBack();
      }
    );
  }

  /**
   * Updating job
   * @param job 
   */
  _update(job): void {
    if (this.isSuper)
      job.employerName = this.employers.filter(e => e.id == job.employerId).map(e => e.name)[0];
    else
      job.employerName = this.employer.name;

    // remove after correction
    if (typeof (job.tasks) === 'string') {
      job.tasks = [job.tasks];
    }

    if (job.employerId == 'all') {
      delete job.employerId
      delete job.employerName
      job.globalTemplate = true;
    }

    this.service.update(this.dialogData.data.id, job).subscribe(
      data => {
        this.submitted = false;
      }, (error: HttpErrorResponse) => {
        this.submitted = false;
        this.notify.showMessage("Error while updating job! ", 2000);
      }
      , () => {
        this.submitted = false;
        this.notify.showMessage("Job update successfully! ", 2000);
        // this.dialogRef.close(this.data);
        this.goBack();
      }
    );
  }

  /**
   * Job Type search function
   * @param term 
   */
  async loadJobType() {
    this.jobTypeService.searchAndSort(0, 10000, null, null).subscribe(data => {
      this.jobTypes = data.content;
    });
  }


  /**
   * Employer search function
   * @param term 
   */
  async loadEmployers() {
    this.employers = [];
    this.employerService.searchAndSort(0, 10000, null, null,
    ).subscribe(data => {
      if (data.content && data.content.length != 0) {
        this.employers.push({ id: 'all', name: 'All EMPLOYER' })
        data.content.forEach(ele => {
          this.employers.push(ele);
        })
      }
    });
  }

  /**
   * Get Job descriptioin using GAI function
   * @param event 
   */
  jobTypeChanged(event) {
    var selectedValue = event.value;
    var inputText = "";
    this.jobTypes.forEach(function (value, key) {
      if (value.id == selectedValue) {
        inputText = inputText + value.name + "\n" + value.notes;
        return;
      }
    })
    this.form.controls['description'].setValue("");
    console.log("input text " + inputText);
    if (inputText != "") {
      this.gaiService.getGaiResponse(inputText, 100).then(res => {
        let respone = res.aiResponse;
        this.form.controls['description'].setValue(respone);
      }).catch((err: any) => {
        return;
      })
    }
  }

  goBack() {
    // console.log(this.dialogData, this.selectedData)
    // return;
    this.form.reset();
    if (this.dataFromPopup) {
      this.dialogRef.close(this.data);
    } else {
      this.router.navigate(['/job'])
    }
  }

}

