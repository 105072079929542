import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirm-delete-dialog',
  templateUrl: './confirm-delete-dialog.component.html',
  styleUrls: ['./confirm-delete-dialog.component.scss']
})
export class ConfirmDeleteDialogComponent implements OnInit {

  deleteCode: string="";

  constructor(public dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

ngOnInit() {
}

onSave(): void {
  if(this.data.type == 'User') {
    this.dialogRef.close(this.deleteCode);
  }
  else
    this.dialogRef.close(this.data);
}

cancel(): void {
this.dialogRef.close();
}

}
