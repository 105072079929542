import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { API_BOOK, API_BOOKING, API_PROPERTY, API_PROPERTY_EXPORT } from 'src/@core/services/util/api.endpoints';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Search } from 'src/@core/interfaces/search';
import { AppService } from 'src/@core/services/app.service';
import { roles } from 'src/app/shared/helpers/roles';
import { SocietyFilterDialogComponent } from '../societies/view-society/society-filter-dialog/society-filter-dialog.component';


@Component({
  selector: 'hour4u-admin-available-permissions',
  templateUrl: './available-permissions.component.html',
  styleUrls: ['./available-permissions.component.scss']
})
export class AvailablePermissionsComponent implements OnInit {
  bookings: any[] = [];
  societies: any[] = [];
  resultsLength: number = 0;
  pageIndex: number = 0;
  pageSize: number = 20;
  pageSizeOptions: number[] = [20, 30, 50];
  roles = roles;
  public userEmail: string = '';
  currentUserRole: any = null;
  searchParams: Search[] = [];
  term: string = '';
  // Initialize the filters with empty values
  filters: { city: string, numberOfFlats: number | null } = {
    city: '',
    numberOfFlats: null
  };
  areFiltersActive: boolean = false;




  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private appService: AppService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.pageIndex = +params['page'] || 0;
      this.term = params['name'] || ''; // Get the search term from query params

      // Parse and apply filter parameters
      this.filters = {
        city: params['city'] || '',
        numberOfFlats: params['numberOfFlats'] !== undefined ? +params['numberOfFlats'] : null
      };

      // this.fetchSocieties();
      this.fetchBookings();
    });

    this.getCurrentRole();
  }



  // fetchSocieties() {
  //   // Create a HttpParams object to pass the page number and sorting parameter as query parameters
  //   let params = new HttpParams()
  //     .set('page', this.pageIndex.toString())
  //     .set('sort', 'createdOn,desc'); // Sorting parameter

  //   if (this.term) {
  //     params = params.set('name', this.term); // Include 'name' query parameter if 'term' is not empty
  //   }

  //   // Apply city filter if it exists
  //   if (this.filters.city) {
  //     params = params.set('location.city', this.filters.city);
  //   }

  //   // Apply numberOfFlats filter if it exists
  //   if (this.filters.numberOfFlats) {
  //     params = params.set('numberOfFlats', this.filters.numberOfFlats.toString());
  //   }

  //   // Make a GET request to fetch societies with pagination and sorting
  //   this.http.get<any>(API_PROPERTY, { params }).subscribe(
  //     (response) => {
  //       this.societies = response.content;
  //       this.resultsLength = response.totalElements;
  //       // console.log(this.appService.getCurrentAccount().email, '&', this.societies);
  //       this.userEmail = this.appService.getCurrentAccount().email;
  //       //console.log(this.userEmail)
  //       this.calculateTimeDifference();

  //       // Update the URL with the current search and filter parameters
  //       this.updateUrl();
  //     },
  //     (error) => {
  //       console.error('Failed to fetch societies:', error);
  //     }
  //   );
  // }

  fetchBookings(): void {
    const sort = 'createdOn,desc';
    const pageIndex = this.pageIndex.toString();

    const httpParams = new HttpParams()
      .set('sort', sort)
      .set('page', pageIndex);

    this.http.get<any>(API_BOOK, { params: httpParams }).subscribe(
      (response) => {

        this.bookings = response.content;
        console.log(this.bookings);
        this.resultsLength = response.totalElements;
        console.log(response);

      },
      (error) => {
        console.error('Failed to fetch bookings:', error);
      }
    );
  }
  acceptBooking(bookingId: string) {
    const updateData = {
      bookingStatus: 'APPROVED',
    };

    this.http.put<any>(`${API_BOOK}/${bookingId}`, updateData)
      .subscribe(
        (response) => {
          console.log('Booking status updated:', response);
          this.fetchBookings();

        },
        (error) => {
          console.error('Error updating booking status:', error);
        }
      );
  }

  rejectBooking(bookingId: string) {
    const updateData = {
      bookingStatus: 'REJECTED',
    };

    this.http.put<any>(`${API_BOOK}/${bookingId}`, updateData)
      .subscribe(
        (response) => {
          console.log('Booking status updated:', response);
          this.fetchBookings();
        },
        (error) => {
          console.error('Error updating booking status:', error);
        }
      );
  }

  // Update the URL with the current search and filter parameters
  private updateUrl() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        page: this.pageIndex,
        name: this.term,
        city: this.filters.city,
        numberOfFlats: this.filters.numberOfFlats
      },
      queryParamsHandling: 'merge'
    });
  }

  search(data: string) {
    this.term = data;
    this.pageIndex = 0;
    this.searchParams = [];

    this.fetchBookings();
  }


  // get current user role
  getCurrentRole() {
    this.appService.currentUser.subscribe(currentUser => {
      currentUser.roles.forEach(ele => {
        this.currentUserRole = ele;
      })
    })
  }


  viewSociety(societyId: string) {
    // Navigate to the "view-society" route with the society ID in the route parameter
    //console.log(`View society with ID: ${societyId}`);
    this.router.navigate(['/societies/view-society', societyId]);
  }

  calculateTimeDifference() {
    this.societies.forEach((society) => {
      if (society && society.availableSlots && society.availableSlots.length > 0) {
        const { timeFrom, timeTo } = society.availableSlots[0];
        if ((timeFrom && timeFrom.length) && (timeTo && timeTo.length)) {
          const hourDifference = timeTo[0] - timeFrom[0];
          const minuteDifference = timeTo[1] - timeFrom[1];

          society.timeDifference = `${hourDifference} h ${minuteDifference} m`;
        }
      } else {
        // Set a default value or handle the case where there are no available slots
        society.timeDifference = 'N/A';
      }
    });
  }

  onPaginateChange(event: any) {
    // Update the page index when the user changes the page
    this.pageIndex = event.pageIndex;

    // Update the URL with the new page number using query parameters
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: this.pageIndex },
      queryParamsHandling: 'merge' // Preserve existing query parameters
    });
  }
  // addSociety() {
  //   this.router.navigate(['../societies/add-edit-societies'], { relativeTo: this.route });
  // }


  exportData() {
    this.http.get(API_PROPERTY_EXPORT, { responseType: 'blob', observe: 'response' })
      .subscribe((response: HttpResponse<Blob>) => {
        // Handle the API response, which is a file to download
        const blob = new Blob([response.body!], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);

        // Create a link element and trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = 'society-data.xlsx'; // specify the desired filename here
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }
  editSociety(societyId: string) {
    // Navigate to the "add-edit-societies" route with the society ID in the route parameter
    // console.log(`Edit society with ID: ${societyId}`);
    this.router.navigate(['/societies/add-edit-societies', societyId]);
  }

  openFilterDialog() {
    const dialogRef = this.dialog.open(SocietyFilterDialogComponent, {
      width: '50%',
      height: '100%',
      position: { right: '0px' },
    });

    dialogRef.afterClosed().subscribe((filters: { city: string | null, numberOfFlats: number | null }) => {
      if (filters.city !== null || filters.numberOfFlats !== null) {
        // Apply the selected filters to your API request
        this.filters = filters;
      }

      // Set areFiltersActive based on whether filters are active or not
      this.areFiltersActive = filters.city !== null || filters.numberOfFlats !== null;

      // Fetch societies after handling filters and areFiltersActive
      this.fetchBookings();
    });
  }


}
