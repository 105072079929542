import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SocietiesComponent } from './societies.component';
import { AuthGuard } from 'src/@core/guards/auth.guard';
import { MainLayoutComponent } from 'src/app/main-layout/main-layout.component';
import { AddEditSocietiesComponent } from './add-edit-societies/add-edit-societies.component';
import { ViewSocietyComponent } from './view-society/view-society.component';
import { BookingsComponent } from './bookings/bookings.component';

const routes: Routes = [
    {
        path: 'societies',
        canActivate: [AuthGuard],
        component: MainLayoutComponent,
        children: [
            {
                path: '',
                component: SocietiesComponent
            },
            {
                path: 'view-society/:id',
                component: ViewSocietyComponent
            },
            {
                path: 'add-edit-societies',
                component: AddEditSocietiesComponent,
                canActivate: [AuthGuard],
                data: { roles: ['SUPER_ADMIN'] }
            },
            {
                path: 'add-edit-societies/:id',
                component: AddEditSocietiesComponent,
                canActivate: [AuthGuard],
                data: { roles: ['SUPER_ADMIN'] }
            },
            {
                path: 'bookings',
                component: BookingsComponent,
                canActivate: [AuthGuard],
                data: { roles: ['EMPLOYER_ADMIN', 'EMPLOYER_STAFF'] }
            }

        ]
    }
];




@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class SocietiesRoutingModule { }
