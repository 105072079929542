import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { EmploymentFilter } from '../employment-filter.model';
import { EmployerService } from 'src/@core/services/employer/employer.service';
import { Employer } from 'src/@core/interfaces/employer/employer.model';
import { AppService } from 'src/@core/services/app.service';
import { roles } from 'src/app/shared/helpers/roles';
import { NotificationService } from 'src/@core/services/notification.service';
import { Search } from 'src/@core/interfaces/search';

@Component({
  selector: 'hour4u-admin-employment-filter-dialog',
  templateUrl: './employment-filter-dialog.component.html',
  styleUrls: ['./employment-filter-dialog.component.scss']
})
export class EmploymentFilterDialogComponent implements OnInit {

  roles = roles;
  employers = [];
  employer: Employer;
  profile = this.appService.getCurrentAccount();

  constructor(
    public dialogRef: MatDialogRef<EmploymentFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EmploymentFilter,
    private employerService: EmployerService,
    private appService: AppService,
    private notify: NotificationService) {
  }

  ngOnInit() {
    this.employerService.getById(this.profile.user_details.employerId).toPromise()
      .then(employer => {
        this.employer = employer;
      })
  }

  statusSelected(event) {
    this.data.status.forEach(x => {
      if (x.value === event.source.value) {
        x.checked = event.checked;
      }
    });
  }

  onApplyFilter() {
    if (this.data.createdOn.length == 1) {
      this.notify.showMessage("Please select valid date Range!", 2000);
    }
    else {
      this.dialogRef.close(this.data);
    }
  }

  /**
  * Employer display function for list
  * @param employer 
  */
  employerDisplayFn(employer: Employer) {
    return employer ? employer.name : employer;
  }

  /**
   * Employer search function
   * @param term 
   */
  async onEmployerSearch(term) {
    this.employerService.searchAndSort(0, 10, null, null,
      [
        new Search('name', term),
        //new Search('email', term)
      ]
    ).subscribe(data => {
      this.employers = data.content;
    }, error => {
      this.notify.showMessage("No Employer Found!", 2000);
    });
  }

  onSelectEmployer(employer) {
    this.employer = employer;
  }

  resetFilter() {
    this.data.createdOn = [];
    this.data.city = null;
    this.data.employer = null;
    this.data.status.forEach(ele => {
      ele.checked = false;
    })
    this.dialogRef.close(this.data);
  }
}
