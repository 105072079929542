import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NavigationExtras, Router } from '@angular/router';
import { EmployementPaymentOrder } from 'src/@core/interfaces/employment/employement-payment-order.model';
import { JobType } from 'src/@core/interfaces/job/job-type.model';
import { CURRENT_EMPLOYER, CURRENT_USER } from 'src/@core/services/app.service';
import { EmployeerPaymentOrderService } from 'src/@core/services/employment/employment-payment-order.service';
import { WorkLogService } from 'src/@core/services/employment/worklog.service';
import { JobService } from 'src/@core/services/job/job.service';
import { NotificationService } from 'src/@core/services/notification.service';
import { environment } from 'src/environments/environment';
declare var Razorpay: any;

@Component({
  selector: 'hour4u-admin-invoice-pay',
  templateUrl: './invoice-pay.component.html',
  styleUrls: ['./invoice-pay.component.scss']
})
export class InvoicePayComponent implements OnInit {
  jobType: JobType;
  employerDetails: any = null;
  isLogin: boolean = false;
  showExpand: boolean = false;
  rezorpay_order_id: string = null;
  showNoData: boolean = false;
  successScreenObj: any = {
    shiftName: '',
    amount: 0
  };
  profile = JSON.parse(localStorage.getItem(CURRENT_EMPLOYER));

  constructor(
    public dialogRef: MatDialogRef<InvoicePayComponent>,
    @Inject(MAT_DIALOG_DATA) public params: any,
    private jobService: JobService,
    // public location: Location,
    public router: Router,
    private employeerPaymentOrderService: EmployeerPaymentOrderService,
    private notify: NotificationService,
    private workLogService: WorkLogService,
  ) {
    console.log(this.profile)
  }

  ngOnInit() {
    console.log(this.params, JSON.parse(localStorage.getItem(CURRENT_USER)))
    this.isLogin = JSON.parse(localStorage.getItem(CURRENT_USER)) ? true : false;
    if (this.params) {
      this.getEmployementDetails();
    }
  }

  async getEmployementDetails() {
    console.log(this.params.id)
    await this.jobService.getInvoiceDetails(this.params.id).toPromise()
      .then(async job => {
        this.showNoData = false;
        console.log(job)
        this.employerDetails = job;
      }).catch(err => {
        this.showNoData = true;
        this.dialogRef.close();
        console.log(err)
      });
  }

  async payCompleteAmount() {
    if (this.employerDetails.employmentDetails && this.employerDetails.employerInvoice) {
      if (this.employerDetails.employerInvoice.status != 'SETTLED') {
        let paymentOrderRequest = new EmployementPaymentOrder();
        //% of total amount 
        paymentOrderRequest.amount = this.employerDetails.employerInvoice.invoiceAmount - this.employerDetails.employerInvoice.paidAmount
        paymentOrderRequest.employer_id = this.employerDetails.employmentDetails.employment.employerId;
        paymentOrderRequest.employment_id = this.employerDetails.employmentDetails.employment.id;
        console.log(paymentOrderRequest)
        if (!this.employerDetails.employerInvoice.razorPayOrderId) {
          this.employeerPaymentOrderService.create(paymentOrderRequest).toPromise().then(
            order => {
              this.openRezorpay(order);
            }
          );
        } else {
          let order = {
            status: 'SUCCESS',
            result: {
              amount: paymentOrderRequest.amount,
              razorPayOrderId: this.employerDetails.employerInvoice.razorPayOrderId
            }
          }
          this.rezorpay_order_id = this.employerDetails.employerInvoice.razorPayOrderId;
          this.openRezorpay(order);
        }
      } else {
        this.notify.showMessage("This Invoice amount already paid!", 2000);
      }
    } else {
      this.notify.showMessage("Something went wrong with this invoice data", 2000);
    }
  }

  openRezorpay(order) {
    if (order != null && order.status == 'SUCCESS') {
      this.razorPayOptions.amount = order.result.amount * 100;
      this.razorPayOptions.currency = 'INR';
      this.razorPayOptions.key = environment.rezorpayKey;
      this.razorPayOptions.order_id = order.result.razorPayOrderId;
      this.successScreenObj.shiftName = this.employerDetails.employmentDetails.employment.employmentTitle;
      this.successScreenObj.amount = this.razorPayOptions.amount;
      console.log("this.razorPayOptions", this.razorPayOptions)
      var rzp = new Razorpay(this.razorPayOptions);
      rzp.open()
      rzp.on('payment.failed', function (response) {
        console.log("response--", response)
        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
      });
    } else {
      this.notify.showMessage("Something went wrong!", 3000);
    }
  }

  razorPayOptions = {
    "key": '',
    "amount": 0,
    "currency": '',
    "name": '',
    "discription": "hours4u payment",
    "order_id": '',
    "handler": (response) => {
      console.log("response===", response)
      let paymentOrderRequest = new EmployementPaymentOrder();
      paymentOrderRequest.razorpay_order_id = response.razorpay_order_id ? response.razorpay_order_id : this.rezorpay_order_id;
      paymentOrderRequest.razorpay_payment_id = response.razorpay_payment_id;
      paymentOrderRequest.razorpay_signature = response.razorpay_signature;
      paymentOrderRequest.razorpay_invoice_id = response.razorpay_invoice_id;
      this.employeerPaymentOrderService.update(paymentOrderRequest.razorpay_order_id, paymentOrderRequest).toPromise().then(res => {
        this.notify.showMessage("Paid work logs amount successfully!", 3000);
        this.closePopup();
        console.log(this.successScreenObj)
        const navigationExtras: NavigationExtras = {
          queryParams: { 'obj': this.successScreenObj }
        }
        this.router.navigate(['/success-screen'], navigationExtras);
      })
    },
    "modal": {
      "ondismiss": () => {
        this.closePopup();
      }
    }
  };

  closePopup() {
    this.dialogRef.close();
    // this.location.back();
    if (this.isLogin) {
      this.router.navigateByUrl('employment/all/all');
    } else {
      this.router.navigateByUrl('/');
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  }

  // goToLogin() {
  //   this.dialogRef.close();
  //   this.router.navigateByUrl('/');
  // }

  chatWithSupport() {
    window.open('https://api.whatsapp.com/send?phone=' + environment.contactSuppportNumber, "_blank");
  }

  // Download Work log
  async downloadWorkLog() {
    let id = this.employerDetails.employmentDetails.employment.id;
    const blob = await this.workLogService.getWorklogAttPay(id)
      .catch(error => {
        this.notify.showMessage('Unable to download work log.', 2000);
      });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'work_log_' + id + '.xlsx';
    link.click();

    window.URL.revokeObjectURL(url);
  }
}
