import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { JobSeekerService } from 'src/@core/services/job-seeker/job-seeker.service';
import { NotificationService } from 'src/@core/services/notification.service';

@Component({
  selector: 'hour4u-admin-profile-reject-dialog',
  templateUrl: './profile-reject-dialog.component.html',
  styleUrls: ['./profile-reject-dialog.component.scss']
})
export class ProfileRejectDialogComponent implements OnInit {

  form: FormGroup;
  isSubmitted: boolean = false;

  constructor(public dialogRef: MatDialogRef<ProfileRejectDialogComponent>,
    private _fb: FormBuilder,
    private notify: NotificationService,
    private jobSeekerService: JobSeekerService,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit() {

    this.form = this._fb.group({
      //rejectedReason: ['', Validators.required],
      notes: [''],
    })
  }

  onCancel() {
    this.dialogRef.close();
  }

  async onReject() {
    this.isSubmitted = true;
    if (!this.data.id) {
      this.isSubmitted = false;
      return;
    }
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.isSubmitted = false;
      return;
    }

    await this.jobSeekerService.updateRejectedStatus(this.data.id, this.form.value).toPromise().then(data => {
      this.notify.showMessage("Status Updated successfully!", 2000);
      this.isSubmitted = false;
      this.dialogRef.close(true);
    }).catch(err => {
      this.isSubmitted = false;
    });
  }

}
