import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { EmploymentActionService } from 'src/@core/services/employment/employment-action.service';
import { NotificationService } from 'src/@core/services/notification.service';

@Component({
  selector: 'hour4u-admin-de-activate-employment',
  templateUrl: './de-activate-employment.component.html',
  styleUrls: ['./de-activate-employment.component.scss']
})
export class DeActivateEmploymentComponent implements OnInit {
 
  notify = {
    notifyEmployer: false,
    notifyJobSeeker: false
  }

  constructor(public dialogRef: MatDialogRef<DeActivateEmploymentComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private service: EmploymentActionService,
              private notification: NotificationService) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  onSubmit() {

    this.service.deActivate(this.data.id, this.notify).subscribe(data => {    
    }, (error) => {
      this.notification.showMessage('Could not Decativate the employment!', 2000);
    }, () => {
      this.notification.showMessage('Employment Deactivated Successfully!', 2000);
      this.dialogRef.close();
    })
  }

}
