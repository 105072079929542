export class Rating {
  createdBy: string;
  createdOn: Date;
  deleteBy: string;
  employerId: string;
  employmentId: string;
  id: string;
  isDelete: true;
  jobSeekerId: string;
  jobSeekerIds: string[];
  rating: number;
  review: string;
  reviewTypes: string[];
  updatedBy: string;
  updatedOn: Date
}