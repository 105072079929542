import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'hour4u-admin-profile-approval-prevent-dialog',
    templateUrl: './profile-approval-prevent-dialog.component.html',
    styleUrls: ['./profile-approval-prevent-dialog.component.scss']
})
export class ProfileApprovalPreventDialogComponent{

    username:string;

    constructor(
        public dialogRef: MatDialogRef<ProfileApprovalPreventDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: string) {
        this.username = data;
    }

    onOK() {
        this.dialogRef.close();
    }
        
}