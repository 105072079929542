import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { API_BULK_INVOICES_PAY, API_INVOICE, API_INVOICE_SETTLE, API_INVOICE_SUMMARY, API_PAYMENT } from "../util/api.endpoints";
import { Observable } from 'rxjs';
import { Search } from 'src/@core/interfaces/search';
import { PageableResponse } from 'src/@core/interfaces/pageable-response';
import { Payment } from 'src/@core/interfaces/payment/payment.model';
import { AppService } from '../app.service';
import { PaymentTracker } from 'src/@core/interfaces/job/payment-tracker.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(private http: HttpClient, private appService: AppService) { }
  private user = null;
  private employer = this.appService.getEmployer();

  searchAndSort(page: number, size: number, sort: string, order: string, searchList?: Search[]): Observable<PageableResponse<Payment>> {
    let params = new HttpParams();
    let url = API_PAYMENT + '?page=' + page;
    if (size != null) {
      url += '&size=' + size;
    }

    if (order !== 'desc') {
      order = 'asc';
    }
    if (sort != null) {
      url += ('&sort=' + sort + ',' + order);
    } else {
      url += ('&sort=createdOn,desc');
    }

    if (searchList && searchList.length > 0) {
      _.forEach(searchList, (search) => {
        if (search.column && search.content) {
          params = params.append(search.column, search.content);
        }
      });
    }
    return this.http.get<PageableResponse<Payment>>(url, { params: params });
  }

  createPayment(request: any): Observable<any> {
    return this.http.post<any>(API_PAYMENT, request);
  }

  countPayment(searchList?: Search[]): Observable<any> {
    let params = new HttpParams();
    let url = API_PAYMENT + '/count';
    if (searchList && searchList.length > 0) {
      _.forEach(searchList, (search) => {
        if (search.column && search.content) {
          params = params.append(search.column, search.content);
        }
      });
    }
    return this.http.get<any>(url, { params: params });
  }

  updatePaymentStatus(id: string, status: string, settledAmount?: number, cashSettled: boolean = false): Observable<any> {
    let params = new HttpParams();
    params.append('status', status);
    if (!settledAmount)
      settledAmount = 0;
    let url = API_PAYMENT + `/${id}` + `?status=` + status + `&settledAmount=` + settledAmount
      + `&cashSettled=` + cashSettled;
    return this.http.put<any>(url, params);
  }

  approvePayments(data: any): Observable<any> {
    let url = API_PAYMENT + `/approve`;
    return this.http.post<any>(url, data);
  }

  paymentTrackingByStatus(pageIndex: number, pageSize: number, status: string, searchList?: Search[]): Observable<any> {

    let params = new HttpParams();
    let url = API_PAYMENT + '/paymentTrackingByStatus/' + status + '?page=' + pageIndex;
    if (pageSize != null) {
      url += '&size=' + pageSize + '&sort=createdOn,desc';
    }

    if (searchList && searchList.length > 0) {
      _.forEach(searchList, (search) => {
        if (search.column && search.content) {
          params = params.append(search.column, search.content);
        }
      });
    }
    return this.http.get<PageableResponse<PaymentTracker>>(url, { params: params });
    //const url = API_PAYMENT + '/paymentTrackingByStatus/' + status + '?page=' + pageIndex + '&size=' + pageSize + '&sort=createdOn,desc';
    //return this.http.get<PageableResponse<PaymentTracker>>(url);
  }

  earningStats(jobSeekerId: string): Observable<any> {
    const url = API_PAYMENT + '/earningStats/' + jobSeekerId;
    return this.http.get<any>(url);
  }

  getInvoiceDetails(): Observable<any> {
    let url = null;
    if (this.user.user_details.employerId) {
      url = API_INVOICE + `?employerId=` + this.user.user_details.employerId;
    } else {
      url = API_INVOICE;
    }
    return this.http.get<any>(url, {});
  }

  getInvoiceSummaryDetails(): Observable<any> {
    this.user = this.appService.getCurrentAccount();
    let url = null;
    if (this.user.user_details.employerId) {
      url = API_INVOICE_SUMMARY + `?employerId=` + this.user.user_details.employerId;
    } else {
      url = API_INVOICE_SUMMARY;
    }
    return this.http.get<any>(url, {});
  }

  searchAndSortInvoice(page: number, size: number, sort: string, order: string, searchList?: Search[], cacheKey?: any): Observable<PageableResponse<any>> {
    let params = new HttpParams();
    let _url = null;
    let url = null;
    if (this.user.user_details.employerId) {
      _url = API_INVOICE + `?employerId=` + this.user.user_details.employerId;
      url = _url + '&page=' + page;
    } else {
      _url = API_INVOICE;
      url = _url + '?page=' + page;
    }
    if (size != null) {
      url += '&size=' + size;
    }

    if (order !== 'desc') {
      order = 'asc';
    }
    if (sort != null) {
      url += ('&sort=' + sort + ',' + order);
    } else {
      url += ('&sort=createdOn,desc');
    }

    if (searchList && searchList.length > 0) {
      _.forEach(searchList, (search) => {
        if (search.column && search.content) {
          params = params.append(search.column, search.content);
        }
      });
    }
    return this.http.get<PageableResponse<any>>(url, { params: params });
  }

  payForBulkInvoices(data: any): Observable<any> {
    let url = API_BULK_INVOICES_PAY;
    return this.http.post<any>(url, data);
  }

  settleInvoice(invoice_id: any): Observable<any> {
    let url = API_INVOICE_SETTLE + '/' + invoice_id;
    return this.http.get<any>(url);
  }
}
