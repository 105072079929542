import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'toDateTime'
})
export class ParseDatePipe implements PipeTransform {
  transform(val: any | null): any {
    if (!val) {
      return '';
    }
    return val.millis;
  }

}
