import { Pipe, PipeTransform } from '@angular/core';
import { PAYMENTSTATUS, PAYMENTSTATUSNAMES } from 'src/app/shared/helpers/status';

@Pipe({
  name: 'getPaymentStatus'
})
export class PaymentStatusPipe implements PipeTransform {
  transform(val: any | null): any {
    if (!val) {
      return '';
    }
    switch (val) {
      case PAYMENTSTATUS.ADVANCE_PAYMENT_PENDING:
        return "<div @scaleIn  class='status-btn-card rounded bg-red-300 text-red-700 items-center justify-center mat-button br-32'>" + PAYMENTSTATUSNAMES.ADVANCE_PAYMENT_PENDING + "</div>"
      case PAYMENTSTATUS.ADVANCE_PAYMENT_SUCCESS:
        return "<div @scaleIn  class='status-btn-card rounded bg-primary-300 text-primary-700 items-center justify-center mat-button br-32'>" + PAYMENTSTATUSNAMES.ADVANCE_PAYMENT_SUCCESS + "</div>"
      case PAYMENTSTATUS.INVOICE_PAID:
        return "<div @scaleIn  class='status-btn-card rounded bg-red-50 text-red-500 items-center justify-center mat-button'>" + PAYMENTSTATUSNAMES.INVOICE_PAID + "</div>"
      case PAYMENTSTATUS.WORKLOG_SENT:
        return "<div @scaleIn  class='status-btn-card rounded bg-primary-50 text-primary-500 items-center justify-center mat-button'>" + PAYMENTSTATUSNAMES.WORKLOG_SENT + "</div>"
      case PAYMENTSTATUS.INVOICE_GENERATED:
        return "<div @scaleIn  class='status-btn-card rounded bg-green-300 text-green-700 items-center justify-center mat-button br-32'>" + PAYMENTSTATUSNAMES.INVOICE_GENERATED + "</div>"
      case PAYMENTSTATUS.REFUND_INITIATED:
        return "<div @scaleIn  class='status-btn-card rounded bg-red-50 text-red-500 items-center justify-center mat-button'>" + PAYMENTSTATUSNAMES.REFUND_INITIATED + "</div>"
      case PAYMENTSTATUS.REFUND_COMPLETED:
        return "<div @scaleIn  class='status-btn-card rounded bg-orange-300 text-orange-700 items-center justify-center mat-button br-32'>" + PAYMENTSTATUSNAMES.REFUND_COMPLETED + "</div>"
      default:
        return ''
    }
  }

}
