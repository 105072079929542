import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DateTime } from 'luxon';
import { AppService } from 'src/@core/services/app.service';
import { JobService } from 'src/@core/services/job/job.service';
import { NotificationService } from 'src/@core/services/notification.service';

@Component({
  selector: 'hour4u-admin-cancel-employement',
  templateUrl: './cancel-employement.component.html',
  styleUrls: ['./cancel-employement.component.scss']
})
export class CancelEmployementComponent implements OnInit {
  reasonForCancellation: any = null;
  // jobType: any;
  currentUserRole: any = null;
  employementDetails: any = null;

  constructor(
    public dialogRef: MatDialogRef<CancelEmployementComponent>,
    @Inject(MAT_DIALOG_DATA) public employment: any,
    private appService: AppService,
    private jobService: JobService,
    private notify: NotificationService
  ) { }

  async ngOnInit() {
    console.log(this.employment)
    await this.getCurrentRole();
    await this.shiftTimeGet();
    await this.getCancelShiftDetails();
  }

  // get cancel shift data by job id
  async getCancelShiftDetails() {
    this.employementDetails = null;
    this.jobService.getCancelShiftDetails(this.employment.id).toPromise()
      .then(async data => {
        this.employementDetails = data;
        console.log(this.employementDetails)
        this.reasonForCancellation = this.employementDetails.employment.cancellationReason;
      })
  }

  // get current user role
  getCurrentRole() {
    this.appService.currentUser.subscribe(currentUser => {
      currentUser.roles.forEach(ele => {
        this.currentUserRole = ele;
        console.log(this.currentUserRole)
      })
    })
  }

  shiftTimeGet() {
    let startTime = DateTime.local(this.employment.dates[0].date[0], this.employment.dates[0].date[1], this.employment.dates[0].date[2],
      this.employment.dates[0].timeFrom[0], this.employment.dates[0].timeFrom[1]);
    let hourDiffence = DateTime.local().diff(startTime, 'hours').hours;

    console.log("hourDiffence----", hourDiffence)

    if (hourDiffence < -12) {
      console.log("No charges");
    }

    if (hourDiffence >= -12 && hourDiffence < -2) {
      console.log("10% charges");
    }

    if (hourDiffence >= -2 && hourDiffence < 0) {
      console.log("30% charges");
    }

    if (hourDiffence >= 0) {
      console.log("Not able to cancel because shift already started!");
    }
  }

  // cancel shift
  cancelShift() {
    console.log(this.reasonForCancellation, this.employment)
    let param = {
      "employmentId": this.employment.id,
      "reason": this.reasonForCancellation
    }
    this.jobService.cancelShift(param).toPromise()
      .then(async (data: any) => {
        this.notify.showMessage('Shift cancelled successfully!', 2000);
        this.dialogRef.close(true);
      })
  }
}
