import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { JobSeeker } from '../../interfaces/job-seeker/job-seeker.model';
import { API_JOB_SEEKER, API_PROFILE_STATUS_DETAIL } from "../util/api.endpoints";
import { BaseService } from "../base.service";
import { Observable } from 'rxjs';
import { JobTypeStatus, JobTypeStatusUpdate } from 'src/app/modules/job-seeker/update-status.model';
import { Search } from 'src/@core/interfaces/search';
import { PageableResponse } from 'src/@core/interfaces/pageable-response';

@Injectable({
  providedIn: 'root'
})
export class JobSeekerService extends BaseService<JobSeeker> {
  constructor(private http: HttpClient) {
    super(http, API_JOB_SEEKER);
  }

  getAllJobSeekerPref(page: number, size: number, sort: string, order: string, searchList?: Search[]): Observable<any> {
    let params = new HttpParams();
    let url = API_JOB_SEEKER + `/getAllJobSeekerPref` + '?page=' + page;
    if (size != null) {
      url += '&size=' + size;
    }

    if (order !== 'desc') {
      order = 'asc';
    }
    if (sort != null) {
      url += ('&sort=' + sort + ',' + order);
    } else {
      url += ('&sort=createdOn,desc');
    }

    if (searchList && searchList.length > 0) {
      searchList.forEach((search) => {
        if (search.column && search.content) {
          params = params.append(search.column, search.content);
        }
      });
    }

    return this.http.get<PageableResponse<JobSeeker>>(url, { params: params });
  }

  getJobSeekersDetails(sort: string, order: string, searchList?: Search[], cacheKey?: any): Observable<any> {
    let params = new HttpParams();
    let url = API_JOB_SEEKER + `/details` + '?';

    if (order !== 'desc') {
      order = 'asc';
    }
    if (sort != null) {
      url += ('&sort=' + sort + ',' + order);
    } else {
      url += ('&sort=createdOn,desc');
    }
    if (searchList && searchList.length > 0) {
      searchList.forEach((search) => {
        if (search.column && search.content) {
          params = params.append(search.column, search.content);
        }
      });
    }
    return this.http.get<any>(url, { params: params });
  }


  updateProfileStatus(id: string, request: JobTypeStatusUpdate): Observable<any> {
    const url = API_JOB_SEEKER + `/profileStatus/${id}`;
    return this.http.put<any>(url, request);
  }

  updateJobPrefLevel(id: string, request: JobTypeStatusUpdate): Observable<any> {
    const url = API_JOB_SEEKER + `/updateJobSeekerLevel/${id}`;
    return this.http.put<any>(url, request);
  }

  updateRejectedStatus(id: string, request: any): Observable<any> {
    const url = API_JOB_SEEKER + `/updateRejected/${id}`;
    return this.http.put<any>(url, request);
  }

  getLatestComment(jobSeekerId: string): Observable<any> {
    const url = API_PROFILE_STATUS_DETAIL + `/getLatestByJobSeekerId/${jobSeekerId}`;
    return this.http.get<string>(url, { responseType: 'string' as 'json' });
  }

  async exportReport(ids: any, request: any): Promise<Blob> {
    let url = API_JOB_SEEKER + `/report/excel?`;
    let params = ''
    ids.forEach(el => {
      params = params + 'id=' + el + '&';
    });
    url = url + params
    // let params = new HttpParams();
    // ids.forEach(el => { params.set('id', el); });

    const file = await this.http.post<Blob>(url,
      request, { responseType: 'blob' as 'json' }).toPromise();
    return file;
  }
  // Function to find a job seeker by phone number
  findJobSeekerByPhone(phoneNumber: string): Observable<JobSeeker> {
    const apiUrl = API_JOB_SEEKER + `/mobile/${phoneNumber}`;
    return this.http.get<JobSeeker>(apiUrl);
  }
}
