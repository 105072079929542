import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'hour4u-admin-fail-screen',
  templateUrl: './fail-screen.component.html',
  styleUrls: ['./fail-screen.component.scss']
})
export class FailScreenComponent implements OnInit {

  tryAgainObserver = new Subject();
  constructor(
    public router: Router
  ) { }

  ngOnInit() {
  }

  tryAgain() {
    this.tryAgainObserver.next();
  }

  postAnotherShift() {
    this.router.navigateByUrl('employment/all/all');
  }

  chatWithSupport() {
    window.open('https://api.whatsapp.com/send?phone=' + environment.contactSuppportNumber, "_blank");
  }
}
