
import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'hour4u-admin-ErrorDialog',
  templateUrl: './ErrorDialog.component.html',
  styleUrls: ['./ErrorDialog.component.scss']
})
export class ErrorDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { message: string; subErrors?: any[] }
  ) {}

}
