import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Search } from 'src/@core/interfaces/search';
import { AppService, CURRENT_EMPLOYER } from 'src/@core/services/app.service';
import { EmploymentService } from 'src/@core/services/employment/employment.service';
import { roles } from 'src/app/shared/helpers/roles';
import { EmployementPaymentOrder } from "src/@core/interfaces/employment/employement-payment-order.model";
import { EmployeerPaymentOrderService } from "src/@core/services/employment/employment-payment-order.service";
import { NotificationService } from 'src/@core/services/notification.service';
import { environment } from 'src/environments/environment';
import { API_INVOICE } from 'src/@core/services/util/api.endpoints';
declare var Razorpay: any;




@Component({
  selector: 'hour4u-admin-view-order',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.scss']
})
export class ViewOrderComponent implements OnInit {
  order: any;
  roles = roles;
  loginEmployerDetails: any = null;
  rezorpay_order_id: string = null;
  isSubmitted: boolean = false;
  isLoading: boolean = false;
  selectedInvoicesForPay: any[] = [];
  profile = this.appService.getCurrentAccount();
  pageIndex: number = 0;
  paymentDate: any;
  orderId: any;





  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private employmentService: EmploymentService,
    private notify: NotificationService,
    private employeerPaymentOrderService: EmployeerPaymentOrderService,
    private appService: AppService
  ) { }

  ngOnInit() {
    this.getOrderDetails();
    this.loginEmployerDetails = JSON.parse(localStorage.getItem(CURRENT_EMPLOYER));
    if (!this.loginEmployerDetails) {
      this.loginEmployerDetails =
      {
        advanceAmountPercentage: 20
      }
    }
    this.fetchInvoiceUpdatedDate();
  }

  getOrderDetails() {
    // Get the employment ID from the URL parameter
    this.route.params.subscribe(params => {
      const employmentId = params['id'];

      if (employmentId) {
        const empSearchParams: Search[] = [{ column: 'id', content: employmentId }];

        this.employmentService.searchAndSortEmployment(0, 1, null, null, empSearchParams, 'Employment')
          .subscribe(
            (response) => {
              this.order = response.content[0];

              if (this.order) {
                this.order.id = employmentId;
                console.log('Employment details:', this.order);
              } else {
                console.error('No employment found for the given ID.');
              }
            },
            (error) => {
              console.error('Error fetching employment details:', error);
            }
          );
      } else {
        console.error('No employment ID found in the URL parameter.');
      }
    });
  }

  showEntireShift(employmentId: string) {
    this.router.navigate([`/employment/job-applications/all/${this.order.id}`]);
  }


  contactSupport() {
    window.location.href = 'https://wa.me/919560412861';
  }

  // Payment option open razorpay
  payAmount(order: any) {
    this.isSubmitted = true;
    this.isLoading = true;
    let paymentOrderRequest = new EmployementPaymentOrder();

    console.log("order", order)

    const calculatedAmount = (order.totalAmount * 1.18) * (this.loginEmployerDetails.advanceAmountPercentage / 100);
    paymentOrderRequest.amount = parseFloat(calculatedAmount.toFixed(2));
    paymentOrderRequest.employer_id = order.employerId;
    paymentOrderRequest.employment_id = order.id;

    if (!order.razorPayOrderId) {
      if (paymentOrderRequest.amount >= 0) {
        this.employeerPaymentOrderService.create(paymentOrderRequest).toPromise().then(
          (createdOrder: any) => {
            this.isSubmitted = false;
            this.isLoading = false;
            this.openRezorpay(createdOrder);
          }).catch(err => {
            this.isSubmitted = false;
            this.isLoading = false;
            console.log(err);
          });
      } else {
        this.isSubmitted = false;
        this.notify.showMessage("Amount is incorrect. Kindly check it once!", 3000);
        this.isLoading = false;
      }
    } else {
      let orderData = {
        status: 'SUCCESS',
        result: {
          amount: paymentOrderRequest.amount,
          razorPayOrderId: order.razorPayOrderId
        }
      };
      this.isSubmitted = false;
      this.rezorpay_order_id = order.razorPayOrderId;
      this.openRezorpay(orderData);
      this.isLoading = false;
    }
  }


  openRezorpay(order, fullPay?: boolean) {
    if (order != null && order.status == 'SUCCESS') {
      if (!fullPay) {
        this.razorPayOptions.amount = order.result.amount * 100; // Adjust based on employment details
      } else {
        this.razorPayOptions.amount = order.result.amount; // Adjust based on employment details
      }
      this.razorPayOptions.currency = 'INR';
      this.razorPayOptions.key = environment.rezorpayKey;
      this.razorPayOptions.order_id = order.result.razorPayOrderId; // Adjust based on employment details

      var rzp = new Razorpay(this.razorPayOptions);
      rzp.open()
      rzp.on('payment.failed', (response) => {
        console.log("response--", response)
        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
      });
    } else {
      this.notify.showMessage("Something went wrong!", 3000);
    }
  }



  // Payment options
  razorPayOptions = {
    "key": '',
    "amount": 0,
    "currency": '',
    "name": '',
    "discription": "hours4u payment",
    "order_id": '',
    "handler": (response) => {
      let paymentOrderRequest = new EmployementPaymentOrder();
      paymentOrderRequest.razorpay_order_id = response.razorpay_order_id ? response.razorpay_order_id : this.rezorpay_order_id;
      paymentOrderRequest.razorpay_payment_id = response.razorpay_payment_id;
      paymentOrderRequest.razorpay_signature = response.razorpay_signature;
      paymentOrderRequest.razorpay_invoice_id = response.razorpay_invoice_id;
      this.employeerPaymentOrderService.update(paymentOrderRequest.razorpay_order_id, paymentOrderRequest).toPromise().then(res => {
        this.notify.showMessage("Paid Advance amount successfully!", 3000);
        this.selectedInvoicesForPay = [];
        this.getOrderDetails();
      })
    }
  };

  // download invoice
  async downloadInvoice(order: any) {
    try {
      const employmentId = order.id; // Get the employmentId from the selected order

      if (!employmentId) {
        console.error('No employmentId found in the order.');
        return;
      }

      const url = `${API_INVOICE}?employerId=${this.profile.user_details.employerId}&page=${this.pageIndex}&size=${1000}&sort=createdOn,desc`;
      const response = await this.http.get<any>(url).toPromise();

      if (response && 'content' in response) {
        const invoices = response.content || [];


        // Find the invoice with matching employmentId
        const invoiceWithMatchingEmploymentId = invoices.find((invoice: any) => invoice.employment.id === employmentId);

        if (invoiceWithMatchingEmploymentId && invoiceWithMatchingEmploymentId.invoiceShortUrl) {
          const invoiceShortUrl = invoiceWithMatchingEmploymentId.invoiceShortUrl;
          console.log('Invoice Short URL:', invoiceShortUrl);

          window.open(invoiceShortUrl, '_blank');
        } else {
          console.error('Invoice short URL not found for the employment.');
        }
      } else {
        console.error('Invalid response format or missing content property.');
      }
    } catch (error) {
      console.error('Error fetching/download invoice:', error);
    }
  }

  async fetchInvoiceUpdatedDate() {
    try {
      let employmentId = '';

      // Get the employment ID from the URL parameter
      this.route.params.subscribe(params => {
        employmentId = params['id'];
      });

      if (!employmentId) {
        console.error('No employment ID found in the URL parameter.');
        return;
      }

      const url = `${API_INVOICE}?employerId=${this.profile.user_details.employerId}&page=${this.pageIndex}&size=${1000}&sort=createdOn,desc`;
      const response = await this.http.get<any>(url).toPromise();

      if (response && 'content' in response) {
        const invoices = response.content || [];

        // Find the invoice with matching employmentId
        const invoiceWithMatchingEmploymentId = invoices.find((invoice: any) => invoice.employment.id === employmentId);
        console.log('invoiceWithMatchingEmploymentId:', invoiceWithMatchingEmploymentId)

        if (invoiceWithMatchingEmploymentId && invoiceWithMatchingEmploymentId.updatedOn) {
          this.paymentDate = invoiceWithMatchingEmploymentId.updatedOn;
          this.orderId = invoiceWithMatchingEmploymentId.id;
          console.log('Invoice Updated Date:', this.paymentDate);
          // Handle the updated date as needed
        } else {
          console.error('Invoice updated date not found for the employment.');
        }
      } else {
        console.error('Invalid response format or missing content property.');
      }
    } catch (error) {
      console.error('Error fetching invoice updated date:', error);
    }
  }



}
