import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-column-visibility-dialog',
  templateUrl: './column-visibility-dialog.component.html',
  styleUrls: ['./column-visibility-dialog.component.scss']
})
export class ColumnVisibilityDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ColumnVisibilityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public columns: any) { }

ngOnInit() {}

close(): void {
  this.dialogRef.close(this.columns);
}

toggleColumnVisibility(column, event) {
  event.stopPropagation();
  event.stopImmediatePropagation();
  column.visible = !column.visible;
}

}
