import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from './layout/layout.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { AuthGuard } from './guards/auth.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './interceptors';
import { throwIfAlreadyLoaded } from './guards/module-import.guard';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from '../app/shared/shared.module';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { HttpErrorInterceptor } from 'src/app/shared/base/HttpErrorInterceptor';
import { ErrorDialogComponent } from 'src/app/shared/base/ErrorDialog/ErrorDialog.component';


@NgModule({
  declarations: [ErrorDialogComponent],
  imports: [
    CommonModule,
    LayoutModule,
    BrowserAnimationsModule,
    SharedModule,
    
  ],
  exports: [
    CommonModule,
    LayoutModule,
    BrowserAnimationsModule,

    SharedModule
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    },
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
  ],
  entryComponents: [ErrorDialogComponent]
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
