import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { API_BOOK } from 'src/@core/services/util/api.endpoints';
import { AppService } from 'src/@core/services/app.service';
import { AccountService } from 'src/@core/services/account.service';
import { CURRENT_EMPLOYER } from 'src/@core/services/app.service';
import { environment } from 'src/environments/environment';
import { EmployementPaymentOrder } from 'src/@core/interfaces/employment/employement-payment-order.model';
import { EmployeerPaymentOrderService } from 'src/@core/services/employment/employment-payment-order.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/@core/services/notification.service';


declare var Razorpay: any;




@Component({
  selector: 'hour4u-admin-book-society-dialog',
  templateUrl: './book-society-dialog.component.html',
  styleUrls: ['./book-society-dialog.component.scss']
})
export class BookSocietyDialogComponent implements OnInit {
  society: any;
  bookingDate: any;
  numberOfHours: any;
  totalExpectedAmount: any;
  profile = this.appService.getCurrentAccount();
  isButtonDisabled: boolean = false;
  isLoading: boolean = false;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BookSocietyDialogComponent>,
    private http: HttpClient,
    private appService: AppService,
    private accountService: AccountService,
    private employeerPaymentOrderService: EmployeerPaymentOrderService,
    private router: Router,
    private notify: NotificationService,
  ) { }

  ngOnInit() {
    this.society = this.data.society;
    this.bookingDate = this.data.bookingDate;
    this.numberOfHours = this.data.numberOfHours;

    const rentPerHour = this.data.society.rentPerDay / 10;
    const numberOfHours = this.data.numberOfHours;

    const baseRent = rentPerHour * numberOfHours;
    const platformFees = baseRent * 0.05;
    const serviceChargesAndTaxes = (baseRent + platformFees) * 0.18;

    this.totalExpectedAmount = baseRent + platformFees + serviceChargesAndTaxes;
  }

  razorPayOptions = {
    "amount": 0,
    currency: 'INR',
    key: environment.rezorpayKey,
    order_id: '',
    invoice_id: '',
    "handler": (paymentResponse) => {
      this.notify.showMessage("Payment success and Booking saved Redirecting to my bookings ", 2000);
      this.dialogRef.close(true);
      this.router.navigate(['/societies/bookings']);
      console.log('Payment successful:', paymentResponse);
    },
    "modal": {
      "ondismiss": () => {
        this.dialogRef.close();
      }
    }
  };


  bookAndPay() {
    this.isButtonDisabled = true;
    this.isLoading = true;
    console.log(this.profile.user_details)

    const requestBody = {
      userId: this.profile.user_details.employerId,
      userMeta: {
        name: this.profile.user_details.name,
        address: 'Pune',
        contactNo: this.profile.user_details.mobile,
        gstNumber: 'AAAANNN1234',
        email: this.profile.user_details.email
      },
      propertyId: this.society.id,
      slot: {
        startDate: this.bookingDate,
        endDate: this.bookingDate,
        timeFrom: '11:00',
        timeTo: '18:00'
      }
    };

    this.http.post(API_BOOK, requestBody).subscribe(
      (response: any) => {
        console.log('Successfully booked society:', response);

        this.razorPayOptions.amount = response.totalAmount * 100;
        this.razorPayOptions.order_id = response.razorPayOrderId;
        this.razorPayOptions.invoice_id = response.razorPayInvoiceId;

        const rzp = new Razorpay(this.razorPayOptions);
        rzp.open();

        rzp.on('payment.failed', function (response) {
          // Handle payment failure
        });
      },
      (error) => {
        console.error('Error while booking society:', error);
      },
      () => {
        // This block executes after the HTTP request completes (regardless of success or error)
        this.isLoading = false;
        this.isButtonDisabled = true;
      }
    );
  }



  updateBookingStatus(bookingId: string) {
    const updateData = {
      bookingStatus: 'APPROVED'
    };

    this.http.put<any>(`${API_BOOK}/${bookingId}`, updateData)
      .subscribe(
        (response) => {
          console.log('Booking status updated:', response);
        },
        (error) => {
          console.error('Error updating booking status:', error);
        }
      );
  }





  closeDialog() {
    this.dialogRef.close();
  }

}
