import {Pipe, PipeTransform} from '@angular/core';
import { map } from 'rxjs/operators';
import { JobCategoryService } from 'src/@core/services/job/job-category.service';

@Pipe({
  name: 'getCategory'
})
export class CategoryPipe implements PipeTransform {

  constructor(
    private service: JobCategoryService
  ) { }

   transform(val: any | null) {
    if (!val) {
      return;
    }
    return this.service.getById(val).pipe(
      map((data) => {
         return data ? data.name : '';
      })
    );
  }
}
