import { Component, OnInit, Inject } from '@angular/core';
import { JobSeekerService } from 'src/@core/services/job-seeker/job-seeker.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'hour4u-admin-payment-filter-dialog',
  templateUrl: './payment-filter-dialog.component.html',
  styleUrls: ['./payment-filter-dialog.component.scss']
})
export class PaymentFilterDialogComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<PaymentFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private jobSeekerService: JobSeekerService) {
  }

  ngOnInit() {
    
  }

  onApplyFilter() {
    this.dialogRef.close(this.data);
  }
}
