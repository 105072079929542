import {Pipe, PipeTransform} from '@angular/core';
import { EmployerService } from 'src/@core/services/employer/employer.service';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'getEmployer'
})
export class EmployerPipe implements PipeTransform {

  constructor(
    private employerService: EmployerService
  ) { }

   transform(val: any | null) {
    if (!val) {
      return;
    }
    return this.employerService.getById(val).pipe(
      map((data) => {
        return data ? data.name : '';
      })
    );
  }
}
