// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  log: true,
  environmentName: 'local',
  version: 5.5,
  // api_url: 'https://gjtanl9v5f.execute-api.ap-south-1.amazonaws.com/dev', // DEV url
  api_url: 'https://devapi.hour4u.com', // DEV url

  // Base URLs for AWS services
  // orderService: 'https://ylgvfw8dz0.execute-api.ap-south-1.amazonaws.com/dev/payment-service',
  // ratingService: 'https://ccpff30ztf.execute-api.ap-south-1.amazonaws.com/dev/rating-service',
  // authService: 'https://ugmkgcsq10.execute-api.ap-south-1.amazonaws.com/dev/auth-service/v1',
  // jobService: 'https://ugmkgcsq10.execute-api.ap-south-1.amazonaws.com/dev/enterprise-job-service',
  // notificationService: 'https://5od4uho60b.execute-api.ap-south-1.amazonaws.com/dev/notification-service',
  // jobSeekerService: 'https://ehxnmz317d.execute-api.ap-south-1.amazonaws.com/dev/job-seeker',
  // enterpriseAdminService: 'https://gjtanl9v5f.execute-api.ap-south-1.amazonaws.com/dev/enterprise-job-service/v1',


  orderService: 'https://szqnb63cuh.execute-api.ap-south-1.amazonaws.com/prod/payment-service',
  ratingService: 'https://rm12v4ptva.execute-api.ap-south-1.amazonaws.com/prod/rating-service',
  authServiceLogin: 'https://qmku7ljs0f.execute-api.ap-south-1.amazonaws.com/prod',
  authService: 'https://qmku7ljs0f.execute-api.ap-south-1.amazonaws.com/prod/auth-service',
  jobService: 'https://2tkmhqezve.execute-api.ap-south-1.amazonaws.com/prod/job-service',
  notificationService: 'https://6uyz07jhlk.execute-api.ap-south-1.amazonaws.com/prod/notification-service',
  jobSeekerService: 'https://wksfoidka9.execute-api.ap-south-1.amazonaws.com/prod/job-seeker',
  enterpriseAdminService: 'https://gjtanl9v5f.execute-api.ap-south-1.amazonaws.com/dev/enterprise-job-service/v1',

  image_url: 'https://image-data-hour4u-2.s3.ap-south-1.amazonaws.com/',

  rezorpayKey: 'rzp_test_frWcC85VwYGHuv', // UAT KEY
  // rezorpayKey: 'rzp_live_0lOqy628mUvmnm', // PROD KEY
  contactSuppportNumber: "917397998705",
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.