import { Directive } from '@angular/core';

@Directive({
  selector: '[codemiroPageLayoutContent],codemiro-page-layout-content',
  host: {
    class: 'codemiro-page-layout-content'
  }
})
export class PageLayoutContentDirective {

  constructor() { }

}
