
export class JobSeekerPreference {
  id: string;
  jobSeekerId: string;
  maxDistance: string;
  availability: AvailabilityPreference[];
  jobTypePreferences: JobTypePreference[];

  notes?: string;
  createdBy?: string;
  updatedBy?: string;
  createdOn?: Date;
  updatedOn?: Date;
}

export class AvailabilityPreference {
  dateFrom: string;
  dateTo: string;
  day: string;
}

export class JobTypePreference {
  typeId: string;
  typeName: string;
  level: string;
  maxHourlyRate: any;
  status: string;
}

