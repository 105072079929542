import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DateTime } from "luxon";
import { Employer } from "src/@core/interfaces/employer/employer.model";
import { Dates, Employment } from "src/@core/interfaces/employment/employment.model";
import { Attendance } from "src/@core/interfaces/job/attendance.model";
import { JobApplication } from "src/@core/interfaces/job/job-application.model";
import { AttendanceService } from "src/@core/services/job/attendance.service";
import { NotificationService } from "src/@core/services/notification.service";

@Component({
    selector: 'hour4u-admin-mark-attendance-dialog',
    templateUrl: './mark-attendance-dialog.component.html',
    styleUrls: ['./mark-attendance-dialog.component.scss']
})

export class MarkAttendanceDialogComponent implements OnInit {

    attendance: Attendance;
    jobApplication: JobApplication;
    employer: Employer;
    employment: Employment;
    date: Dates;
    form: FormGroup;
    //minTimeTo = "01:00";
    isSubmitted: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<MarkAttendanceDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private notify: NotificationService,
        private attendanceService: AttendanceService,
        private _fb: FormBuilder
    ) { }

    ngOnInit() {
        this.attendance = this.data.attendance;
        this.jobApplication = this.data.jobApplication;
        this.employer = this.data.employer;
        this.employment = this.data.employment;
        this.date = this.data.date;

        this.form = this._fb.group({
            checkIn: [''],
            checkOut: ['']
        });
        this.setTimes();
    }

    setTimes() {
        if (this.attendance.checkIn != null) {
            let correctHour = this.attendance.checkIn[3].toString().length == 1 ? "0" + this.attendance.checkIn[3] : this.attendance.checkIn[3];
            let correctMin = this.attendance.checkIn[4].toString().length == 1 ? "0" + this.attendance.checkIn[4] : this.attendance.checkIn[4];
            this.form.controls.checkIn.patchValue(correctHour + ":" + correctMin);
        }
        if (this.attendance.checkOut != null) {
            let correctHour = this.attendance.checkOut[3].toString().length == 1 ? "0" + this.attendance.checkOut[3] : this.attendance.checkOut[3];
            let correctMin = this.attendance.checkOut[4].toString().length == 1 ? "0" + this.attendance.checkOut[4] : this.attendance.checkOut[4];
            this.form.controls.checkOut.patchValue(correctHour + ":" + correctMin);
        }

    }

    getRecTimeDiff(checkIn, checkOut) {
        let startTime = DateTime.local(checkIn[0], checkIn[1], checkIn[2], checkIn[3], checkIn[4]);
        let endTime = DateTime.local(checkOut[0], checkOut[1], checkOut[2], checkOut[3], checkOut[4]);
        //return endTime.diff(startTime, 'hours').hours.toFixed(2);
        //let shiftLength = endTime.diff(startTime, 'hours').hours < 0 ? (endTime.diff(startTime, 'hours').hours+24).toFixed(2) : endTime.diff(startTime, 'hours').hours.toFixed(2);
        return endTime.diff(startTime, 'hours').hours;
    }

    close() {
        this.dialogRef.close('Canceled');
    }

    onSubmit() {
        this.isSubmitted = true;
        let currDate = new Date();
        let attDate = new Date(this.date.date);
        if (attDate > currDate) {
            this.notify.showMessage('Attendance cannot be marked for future date', 2000);
            this.isSubmitted = false;
            return;
        }

        if (this.form.controls.checkIn.value == null || this.form.controls.checkIn.value == '') {
            this.notify.showMessage('Check in time cannot be empty', 2000);
            this.isSubmitted = false;
            return;
        }

        let startDate = null, endDate = null;
        if (this.form.controls.checkIn.value != null && this.form.controls.checkIn.value != '') {
            startDate = new Date(this.date.date);
            startDate.setHours(this.form.controls.checkIn.value.split(':')[0], this.form.controls.checkIn.value.split(':')[1]);
        }
        if (this.form.controls.checkOut.value != null && this.form.controls.checkOut.value != '') {
            endDate = new Date(this.date.date);
            endDate.setHours(this.form.controls.checkOut.value.split(':')[0], this.form.controls.checkOut.value.split(':')[1]);
        }

        /*if(startDate != null && endDate != null && (startDate > endDate)) {
            this.notify.showMessage('Check in time cannot be later than Check out time', 2000);
            return;
        }*/

        let currAttendance = new Attendance();
        let endTimeDiff: any;
        let jobFin: any;
        let startTimeDiff: any;
        let jobStart: any;

        if (this.attendance.id != null) {
            currAttendance = Object.assign({}, this.attendance);
        }
        else {
            this.attendance = new Attendance();
        }

        if (startDate != null) {
            currAttendance.checkIn = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000));
            if (this.attendance.checkIn == null) {
                this.attendance.checkIn = new Date();
            }
            this.attendance.checkIn[0] = startDate.getFullYear();
            this.attendance.checkIn[1] = startDate.getMonth() + 1;
            this.attendance.checkIn[2] = startDate.getDate();
            this.attendance.checkIn[3] = startDate.getHours();
            this.attendance.checkIn[4] = startDate.getMinutes();
        }
        if (endDate != null) {
            currAttendance.checkOut = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000));

            if (this.attendance.checkOut == null) {
                this.attendance.checkOut = new Date();
            }
            this.attendance.checkOut[0] = endDate.getFullYear();
            this.attendance.checkOut[1] = endDate.getMonth() + 1;
            this.attendance.checkOut[2] = endDate.getDate();
            this.attendance.checkOut[3] = endDate.getHours();
            this.attendance.checkOut[4] = endDate.getMinutes();
        }

        if (startDate != null && endDate != null &&
            this.form.controls.checkIn.value != null && this.form.controls.checkIn.value != '' &&
            this.form.controls.checkOut.value != null && this.form.controls.checkOut.value != '') { //checkIn control != '' & checkOut control != ''
            jobStart = Object.assign({}, this.attendance.checkIn);
            jobStart[3] = this.employment.dates[0].timeFrom[0];
            jobStart[4] = this.employment.dates[0].timeFrom[1];
            startTimeDiff = this.getRecTimeDiff(this.attendance.checkIn, jobStart);
            if (startTimeDiff < -2) {
                startTimeDiff = startTimeDiff + 24;
            }
            else if (startTimeDiff > 2) {
                startTimeDiff = startTimeDiff - 24;
            }
            else {
            }

            jobFin = Object.assign({}, this.attendance.checkOut);
            jobFin[3] = this.employment.dates[0].timeTo[0];
            jobFin[4] = this.employment.dates[0].timeTo[1];
            endTimeDiff = this.getRecTimeDiff(jobFin, this.attendance.checkOut);
            if (endTimeDiff < -2) {
                endTimeDiff = endTimeDiff + 24;
            }
            else if (endTimeDiff > 2) {
                endTimeDiff = endTimeDiff - 24;
            }
            else {
            }

            let shiftEnd =
                DateTime.local(this.employment.dates[0].date[0],
                    this.employment.dates[0].date[1],
                    this.employment.dates[0].date[2],
                    this.employment.dates[0].timeTo[0],
                    this.employment.dates[0].timeTo[1]);

            let outDate =
                DateTime.local(this.attendance.checkOut[0],
                    this.attendance.checkOut[1],
                    this.attendance.checkOut[2],
                    this.attendance.checkOut[3],
                    this.attendance.checkOut[4]);

            if (Number(shiftEnd.diff(outDate, 'hours').hours.toFixed(2)) < 0) {
                this.attendance.checkOut[0] = this.employment.dates[0].date[0]
                this.attendance.checkOut[1] = this.employment.dates[0].date[1]
                this.attendance.checkOut[2] = this.employment.dates[0].date[2]
                this.attendance.checkOut[3] = this.employment.dates[0].timeTo[0]
                this.attendance.checkOut[4] = this.employment.dates[0].timeTo[1]
                currAttendance.checkOut = this.attendance.checkOut
            }

            let shiftStart =
                DateTime.local(this.employment.dates[0].date[0],
                    this.employment.dates[0].date[1],
                    this.employment.dates[0].date[2],
                    this.employment.dates[0].timeFrom[0],
                    this.employment.dates[0].timeFrom[1]);

            let inDate =
                DateTime.local(this.attendance.checkIn[0],
                    this.attendance.checkIn[1],
                    this.attendance.checkIn[2],
                    this.attendance.checkIn[3],
                    this.attendance.checkIn[4]);

            if (Number(shiftStart.diff(inDate, 'hours').hours.toFixed(2)) > 0.5) {
                this.notify.showMessage("Error, Please enter valid Start Date!", 2000);
                this.isSubmitted = false;
                return;
            }

            if (Number(inDate.diff(shiftEnd, 'hours').hours.toFixed(2)) > 0) {
                this.notify.showMessage("Error, Please enter valid Start Date!", 2000);
                this.isSubmitted = false;
                return;
            }

            let strictStartTime: any;
            let strictEndTime: any;
            strictStartTime = Object.assign({}, this.attendance.checkIn);
            strictEndTime = Object.assign({}, this.attendance.checkOut);
            if (endTimeDiff > 0) {
                strictEndTime = Object.assign({}, jobFin);
            }
            if (startTimeDiff > 0) {
                strictStartTime = Object.assign({}, jobStart);
            }

            let shiftLength = this.getRecTimeDiff(strictStartTime, strictEndTime);
            this.attendance.totalRecordedTime = shiftLength < 0 ? (shiftLength + 24).toFixed(2) : shiftLength.toFixed(2);

            currAttendance.totalRecordedTime = this.attendance.totalRecordedTime;
        }
        console.log(currAttendance.totalRecordedTime)
        if (this.attendance.id != null && startDate != null) {
            this.attendanceService.SAupdate(this.attendance.id, currAttendance).subscribe(data => {
                this.isSubmitted = false;
                this.notify.showMessage("Attendance Updated successfully!", 2000);
            }, (error) => {
                this.isSubmitted = false;
                this.notify.showMessage("Error, Could not update attendance", 2000);
            }, () => {
                this.isSubmitted = false;
                this.dialogRef.close('Updated attendance');
            });
        }
        else if (startDate != null) {
            currAttendance.jobSeekerId = this.jobApplication.jobSeekerId;
            currAttendance.jobSeekerName = this.jobApplication.tempJobSeeker.name;
            currAttendance.employmentId = this.jobApplication.employmentId;
            currAttendance.employerId = this.employer.id;

            this.attendanceService.SAcreate(currAttendance).subscribe(data => {
                this.isSubmitted = false;
                this.notify.showMessage("Attendance Created successfully!", 2000);
            }, (error) => {
                this.isSubmitted = false;
                this.notify.showMessage("Error, Could not create attendance", 2000);
            }, () => {
                this.isSubmitted = false;
                this.dialogRef.close('Created attendance');
            });
        }
        else {
            this.isSubmitted = false;
            this.notify.showMessage("Could not mark attendance", 2000);
            this.dialogRef.close('Canceled');
        }
    }
}  