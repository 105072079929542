import { Component, OnInit, Input } from '@angular/core';
import { scaleFadeIn400ms } from 'src/@core/animations/scale-fade-in.animation';

@Component({
  selector: 'hour4u-no-data-found',
  templateUrl: './no-data-found.component.html',
  styleUrls: ['./no-data-found.component.scss'],
  animations: [scaleFadeIn400ms]
})
export class NoDataFoundComponent implements OnInit {

  @Input() message: string;
  constructor() { }

  ngOnInit() {
  }

}
