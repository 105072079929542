import { JobType } from './job-type.model';
import { Employer } from '../employer/employer.model';
import { Employment } from '../employment/employment.model';

export class Job {
  approved: boolean;
  code: string;
  createdBy: string;
  createdOn: string;
  deleteBy: boolean;
  description: string;
  dressCodeFemale: string;
  dressCodeMale: string;
  employerId: string;
  id: string;
  isDelete: boolean;
  jobTypeId: string;
  prerequisites: string;
  requirements: string;
  tasks: string[];
  title: string;
  updatedBy: string;
  updatedOn: string;

  // other
  jobType: JobType;
  employerName: string;
  employer: Employer;
  employment: Employment;
}
