import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, PageEvent } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { EmploymentService } from 'src/@core/services/employment/employment.service';
import { Employment, Dates } from 'src/@core/interfaces/employment/employment.model';
import { NotificationService } from 'src/@core/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Search } from 'src/@core/interfaces/search';
import { EmployerService } from 'src/@core/services/employer/employer.service';
import { JobTypeService } from 'src/@core/services/job/job-type.service';
import { JobService } from 'src/@core/services/job/job.service';
import { JobSeeker } from 'src/@core/interfaces/job-seeker/job-seeker.model';
import { JobApplicationService } from 'src/@core/services/job/job-application.service';
import { JobApplication } from 'src/@core/interfaces/job/job-application.model';
import { JobSeekerService } from 'src/@core/services/job-seeker/job-seeker.service';
import { EmploymentHistoryService } from 'src/@core/services/employment/employment-history.service';
import { roles } from 'src/app/shared/helpers/roles';
import { environment } from 'src/environments/environment';
import { CURRENT_EMPLOYER } from 'src/@core/services/app.service';
import { STATUS } from 'src/app/shared/helpers/status';

@Component({
  selector: 'hour4u-admin-view-proof-of-work',
  templateUrl: './view-proof-of-work.component.html',
  styleUrls: ['./view-proof-of-work.component.scss']
})
export class ViewProofOfWorkComponent implements OnInit {
  loadComponent = true;
  employment: Employment;
  searchParams: Array<Search> = [];
  jobSeekers = Array<JobSeeker>();
  jobApplications: JobApplication[];
  jobApplicationPage: JobApplication[];
  activeDate: any;
  roles = roles;
  paramDate;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  resultsLength = 0;
  pageIndex = 0;
  copiedEmployer: boolean = false;
  copyTitleEmployer: string = 'Copy link to share';
  showNoAccessState: boolean = false;
  status = STATUS;
  routeData: any = null;
  selectedJobSeekerDetails: any = null;
  workImages: any[] = [];

  constructor(private route: ActivatedRoute,
    private employmentService: EmploymentService,
    private notify: NotificationService,
    private employerService: EmployerService,
    private jobService: JobService,
    private jobTypeService: JobTypeService,
    private jobApplicationService: JobApplicationService,
    private jobSeekerService: JobSeekerService,
    private employmentHistoryService: EmploymentHistoryService,
    private dialog: MatDialog,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.paramDate = this.route.snapshot.paramMap.get('date');
    document.getElementById('detail-header').style.display = 'none';

    this.route.params.subscribe(async data => {
      this.routeData = data;
      await this.loadEmploymentData(data.id);
      this.searchParams.push(new Search('employmentId', data.id));
      this.searchParams.push(new Search('seekerId', data.seekerId));
    });
  }

  loadMap() {
    this.loadComponent = false;
  }

  loadList() {
    this.loadComponent = true;
  }

  async loadEmploymentData(id: string) {
    await this.employmentService.getById(id).toPromise()
      .then(data => {
        this.employment = data;
        // fetch active date
        // finding active date to view active employment
        this.activeDate = this.employment.dates.find(x => x.isActive)

        // if user is view past employment
        // date will come along with navigation params
        // and active date will be replaced with requested date
        if (this.paramDate) {
          this.activeDate = this.employment.dates.find(x => x.date == this.route.snapshot.paramMap.get('date'))
        }
      }).catch(error => {
        this.notify.showMessage('Could not load employment data...', 2000);
      }).finally(async () => {
        // load employer
        await this.employerService.getById(this.employment.employerId).toPromise()
          .then(data => {
            this.employment.employer = data;
            this.showNoAccessState = false;
            if (this.employment && JSON.parse(localStorage.getItem(CURRENT_EMPLOYER))) {
              if (this.employment.employerId != JSON.parse(localStorage.getItem(CURRENT_EMPLOYER)).id) {
                document.getElementById('detail-header').style.display = 'none';
                this.showNoAccessState = true;
              } else {
                document.getElementById('detail-header').style.display = 'block';
                this.loadJob(this.employment.id);
                this.loadJobApplications();
              }
            } else {
              document.getElementById('detail-header').style.display = 'block';
              this.loadJob(this.employment.id);
              this.loadJobApplications();
            }
          });
      });
  }

  async loadJob(employmentId) {
    // load job
    await this.jobService.getById(this.employment.jobId).toPromise()
      .then(job => {
        this.employment.job = job;
      }).finally(async () => {
        await this.jobTypeService.getById(this.employment.job.jobTypeId).toPromise()
          .then(jobType => {
            this.employment.job.jobType = jobType;
          });
      })
  }

  async loadJobApplications() {
    this.notify.showMessage('Loading applicants .....', 2000);
    const serachParams = [new Search('employmentId', this.employment.id)];
    serachParams.push(new Search('status', 'COMPLETED'));
    serachParams.push(new Search('status', 'APPROVED'));

    await this.jobApplicationService.searchAndSort(0, 5000, null, null, serachParams).toPromise()
      .then(data => {
        if (data.content.length > 0) {
          this.jobApplications = data.content;
          this.resultsLength = this.jobApplications.length;

          this.jobApplications.forEach(async ja => {
            // laod js
            await this.jobSeekerService.getById(ja.jobSeekerId).toPromise()
              .then(js => {
                ja.tempJobSeeker = js;
              });

            // load history
            await this.employmentHistoryService.searchAndSort(0, 1000, null, null, [new Search('employmentId', this.employment.id), new Search('jobSeekerId', ja.jobSeekerId)]).toPromise()
              .then(data => {
                ja.history = data.content[0];
              });
          });
          this.jobApplicationPage = this.jobApplications.slice(0, this.pageSize);
          console.log("############", this.jobApplicationPage)
          this.workImages = [];
          // this.jobApplicationPage.forEach((ele: any) => {
          setTimeout(() => {
            for (let i = 0; i < this.jobApplicationPage.length; i++) {
              let ele = this.jobApplicationPage[i];
              console.log(ele, "))))))))))))")
              if (this.routeData && this.routeData.seekerId) {
                console.log("calllllllllllll ifffffffffffffffff")
                if (ele.jobSeekerId == this.routeData.seekerId) {
                  this.selectedJobSeekerDetails = ele;
                  // setTimeout(() => {
                  this.workImages = this.selectedJobSeekerDetails.history.imageProofs.filter(ip => ip.proofEnum == 'MIDDLE');
                  // }, 1500);
                }
              } else {
                console.log("calllllllllllll", ele)
                // this.selectedJobSeekerDetails = ele;
                // setTimeout(() => {
                let workImages = ele.history.imageProofs.filter(ip => ip.proofEnum == 'MIDDLE');
                console.log(workImages)
                if (workImages.length) {
                  workImages.forEach((el: any) => {
                    el.tempJobSeeker = ele;
                    this.workImages.push(el)
                  })
                }
                // }, 1500);
              }
            }
          }, 1500);
          // })
        }
      })
  }

  getPageData(e: PageEvent) {
    this.jobApplicationPage = this.jobApplications.slice(e.pageIndex * e.pageSize, (e.pageIndex + 1) * e.pageSize);
  }

  copyLinkToClipboardForEmployer() {
    let copyUrl = null;
    this.copiedEmployer = true;
    this.copyTitleEmployer = 'Copied to your clipboard!';

    if (environment.api_url == 'https://uatapi.hour4u.com') {
      copyUrl = 'https://uatemployer.hour4u.com' + this.router.url
    }
    if (environment.api_url == 'https://api.hour4u.com') {
      copyUrl = 'https://business.hour4u.com' + this.router.url
    }
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = copyUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notify.showMessage('Link copied!', 3000)
    setTimeout(() => {
      this.copiedEmployer = false;
      this.copyTitleEmployer = 'Copy link to share';
    }, 7000);
  }

  dataRefresh() {
    this.router.navigateByUrl('/employment/all/all');
  }

}
