import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'hour4u-admin-confirm-signature',
  templateUrl: './confirm-signature.component.html',
  styleUrls: ['./confirm-signature.component.scss']
})
export class ConfirmSignatureComponent implements OnInit {
  check: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmSignatureComponent>,
  ) { }

  ngOnInit() {
  }

  onSave(): void {
    this.dialogRef.close('success');
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
