import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'employmentTypeValue'
})
export class EmploymentTypePipe implements PipeTransform {
  transform(val: any | null): any {
    if (!val) {
      return '';
    }
    switch(val) {
      case 'SINGLE_DAY': 
        return 'Single Day';
      case 'MULTI_DAYS': 
        return 'Multiple Days';
      case 'RECURRING_DAYS': 
        return 'Recurring Days';
      default:
        return '';
    }
  }

}
