import {Pipe, PipeTransform} from '@angular/core';
import { map } from 'rxjs/operators';
import { JobService } from 'src/@core/services/job/job.service';

@Pipe({
  name: 'getJob'
})
export class JobPipe implements PipeTransform {

  constructor(
    private service: JobService
  ) { }

  transform(val: any | null) {
    if (!val) {
      return;
    }
    return this.service.getById(val).pipe(
      map((data) => {
        return data ? data.title : '';
      })
    ).toPromise();
  }

}
