import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Validators, FormBuilder } from '@angular/forms';
import { AccountService } from '../../../../@core/services/account.service'
import { AppService } from '../../../../@core/services/app.service'
import { NotificationService } from '../../../../@core/services/notification.service'

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {


form = this.fb.group({
  emailAddress: [this.appService.getCurrentAccount().email, Validators.required],
  newPassword: [null, Validators.required],
  oldPassword: [null, Validators.required],
});

isAdmin = false;

constructor(
  public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
  private fb: FormBuilder,
  private notify: NotificationService,
  private appService: AppService,
  @Inject(MAT_DIALOG_DATA) public data,
  private accountService: AccountService) { }

ngOnInit() {
  if(this.data) {
    this.isAdmin = !this.isAdmin;
  }
 }

submit(): void {
  if(!this.form.valid) {
    this.notify.showMessage("Invalid Entry!", 3000);
    return;
  }
  
  if(this.isAdmin && (this.form.controls['newPassword'].value !== this.form.controls['oldPassword'].value)) {
    this.notify.showMessage("Password must match!", 3000);
    return;
  }

  if(this.data && this.data.emailAddress != '') {
    this.form.controls['emailAddress'].setValue(this.data.emailAddress);
  }

  if(this.isAdmin) {
    this.adminChangePassword();    
  } else {
    this.changePassword();
  }

}

adminChangePassword() {
  this.accountService.adminChangePassword({
    "emailAddress": this.data.emailAddress,
    "newPassword": this.form.controls['newPassword'].value,
    "retypePassword": this.form.controls['oldPassword'].value
  }).subscribe(data => {
    this.notify.showMessage("Password changed successfully!", 3000);
  }, (error) => {
    this.notify.showMessage("Something went wrong!", 3000);
  }, () => {
    this.form.controls['emailAddress'].setValue('');
    this.form.controls['newPassword'].setValue('');
    this.form.controls['oldPassword'].setValue('');
    this.dialogRef.close();
  });
}

changePassword() {
  this.accountService.changePassword(this.form.value).subscribe(data => {
    this.notify.showMessage("Password changed successfully!", 3000);
  }, (error) => {
    this.notify.showMessage("Something went wrong!", 3000);
  }, () => {
    this.form.controls['emailAddress'].setValue('');
    this.form.controls['newPassword'].setValue('');
    this.form.controls['oldPassword'].setValue('');
    this.dialogRef.close();
  });
}

cancel(): void {
this.dialogRef.close();
}

}
