import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/@core/services/app.service';
import { API_BOOK } from 'src/@core/services/util/api.endpoints';
import { environment } from 'src/environments/environment';
import { EmployementPaymentOrder } from 'src/@core/interfaces/employment/employement-payment-order.model';
import { EmployeerPaymentOrderService } from 'src/@core/services/employment/employment-payment-order.service';
import { NotificationService } from 'src/@core/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';

declare var Razorpay: any;


@Component({
  selector: 'hour4u-admin-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent implements OnInit {

  bookings: any[] = [];
  profile = this.appService.getCurrentAccount();
  resultsLength: number = 0;
  pageIndex: number = 0;
  pageSize: number = 20;
  pageSizeOptions: number[] = [20, 30, 50];

  constructor(
    private http: HttpClient,
    private appService: AppService,
    private employeerPaymentOrderService: EmployeerPaymentOrderService,
    private notify: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.fetchBookings();
  }

  fetchBookings(): void {
    const createdBy = this.profile.user_details.email;
    const sort = 'createdOn,desc';
    const pageIndex = this.pageIndex.toString();

    const httpParams = new HttpParams()
      .set('createdBy', createdBy)
      .set('sort', sort)
      .set('page', pageIndex);

    this.http.get<any>(API_BOOK, { params: httpParams }).subscribe(
      (response) => {
        if (response && Array.isArray(response.content)) {
          this.bookings = response.content;
          this.resultsLength = response.totalElements;
          console.log(response);
        }
      },
      (error) => {
        console.error('Failed to fetch bookings:', error);
      }
    );
  }
  onPaginateChange(event: any) {
    // Update the page index when the user changes the page
    this.pageIndex = event.pageIndex;

    // Update the URL with the new page number using query parameters
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: this.pageIndex },
      queryParamsHandling: 'merge' // Preserve existing query parameters
    });
  }

  payNow(booking: any) {
    const razorPayOptions = {
      amount: booking.totalAmount * 100, // Multiplying by 100 to convert to paisa
      currency: 'INR',
      key: environment.rezorpayKey,
      order_id: booking.razorPayOrderId,
      invoice_id: booking.razorPayInvoiceId,
      "handler": (paymentResponse: any) => {
        this.notify.showMessage("Payment success and Booking saved. Redirecting to my bookings", 2000);
        this.fetchBookings();
        // Perform any necessary action upon successful payment here

      },
      "modal": {
        "ondismiss": () => {
          console.log('Payment window dismissed');
          // Handle dismiss scenarios if required
        }
      }
    };

    // Initiate Razorpay payment with the provided options
    const rzp = new Razorpay(razorPayOptions);
    rzp.open();

    rzp.on('payment.failed', function (response: any) {
      // Handle payment failure if required
      console.error('Payment failed:', response);
    });
  }



}
