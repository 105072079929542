import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'hour4u-admin-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingComponent implements OnInit {

  @Input() rating: number;
  active = [];
  inActive = [];
  constructor(public cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.rating = Math.round(this.rating)
    if (this.rating) {
      this.active = Array(this.rating).map((x, i) => i);
      this.inActive = Array(5 - this.rating).map((x, i) => i);
    } else {
      this.inActive = Array(5).map((x, i) => i);
    }
  }
}
