import {
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
  HttpInterceptor
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { ErrorDialogServiceService } from './ErrorDialogService.service';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor{
  constructor(
    private errorDialogService: ErrorDialogServiceService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        
        // show dialog for error message
        this.errorDialogService.openDialog(
          error.error.error_description != null ? error.error.error_description:  error.error.message, 
          error.error.subErrors != null ? error.error.subErrors: null);
        return throwError(error);
      }),
      finalize(() => {
        // hide loading spinner
      })
    ) as Observable<HttpEvent<any>>;
  }

}
