import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'hour4u-admin-help-videos',
  templateUrl: './help-videos.component.html',
  styleUrls: ['./help-videos.component.scss']
})
export class HelpVideosComponent implements OnInit {
  videos: { url: string }[] = [];


  constructor(private router: Router) {
        // Assign the video URL to the videos array
        this.videos = [
          { url: 'https://uat.hour4u.com/video.mp4' },
        ];
  }
  ngOnInit() {
  }

}
