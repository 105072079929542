import {BaseService} from '../base.service';
import {HttpClient} from '@angular/common/http';
import {API_EMPLOYMENT_ADMINISTRATION} from '../util/api.endpoints';
import {Injectable} from '@angular/core';
import {EmploymentAdministration} from 'src/@core/interfaces/employment/employment-administration.model';
import {Observable} from "rxjs";
import {share} from "rxjs/operators";

/**
 * Service response for fetch, create, update and delete the Employment Administration.
 */
@Injectable({
  providedIn: 'root'
})

export class EmploymentAdministrationService extends BaseService<EmploymentAdministration> {
  constructor(private http: HttpClient) {
    super(http, API_EMPLOYMENT_ADMINISTRATION);
  }

  getByEmploymentId(employmentId: any): Observable<EmploymentAdministration> {
    const url = API_EMPLOYMENT_ADMINISTRATION + '/employmentId/' + employmentId;
    return this.http.get<EmploymentAdministration>(url).pipe(share());
  }

}
