import { Pipe, PipeTransform } from '@angular/core';
import { STATUS } from 'src/app/shared/helpers/status';

@Pipe({
  name: 'getStatus'
})
export class StatusPipe implements PipeTransform {
  transform(val: any | null): any {
    if (!val) {
      return '';
    }
    switch (val) {
      case STATUS.PENDING:
        return "<div @scaleIn  class='status-btn-card px-1 py-1 rounded bg-red-300 text-red-700 text-bold flex items-center justify-center mat-button br-32'>Pending</div>"
      case STATUS.ACTIVE:
        return "<div @scaleIn  class='status-btn-card px-4 py-1 rounded bg-primary-300 text-primary-700 flex items-center justify-center mat-button br-32'>Approved</div>"
      case STATUS.INACTIVE:
        return "<div @scaleIn  class='status-btn-card px-4 py-1 rounded bg-red-50 text-red-500 flex items-center justify-center mat-button'>Inactive</div>"
      case STATUS.INPROGRESS:
        return "<div @scaleIn  class='status-btn-card px-4 py-1 rounded bg-primary-50 text-primary-500 flex items-center justify-center mat-button'>On going</div>"
      case STATUS.COMPLETED:
        return "<div @scaleIn  class='status-btn-card px-4 py-1 rounded bg-green-300 text-green-700 flex items-center justify-center mat-button br-32 text-bold'>Completed</div>"
      case STATUS.REJECTED:
        return "<div @scaleIn  class='status-btn-card px-4 py-1 rounded bg-red-50 text-red-500 flex items-center justify-center mat-button'>Rejected</div>"
      case STATUS.DRAFT:
        return "<div @scaleIn  class='status-btn-card px-4 py-1 rounded bg-orange-300 text-orange-700 flex items-center justify-center mat-button br-32'>Draft</div>"
      case STATUS.CANCELLED:
        return "<div @scaleIn  class='status-btn-card px-4 py-1 rounded bg-orange-300 text-orange-700 flex items-center justify-center mat-button br-32'>Cancelled</div>"
      default:
        return ''
    }
  }

}
