import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Employment } from 'src/@core/interfaces/employment/employment.model';
import { Search } from 'src/@core/interfaces/search';
import { JobApplication } from 'src/@core/interfaces/job/job-application.model';
import { roles } from 'src/app/shared/helpers/roles';
import { environment } from 'src/environments/environment';
import { Attendance } from 'src/@core/interfaces/job/attendance.model';

@Component({
  selector: 'hour4u-admin-view-detail-tasks',
  templateUrl: './view-detail-tasks.component.html',
  styleUrls: ['./view-detail-tasks.component.scss']
})
export class ViewDetailTasksComponent implements OnInit {

  dataChange: BehaviorSubject<any>;
  @Input('jobApplication') jobApplication: any;
  @Input('jobseeker') jobseeker: any;
  showProgress = true;
  roles = roles;
  baseImageUrl = environment.image_url;


  constructor(
  ) { }

  ngOnInit() {
    console.log("jobApplication======", this.jobApplication)
  }

  isEven(index) {
    if (index % 2 === 0) {
      return false;
    } else {
      return true;
    }
  }
}
