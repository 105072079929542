import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmployerService } from 'src/@core/services/employer/employer.service';
import { AppService } from 'src/@core/services/app.service';


@Component({
  selector: 'hour4u-admin-update-employer-details',
  templateUrl: './update-employer-details.component.html',
  styleUrls: ['./update-employer-details.component.scss']
})
export class UpdateEmployerDetailsComponent implements OnInit {
  empForm: FormGroup;
  employerId: string;
  profile = this.appService.getCurrentAccount();
  @Output() employerUpdated = new EventEmitter<any>();


  constructor(private appService: AppService,
    private dialogRef: MatDialogRef<UpdateEmployerDetailsComponent>,
    private fb: FormBuilder,
    private employerService: EmployerService
  ) { }

  ngOnInit(): void {
    // Initialize the form with form controls
    this.empForm = this.fb.group({
      compName: ['', Validators.required],
      compEmail: ['', Validators.email],
      compContactNumber: [''],
      compGST: [''],
      compAddr: ['']
    });

    // Fetch employer details using the service
    //this.employerId = 'your-employer-id'; // Update with your employer ID
    this.fetchEmployerDetails(this.profile.user_details.employerId);
  }

  fetchEmployerDetails(id: string): void {
    this.employerId = id;

    this.employerService.forceGetEmp(id).subscribe((employerData: any) => {
      this.empForm.patchValue({
        compName: employerData.name,
        compEmail: employerData.email,
        compContactNumber: employerData.contactNo,
        compGST: employerData.gstNumber,
        compAddr: employerData.address
      });
    });
  }


  // Inside the popup component
  updateEmployer(): void {
    if (this.empForm.valid) {
      const updatedData = {
        // Retrieve the updated values from the form
        name: this.empForm.get('compName').value,
        email: this.empForm.get('compEmail').value,
        contactNo: this.empForm.get('compContactNumber').value,
        gstNumber: this.empForm.get('compGST').value,
        address: this.empForm.get('compAddr').value
        // Add other fields if needed
      };

      // Call the service to update the employer data
      this.employerService.updateEmployer(this.employerId, updatedData).subscribe((response: any) => {
        // Handle the response or perform any necessary actions upon successful update
        console.log('Employer updated successfully!', response);

        // Update the local storage with the new employer details
        localStorage.setItem('CURRENT_EMPLOYER', JSON.stringify(response));

        // Emit an event to notify the parent component about the employer update
        this.employerUpdated.emit(response);

        // Close the dialog or perform other actions
        this.closeDialog();
      }, (error: any) => {
        // Handle error cases
        console.error('Error updating employer', error);
        // Perform error handling actions as needed
      });
    } else {
      // Handle invalid form state, if needed
    }
  }


  closeDialog(): void {
    this.dialogRef.close();
  }

}
