import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { ConfigService } from '../@core/services/theme/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@core/services/theme/navigation.service';
import { LayoutService } from '../@core/services/theme/layout.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@core/services/theme/splash-screen.service';
import { Style, StyleService } from '../@core/services/theme/style.service';
import { ConfigName } from '../@core/interfaces/theme/config-name.model';
import { roles } from './shared/helpers/roles';
import { CheckoutComponent } from './modules/employment/employment/checkout/checkout.component';
import { MatDialog } from '@angular/material';
import { InvoicePayComponent } from './modules/employment/invoice-pay/invoice-pay.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'hour4u admin';
  message;

  constructor(private configService: ConfigService,
    private styleService: StyleService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private dialog: MatDialog
  ) {
    router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          // Perform actions
          console.log("++++++++++++++++++", event)
        }
      });
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    showConfigButton: false,
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl')))
    ).subscribe(queryParamMap => {
      this.document.body.dir = 'rtl';
      this.configService.updateConfig({
        rtl: true
      });
    });

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('layout'))
    ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.styleService.setStyle(queryParamMap.get('style') as Style));


    this.navigationService.items = [
      {
        type: 'link',
        label: 'Dashboard',
        route: '/dashboard',
        icon: 'icon-chart-pie',
        roles: [roles.SUPER_ADMIN, roles.JOB_ADMIN, roles.EMPLOYER_ADMIN, roles.EMPLOYER_STAFF, roles.ON_BOARDING_STAFF]
      },
      {
        type: 'link',
        label: 'Champs',
        route: '/job-seeker',
        icon: 'icon-account',
        roles: [roles.SUPER_ADMIN, roles.ON_BOARDING_STAFF, roles.EMPLOYER_ADMIN, roles.EMPLOYER_STAFF]
      },
      {
        type: 'link',
        label: 'Champs',
        route: '/supervisor-admin/champs/all',
        icon: 'icon-briefcase',
        roles: [roles.JOB_SUPERVISOR, roles.JOB_ADMIN]
      },
      {
        type: 'link',
        label: 'Job Supervisors',
        route: '/supervisor-admin/supervisor-admin-list',
        icon: 'icon-account',
        roles: [roles.JOB_ADMIN]
      },
      {
        type: 'link',
        label: 'Shifts',
        route: '/employment/all/all',
        icon: 'icon-briefcase',
        roles: [roles.SUPER_ADMIN, roles.EMPLOYER_ADMIN, roles.EMPLOYER_STAFF, roles.ON_BOARDING_STAFF]
      },
      {
        type: 'link',
        label: 'Shifts',
        route: '/job-admin/job-admin/all',
        icon: 'icon-briefcase',
        roles: [roles.JOB_ADMIN, roles.JOB_SUPERVISOR]
      },
      // {
      //   type: 'link',
      //   label: 'Shifts',
      //   route: '/job-admin/job-admin-list/all',
      //   icon: 'icon-briefcase',
      //   roles: [roles.JOB_ADMIN, roles.JOB_SUPERVISOR]
      // },
      {
        type: 'link',
        label: 'Attendances',
        route: '/attendance-tracker',
        icon: 'icon-briefcase',
        roles: [roles.SUPER_ADMIN, roles.ON_BOARDING_STAFF]
      },
      {
        type: 'link',
        label: 'Job templates',
        route: '/job',
        icon: 'icon-briefcase',
        roles: [roles.SUPER_ADMIN, roles.EMPLOYER_ADMIN, roles.EMPLOYER_STAFF, roles.ON_BOARDING_STAFF]
      },
      {
        type: 'link',
        label: 'Order & Invoices',
        route: '/order-and-invoices',
        icon: 'icon-note',
        roles: [roles.EMPLOYER_ADMIN]
      },
      {
        type: 'link',
        label: 'Invoices',
        route: '/invoices',
        icon: 'icon-note',
        roles: [roles.SUPER_ADMIN, roles.EMPLOYER_ADMIN, roles.EMPLOYER_STAFF, roles.ON_BOARDING_STAFF]
      },
      {
        type: 'link',
        label: 'Help Videos',
        route: '/help-videos',
        icon: 'icon-camera',
        roles: [roles.SUPER_ADMIN, roles.JOB_ADMIN, roles.EMPLOYER_ADMIN, roles.EMPLOYER_STAFF, roles.ON_BOARDING_STAFF]
      },
      {
        type: 'link',
        label: 'Societies',
        route: '/societies',
        icon: 'icon-home',
        roles: [roles.SUPER_ADMIN, roles.PERMISSION_USER, roles.EMPLOYER_ADMIN, roles.EMPLOYER_STAFF, roles.ON_BOARDING_STAFF]
      },
      {
        type: 'link',
        label: 'Bookings',
        route: '/societies/bookings',
        icon: 'icon-home',
        roles: [roles.EMPLOYER_ADMIN, roles.EMPLOYER_STAFF]
      },
      {
        type: 'link',
        label: 'Permissions',
        route: '/available-permissions',
        icon: 'icon-home',
        roles: [roles.SUPER_ADMIN]
      },
      // {
      //   type: 'link',
      //   label: 'Champs',
      //   route: '/supervisor-admin/champs/all',
      //   icon: 'icon-briefcase',
      //   roles: [roles.JOB_SUPERVISOR]
      // },
      {
        type: 'link',
        label: 'Commissions',
        route: '/supervisor-admin/commissions',
        icon: 'icon-briefcase',
        roles: [roles.JOB_SUPERVISOR]
      },
      {
        type: 'link',
        label: 'Payments',
        route: '/payment-tracker/all',
        icon: 'icon-briefcase',
        roles: [roles.SUPER_ADMIN]
      },
      {
        type: 'dropdown',
        label: 'Users',
        icon: 'icon-account',
        roles: [roles.SUPER_ADMIN, roles.ON_BOARDING_STAFF],
        children: [
          {
            type: 'link',
            label: 'Employers',
            route: '/employer',
            icon: 'icon-account',
            roles: [roles.SUPER_ADMIN, roles.ON_BOARDING_STAFF]
          },
          {
            type: 'link',
            label: 'Job Supervisors',
            route: '/supervisor-admin/supervisor-admin-list',
            icon: 'icon-account',
            roles: [roles.SUPER_ADMIN, roles.ON_BOARDING_STAFF]
          },
          {
            type: 'link',
            label: 'Job Admin',
            route: '/job-admin/job-admin-list',
            icon: 'icon-account',
            roles: [roles.SUPER_ADMIN, roles.ON_BOARDING_STAFF]
          },
        ]
      },
      {
        type: 'dropdown',
        label: 'Settings',
        icon: 'icon-cog',
        roles: [roles.SUPER_ADMIN],
        children: [
          {
            type: 'link',
            label: 'Job Categories',
            route: '/admin/job-category',
            roles: [roles.SUPER_ADMIN]
          },
          {
            type: 'link',
            label: 'Job Type',
            route: '/admin/job-type',
            roles: [roles.SUPER_ADMIN]
          },
          {
            type: 'link',
            label: 'Admin Users',
            route: '/admin/users',
            roles: [roles.SUPER_ADMIN]
          },
          {
            type: 'link',
            label: 'Roles',
            route: '/admin/roles',
            roles: [roles.SUPER_ADMIN]
          }
        ]
      }
    ];
  }

  ngOnInit() {
    if (this.router.url.includes('checkout')) {
      this.route.params.subscribe(data => {
        this.copyEmployementAdvancePaymentLink(data);
      })
    }
    if (this.router.url.includes('invoice-pay')) {
      this.route.params.subscribe(data => {
        this.copyEmployementPayInvoiceLink(data);
      })
    }
  }

  // checkout modal open
  copyEmployementAdvancePaymentLink(employment: any) {
    const dialogRef = this.dialog.open(CheckoutComponent, {
      width: '700px',
      data: employment,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(
      result => {
        console.log(result)
      }
    );
  }

  // share-invoice modal open
  copyEmployementPayInvoiceLink(employment: any) {
    const dialogRef = this.dialog.open(InvoicePayComponent, {
      width: '700px',
      data: employment,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(
      result => {
        console.log(result)
      }
    );
  }

}
