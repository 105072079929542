import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_CHAMP } from 'src/@core/services/util/api.endpoints';
import { ReferralService } from 'src/@core/services/referral/referral.service';
import { AppService } from 'src/@core/services/app.service';
import { Router } from '@angular/router';


@Component({
  selector: 'hour4u-admin-add-champs',
  templateUrl: './add-champs.component.html',
  styleUrls: ['./add-champs.component.scss']
})
export class AddChampsComponent implements OnInit {
  name: string;
  phone: string;
  champs: any[] = [
    { name: '', phone: '' },
    { name: '', phone: '' },
    { name: '', phone: '' }
  ];
  profile = this.appService.getCurrentAccount().user_details;
  

  constructor(
    private dialogRef: MatDialogRef<AddChampsComponent>,
    private http: HttpClient,
    private referralService: ReferralService,
    private appService: AppService,
    private router: Router
    ) { }

  ngOnInit() {
  }

  addChamp() {
    this.champs.push({ name: '', phone: '' });
  }


  submitForm() {
    // Check if referralId is available
    if (!this.profile.referralId) {
      // Create a referral for supervisorAdmin
      const referralData = {
        supervisorId: this.profile.id,
        employerName: this.profile.name
      };
      this.referralService.createReferral(referralData).subscribe(
        (response) => {
          const referralId = response.id;
          this.processChamps(referralId);
        },
        (error) => {
          console.error('Error creating referral:', error);
          this.dialogRef.close();
        }
      );
    } else {
      // ReferralId is available, process the champs
      this.processChamps(this.profile.referralId);
      this.refreshPage();
    }
  }
  refreshPage() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/supervisor-admin/champs']);
      console.log("refresh successful")
    });
  }
  
  processChamps(referralId: string) {
    this.champs.forEach((champ, index) => {
      // Check if both name and phone are non-blank
      if (champ.name.trim() !== '' || champ.phone.trim() !== '') {
        // console.log(`Champ ${index + 1} - Name: ${champ.name}, Phone Number: ${champ.phone}`);
        // Create a Champ using the ReferralService
        const champData = {
          jobSeekerId: '', // Provide the job seeker ID
          name: champ.name,
          phone: champ.phone,
          referralId: referralId,
        };
        this.referralService.createChamp(champData).subscribe(
          (response) => {
            // console.log(`Champ ${index + 1} created successfully:`, response);
          },
          (error) => {
            console.error(`Error creating Champ ${index + 1}:`, error);
          }
        );
      }
    });
  
    // Close the dialog and pass any result if needed
    this.dialogRef.close(/* result */);
  }
  
  
  
  closeForm() {
    this.dialogRef.close();
  }
}
