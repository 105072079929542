import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JobSeekerService } from '../../../../@core/services/job-seeker/job-seeker.service';
import { JobSeekerPreferenceService } from '../../../../@core/services/job-seeker/job-seeker-preference.service';
import { JobSeekerPreference } from '../../../../@core/interfaces/job-seeker/job-seeker-preference.model';
import { Search } from '../../../../@core/interfaces/search';
import { JobSeeker } from '../../../../@core/interfaces/job-seeker/job-seeker.model';
import { JobTypeStatusUpdate } from '../update-status.model';
import { NotificationService } from 'src/@core/services/notification.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

@Component({
  selector: 'hour4u-admin-profile-approval-dialog',
  templateUrl: './profile-approval-dialog.component.html',
  styleUrls: ['./profile-approval-dialog.component.scss']
})
export class ProfileApprovalDialogComponent implements OnInit {

  preference: JobSeekerPreference = new JobSeekerPreference();
  jobSeeker: JobSeeker = new JobSeeker();
  params: Search[] = [];
  model: JobTypeStatusUpdate;
  form: FormGroup;
  isSubmitted: boolean = false;

  constructor(public dialogRef: MatDialogRef<ProfileApprovalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private jobSeekerService: JobSeekerService,
    private _fb: FormBuilder,
    private notify: NotificationService //,    private jobSeekerPreferenceService: JobSeekerPreferenceService
  ) {
    this.form = this._fb.group({
      currentStatus: ['Active', Validators.required],
      notes: [''],
      jobTypeStatusRequests: this._fb.array([])
    })
  }

  ngOnInit() {
    //this.params.push(new Search('jobSeekerId', this.data.id));

    //this.jobSeekerPreferenceService.searchAndSort(0, 1, null, null, this.params, this.data.id).subscribe(data => {
    this.preference = this.data.preference;
    this.jobSeeker = this.data.jobSeeker;
    //if (this.preference) {
    //this.getStatusRequestArray().clear();

    // create new form as per the type pref list
    this.preference.jobTypePreferences.forEach((a, i) => {
      this.getStatusRequestArray().push(
        this._fb.group({
          level: ['Beginner', Validators.required],
          status: ['Active', Validators.required],
          typeId: [a.typeId, Validators.required],
          typeName: [a.typeName, Validators.required]
        })
      );
    });
    //}
    //});

    //this.createApprovalForm();
  }

  getStatusRequestArray() {
    return this.form.get('jobTypeStatusRequests') as FormArray;
  }

  // createApprovalForm() {
  //   this.form = this._fb.group({
  //     currentStatus: ['Active', Validators.required],
  //     notes: ['', Validators.required],
  //     jobTypeStatusRequests: this._fb.array([this.createJobTypeStatusForm()])
  //   })
  // }

  // createJobTypeStatusForm() {
  //   return this._fb.group({
  //     level: ['', Validators.required],
  //     status: ['Active', Validators.required],
  //     typeId: ['', Validators.required],
  //     typeName: ['', Validators.required]
  //   })
  // }

  selectionChanged(event, i) {
    if (event.value == 'Unqualified') {
      this.getStatusRequestArray().controls[i].value.status = 'Unqualified';
      this.getStatusRequestArray().controls[i].value.level = 'Beginner';
    }
    else
      this.getStatusRequestArray().controls[i].value.status = 'Active';

  }

  onCancel() {
    this.dialogRef.close();
  }

  onApprove() {
    this.isSubmitted = true;
    this.form.markAllAsTouched();
    if (!this.preference.jobSeekerId) {
      this.isSubmitted = false;
      return;
    }
    if (this.form.invalid) {
      this.isSubmitted = false;
      return;
    }

    for (let i = 0; i < this.preference.jobTypePreferences.length; i++) {
      if (this.form.value.jobTypeStatusRequests[i].level == 'Unqualified') {
        this.form.value.jobTypeStatusRequests[i].status = 'Unqualified';
        this.form.value.jobTypeStatusRequests[i].level = 'Beginner';
      }
    }

    this.jobSeekerService.updateProfileStatus(this.preference.jobSeekerId, this.form.value).subscribe(data => {
      this.notify.showMessage("Status Updated successfully!", 2000);
      this.isSubmitted = false;
    }, (error) => {
      this.isSubmitted = false;
      this.notify.showMessage("Error, Could not update status", 2000);
    }, () => {
      this.isSubmitted = false;
      this.dialogRef.close(true);
    });
  }

}
