import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'hour4u-admin-society-filter-dialog',
  templateUrl: './society-filter-dialog.component.html',
  styleUrls: ['./society-filter-dialog.component.scss']
})
export class SocietyFilterDialogComponent implements OnInit {
  city: string = '';
  numberOfFlats: number | null = null;


  constructor(public dialogRef: MatDialogRef<SocietyFilterDialogComponent>) { }

  ngOnInit() { }

  onApplyFilter() {
    // Pass both selected city and flat count as the result when applying the filter
    this.dialogRef.close({ city: this.city.trim(), numberOfFlats: this.numberOfFlats });
  }

  onRemoveFilter() {
    // Clear filters
    this.city = '';
    this.numberOfFlats = null;
    this.dialogRef.close({});
  }
}
