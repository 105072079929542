import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { JobType } from 'src/@core/interfaces/job/job-type.model';
import { JobService } from 'src/@core/services/job/job.service';
import { Location } from '@angular/common';
import { CURRENT_EMPLOYER, CURRENT_USER } from 'src/@core/services/app.service';
import { Router } from '@angular/router';
import { EmployementPaymentOrder } from 'src/@core/interfaces/employment/employement-payment-order.model';
import { EmployeerPaymentOrderService } from 'src/@core/services/employment/employment-payment-order.service';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/@core/services/notification.service';

declare var Razorpay: any;

@Component({
  selector: 'hour4u-admin-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  jobType: JobType;
  employerDetails: any = null;
  isLogin: boolean = false;
  showExpand: boolean = false;
  loginEmployerDetails: any = null;
  showNoData: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CheckoutComponent>,
    @Inject(MAT_DIALOG_DATA) public params: any,
    private jobService: JobService,
    public location: Location,
    public router: Router,
    private employeerPaymentOrderService: EmployeerPaymentOrderService,
    private notify: NotificationService,
  ) { }

  ngOnInit() {
    console.log(this.params, JSON.parse(localStorage.getItem(CURRENT_USER)))
    this.isLogin = JSON.parse(localStorage.getItem(CURRENT_USER)) ? true : false;
    if (this.params) {
      this.getEmployementDetails();
    }
    this.loginEmployerDetails = JSON.parse(localStorage.getItem(CURRENT_EMPLOYER));
  }

  async getEmployementDetails() {
    console.log(this.params.id)
    await this.jobService.getEmployementDetails(this.params.id).toPromise()
      .then(async job => {
        console.log(job)
        this.showNoData = false;
        this.employerDetails = job;
        if (!this.loginEmployerDetails) {
          this.loginEmployerDetails =
          {
            advanceAmountPercentage: this.employerDetails.advanceAmountPercent
          }
        }
      }).catch(err => {
        this.showNoData = true;
        this.dialogRef.close();
        console.log(err)
      });
  }

  async payCheckoutAmount() {
    if (this.employerDetails && this.employerDetails.employment.paidAmount >= 0) {
      let paymentOrderRequest = new EmployementPaymentOrder();
      //% of total amount 
      paymentOrderRequest.amount = (this.employerDetails.femaleTotalAmount + this.employerDetails.maleTotalAmount) * (this.loginEmployerDetails.advanceAmountPercentage / 100);
      paymentOrderRequest.employer_id = this.employerDetails.employment.employerId;
      paymentOrderRequest.employment_id = this.employerDetails.employment.id;
      console.log(paymentOrderRequest)
      this.employeerPaymentOrderService.create(paymentOrderRequest).toPromise().then(
        order => {
          if (order != null && order.status == 'SUCCESS') {
            this.razorPayOptions.amount = order.result.amount * 100;
            this.razorPayOptions.currency = 'INR';
            this.razorPayOptions.key = environment.rezorpayKey;
            this.razorPayOptions.order_id = order.result.razorPayOrderId;
            var rzp = new Razorpay(this.razorPayOptions);
            rzp.open()
            rzp.on('payment.failed', function (response) {
              alert(response.error.code);
              alert(response.error.description);
              alert(response.error.source);
              alert(response.error.step);
              alert(response.error.reason);
              alert(response.error.metadata.order_id);
              alert(response.error.metadata.payment_id);
            });
          }
        }
      );
    } else {
      this.notify.showMessage("Advance amount already paid!", 2000);
    }
  }

  razorPayOptions = {
    "key": '',
    "amount": 0,
    "currency": '',
    "name": '',
    "discription": "hours4u payment",
    "order_id": '',
    "handler": (response) => {
      let paymentOrderRequest = new EmployementPaymentOrder();
      paymentOrderRequest.razorpay_order_id = response.razorpay_order_id;

      paymentOrderRequest.razorpay_payment_id = response.razorpay_payment_id;

      paymentOrderRequest.razorpay_signature = response.razorpay_signature;
      this.employeerPaymentOrderService.update(response.razorpay_order_id, paymentOrderRequest).toPromise().then(res => {
        // this.employerDetails.employment.status = 'Active'
        // this.employmentService.update(this.employerDetails.employment.id, this.employerDetails.employment).toPromise().then(emp => {
        this.notify.showMessage("Payment success and employment saved", 2000);
        this.closePopup();
        // })
      })
    },
    "modal": {
      "ondismiss": () => {
        this.closePopup();
      }
    }
  };

  closePopup() {
    this.dialogRef.close();
    // this.location.back();
    if (this.isLogin) {
      this.router.navigateByUrl('employment/all/all');
    } else {
      this.router.navigateByUrl('/');
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  }

  // goToLogin() {
  //   this.dialogRef.close();
  //   this.router.navigateByUrl('/');
  // }

  chatWithSupport() {
    window.open('https://api.whatsapp.com/send?phone=' + environment.contactSuppportNumber, "_blank");
  }
}
