import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_CHAMP, API_REFERRAL, API_COMMISSION } from '../util/api.endpoints';

@Injectable({
  providedIn: 'root'
})
export class ReferralService {
  constructor(private http: HttpClient) { }

  public createChamp(champData: any): Observable<any> {
    return this.http.post<any>(API_CHAMP, champData);
  }
  public getChampById(id: string): Observable<any> {
    const url = API_CHAMP + `/${id}`;
    return this.http.get<any>(url);
  }  
  createReferral(referralData: any): Observable<any> {
    return this.http.post(API_REFERRAL, referralData);
  }
  getAllCommissions(params: any): Observable<any> {
    return this.http.get<any>(API_COMMISSION, { params });
  }
}
