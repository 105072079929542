import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { share } from 'rxjs/operators';
import { Search } from '../interfaces/search';
import { PageableResponse } from '../interfaces/pageable-response';
import { Cacheable, CacheBuster } from 'ngx-cacheable';

/* cache buster Subject */
const cacheBuster$ = new Subject();

// @Injectable({ providedIn: 'root' })
export class BaseService<T> {
  private _http: HttpClient;
  private _url: string;

  constructor(http: HttpClient, url: string) {
    this._url = url;
    this._http = http;

    cacheBuster$.subscribe(() => {
      console.log('base.service cacheBuster$ just emitted');
    });
  }

  // @Cacheable({
  //    maxCacheCount: 50,
  //   cacheBusterObserver: cacheBuster$.asObservable() 
  // })
  searchAndSort(page: number, size: number, sort: string, order: string, searchList?: Search[], cacheKey?: any): Observable<PageableResponse<T>> {
    let params = new HttpParams();
    let url = this._url + '?page=' + page;
    if (size != null) {
      url += '&size=' + size;
    }

    if (order !== 'desc') {
      order = 'asc';
    }
    if (sort != null) {
      url += ('&sort=' + sort + ',' + order);
    } else {
      url += ('&sort=createdOn,desc');
    }

    if (searchList && searchList.length > 0) {
      _.forEach(searchList, (search) => {
        if (search.column && search.content) {
          params = params.append(search.column, search.content);
        }
      });
    }
    return this._http.get<PageableResponse<T>>(url, { params: params });
  }

  @Cacheable({
    maxCacheCount: 50,
    cacheBusterObserver: cacheBuster$.asObservable()
  })
  count(searchList?: Search[], cacheKey?: any): Observable<number> {
    let params = new HttpParams();
    const url = this._url + '/count';
    if (searchList && searchList.length > 0) {
      const url = this._url + '/count?';
      _.forEach(searchList, (search: Search) => {
        if (search.column && search.content) {
          params = params.append(search.column, search.content);
        }
      });
    }
    return this._http.get<number>(url, { params: params }).pipe(share());
  }

  @Cacheable({
    maxCacheCount: 1,
    cacheBusterObserver: cacheBuster$.asObservable()
  })
  getById(id: any): Observable<T> {
    const url = this._url + '/' + id;
    return this._http.get<T>(url).pipe(share());
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBuster$
  })
  delete(id: any): Observable<any> {
    const url = this._url + '/' + id;
    return this._http.delete<any>(url);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBuster$
  })
  update(id: any, data: T, cacheKey?: any): Observable<T> {
    const url = this._url + '/' + id;
    return this._http.put<T>(url, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBuster$
  })

  create(data: T, cacheKey?: any): Observable<T> {
    const url = this._url;
    return this._http.post<T>(url, data);
  }


  bustCache() {
    cacheBuster$.next();
  }

}
