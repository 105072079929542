import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_SEEKER_PREFERENCE} from '../util/api.endpoints';
import {BaseService} from '../base.service';
import {JobSeekerPreference} from '../../interfaces/job-seeker/job-seeker-preference.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobSeekerPreferenceService extends BaseService<JobSeekerPreference> {
  constructor(private http: HttpClient) {
    super(http, API_SEEKER_PREFERENCE);
  }

  getChampJobTypeStatus(jobSeekerId:string, typeId:string): Observable<any> {
    const url = API_SEEKER_PREFERENCE + `/getByJobSeekerIdAndTypeId/${jobSeekerId}/${typeId}`;
    return this.http.get<string>(url, { responseType: 'string' as 'json'});
  }
}
