import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { JobSeekerRatingService } from 'src/@core/services/rating/job-seeker-rating.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatCheckboxChange } from '@angular/material';
import { JobSeekerService } from 'src/@core/services/job-seeker/job-seeker.service';
import { JobSeeker } from 'src/@core/interfaces/job-seeker/job-seeker.model';
import { NotificationService } from 'src/@core/services/notification.service';

@Component({
  selector: 'hour4u-admin-give-rating-dialog',
  templateUrl: './give-rating-dialog.component.html',
  styleUrls: ['./give-rating-dialog.component.scss']
})
export class GiveRatingDialogComponent implements OnInit {

  starList: boolean[] = [true,true,true,true,true];       // create a list which contains status of 5 stars
  rate:number;  
  jobSeeker: JobSeeker;
  level = ''
  form: FormGroup;
  reviewTypes: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<GiveRatingDialogComponent>,
    private _fb: FormBuilder,
    private service: JobSeekerRatingService,
    private jobSeekerService: JobSeekerService,
    private notify: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.loadJobSeekerData()
    this.createForm();
  }

  loadJobSeekerData() {
    this.jobSeekerService.getById(this.data.jobApplication.jobSeekerId).subscribe(data => {
      this.jobSeeker = data;
    })
  }

  /**
   * Creating for using form builder for Job
   */
  createForm() {
    this.form = this._fb.group({
      rating: [''],
      jobSeekerId: ['', Validators.required],
      employerId: ['', Validators.required],
      employmentId: ['', Validators.required],
      review: [''],
      reviewTypes: [[]],
    });
  }

  setStar(data:any){
    this.rate=data+1;                               
    for(var i=0;i<=4;i++){  
      if(i<=data){  
        this.starList[i]=false;  
      }  
      else{  
        this.starList[i]=true;  
      }  
   }  

   if(data > -1 && data < 2) this.level = 'Fair' 
   else if(data > 1 && data < 4) this.level = 'Good' 
   else this.level = 'Excellent' 
   this.form.controls.rating.setValue(data + 1); 

} 

  onSubmit() {

    if(!this.data.employer.id 
      || !this.data.jobApplication.employmentId
      || !this.data.jobApplication.jobSeekerId) return;

    let data = this.form.value;
    data.employerId = this.data.employer.id
    data.employmentId = this.data.jobApplication.employmentId
    data.jobSeekerId = this.data.jobApplication.jobSeekerId
    data.reviewTypes = this.reviewTypes;


    // this.form.controls.employerId = this.data.employer.id
    // this.form.controls.employmentId = this.data.jobApplication.employmentId
    // this.form.controls.jobSeekerId = this.data.jobApplication.jobSeekerId
    // this.form.controls.reviewTypes = this.reviewTypes;
    

    this.service.create(data).subscribe(data => {
      this.notify.showMessage("Rating added successfully !", 2000);
    }, (error) => {
      this.notify.showMessage("Failed to add rating !", 2000);
    }, () => {
      this.dialogRef.close();
    })

  }

  close() {
    this.dialogRef.close();
  }


  reviewTypeChang(event: MatCheckboxChange, type: string) {
    if(event.checked) {
      // add into array
      this.reviewTypes.push(type);
    } else {
      // remove value from array
      this.reviewTypes = this.reviewTypes.splice(this.reviewTypes.indexOf('type'), 1);
    }
  }
}
