

import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/@core/services/notification.service';
import { BaseService } from 'src/@core/services/base.service';

@Component({
  selector: 'codemiro-base-dialog-curd',
  template: ''
})
export class BaseDialogCurdComponent<T, U> {

  resourcesLoaded: boolean = false;
  isDisabled = false;
  callingApi = false;
  form: FormGroup;
  submitted = false;


  dialogRef: any;
  data: any;
  service: any;
  notify: NotificationService;
  constructor(dialogRef: MatDialogRef<U>,
    @Inject(MAT_DIALOG_DATA) data: any,
    notify: NotificationService,
    service: BaseService<T>) {
    this.dialogRef = dialogRef;
    this.data = data;
    this.notify = notify;
    this.service = service;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  get f() { return this.form.controls; }


  onSave(): void {
    if (this.form.invalid) {
      this.submitted = true;
      this.form.markAllAsTouched();
      return;
    }
    if (this.data.object.id) {
      this._update(this.form.value);
    } else {
      this._create(this.form.value);
    }
  }

  _create(t: T): void {
    this.callingApi = true;
    this.service.create(t).subscribe(
      data => {
      }, (error: HttpErrorResponse) => {
        this.notify.showMessage(error.error.message, 3000);
        if (error.error.subErrors != null && error.error.subErrors.length > 0) {
          // this.form = this.utilService._setValidationErrors(this.form, error.error);
          this.callingApi = false;
        }
      }
      , () => {
        this.callingApi = false;
        this.dialogRef.close(this.data);
        // this.notify.showMessage(tax.taxCode + ' Added !', 3000);
      }
    );
  }
  _update(t: any): void {
    // this.callingApi = true;
    this.service.update(t.id, t).subscribe(
      data => {
      }, (error: HttpErrorResponse) => {
        this.notify.showMessage(error.error.message, 3000);
        if (error.error.subErrors != null && error.error.subErrors.length > 0) {
          //this.form = this.utilService._setValidationErrors(this.form, error.error);
          // this.callingApi = false;
        }
      }
      , () => {
        // this.callingApi = false;
        this.dialogRef.close(this.data);
        // this.notify.showMessage(t.taxCode + ' Updated !', 3000);
      }
    );
  }

}

