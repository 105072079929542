import { BaseService } from '../base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_EMPLOYMENT_HISTORY, API_FETCH_WORKLOG, API_WORKLOG } from '../util/api.endpoints';
import { Injectable } from '@angular/core';
import { Search } from 'src/@core/interfaces/search';
import { Observable } from 'rxjs';
import { PageableResponse } from 'src/@core/interfaces/pageable-response';
import { Payment } from 'src/@core/interfaces/payment/payment.model';

/**
 * Service response for fetch, create, update and delete the Employment.
 */
@Injectable({
  providedIn: 'root'
})

export class WorkLogService {
  constructor(private http: HttpClient) {
  }

  async getWorkLogList(employmentId: string) {
    let url = API_FETCH_WORKLOG + `/` + employmentId;
    return this.http.get<any>(url).toPromise();
  }

  async updateWorkLog(employmentId: string, obj) {
    let url = API_FETCH_WORKLOG + `/` + employmentId;
    return this.http.put<any>(url, obj).toPromise();
  }


  async getWorklog(employmentId: string): Promise<Blob> {
    let url = API_WORKLOG + `/employment/` + employmentId;
    const file = await this.http.get<Blob>(url, { responseType: 'blob' as 'json' }).toPromise();
    return file;
  }

  async getWorklogAttPay(employmentId: string): Promise<Blob> {
    let url = API_WORKLOG + `/employmentAttPay/` + employmentId;
    return await this.http.get<Blob>(url, { responseType: 'blob' as 'json' }).toPromise();
  }

  async getProofOfWork(employmentId: string): Promise<Blob> {
    let url = API_EMPLOYMENT_HISTORY + `/exportOrdersToExcel/` + employmentId;
    return await this.http.get<Blob>(url, { responseType: 'blob' as 'json' }).toPromise();
  }

}
