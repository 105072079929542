import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IconModule } from '@visurel/iconify-angular';
import { ContainerModule } from 'src/@core/directives/container/container.module';
import { ColorFadeModule } from 'src/@core/pipes/color/color-fade.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialShared } from './material-shared.module';
import { ConfirmDeleteDialogComponent } from './components/confirm-delete-dialog/confirm-delete-dialog.component';
import { ChangePasswordDialogComponent } from './components/change-password-dialog/change-password-dialog.component';
import { ColumnVisibilityDialogComponent } from './components/column-visibility-dialog/column-visibility-dialog.component';
import { IconComponent } from './components/icon/icon.component';
import { ParseDatePipe } from 'src/@core/pipes/parseDate.pipe';
import { NoDataFoundComponent } from './components/no-data-found/no-data-found.component';
import { ShowIfRoleDirective } from './directives/show-if-role.directive';
import { SearchAddressComponent } from './components/search-address/search-address.component';
import { AgmCoreModule } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { EmploymentTypePipe } from 'src/@core/pipes/employmentType.pipe';
import { ParseTimePipe } from 'src/@core/pipes/parseTime.pipe';
import { FormatDatePipe } from 'src/@core/pipes/formatDate.pipe';
import { AddEditEmploymentComponent } from '../modules/employment/employment/add-edit-employment/add-edit-employment.component';
import { EmployerPipe } from 'src/@core/pipes/data/employer.pipe';
import { CategoryPipe } from 'src/@core/pipes/data/category.pipe';
import { JobPipe } from 'src/@core/pipes/data/job.pipe';
import { JobTypePipe } from 'src/@core/pipes/data/jobType.pipe';
import { StatusPipe } from 'src/@core/pipes/status.pipe';
import { RatingComponent } from './components/rating/rating.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgImageSliderModule } from 'ng-image-slider';
import { JobSeekerPipe } from 'src/@core/pipes/data/jobseeker.pipe';
import { MomentModule } from 'ngx-moment';
import { DescriptionPipe } from 'src/@core/pipes/description.pipe';
import { DragDirective } from './directives/dragDrop.directive';
import { JobSeekerCardComponent } from '../modules/job-seeker/job-seeker-card/job-seeker-card.component';
import { PaymentStatusPipe } from 'src/@core/pipes/paymentStatus.pipe';
import { JobAdminShiftBurgerMenuComponent } from '../modules/job-admin/shift-burger-menu/shift-burger-menu.component';
import { UpdateEmployerDetailsComponent } from '../modules/dashboard/update-employer-details/update-employer-details.component';






@NgModule({
  declarations: [
    IconComponent,
    ConfirmDeleteDialogComponent,
    ChangePasswordDialogComponent,
    ColumnVisibilityDialogComponent,
    SearchAddressComponent,
    NoDataFoundComponent,
    AddEditEmploymentComponent,
    //pipe
    ParseDatePipe,
    EmploymentTypePipe,
    ParseTimePipe,
    FormatDatePipe,
    StatusPipe,
    PaymentStatusPipe,

    //data pipe
    EmployerPipe,
    JobSeekerPipe,
    CategoryPipe,
    JobTypePipe,
    JobPipe,
    //directive
    ShowIfRoleDirective,
    DragDirective,
    RatingComponent,
    DescriptionPipe,
    JobSeekerCardComponent,
    JobAdminShiftBurgerMenuComponent,
    UpdateEmployerDetailsComponent
  ],
  imports: [
    CommonModule,
    MomentModule,
    FlexLayoutModule,
    IconModule,
    MaterialShared,
    ContainerModule,
    ColorFadeModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDdY3MNwfLuDibZ0gvm_BAqAzX43E2u0dc', // AIzaSyAYdBudSYX8d4qQ07OkW9OzjKKn4te_hTg
      libraries: ['places']
    }),
    AgmSnazzyInfoWindowModule,
    NgxSkeletonLoaderModule,
    NgImageSliderModule
  ],
  exports: [
    CommonModule,
    MomentModule,
    FlexLayoutModule,
    IconModule,
    MaterialShared,
    ContainerModule,
    ColorFadeModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    NgxSkeletonLoaderModule,
    NgImageSliderModule,

    // components
    IconComponent,
    ConfirmDeleteDialogComponent,
    ChangePasswordDialogComponent,
    ColumnVisibilityDialogComponent,
    NoDataFoundComponent,
    SearchAddressComponent,
    AddEditEmploymentComponent,
    JobSeekerCardComponent,
    //directive
    ShowIfRoleDirective,
    DragDirective,
    //pipe
    ParseDatePipe,
    EmploymentTypePipe,
    ParseTimePipe,
    FormatDatePipe,
    StatusPipe,
    PaymentStatusPipe,

    //data pipe
    EmployerPipe,
    JobSeekerPipe,
    CategoryPipe,
    JobTypePipe,
    JobPipe,
    RatingComponent,
    RatingComponent,
    DescriptionPipe,
    JobAdminShiftBurgerMenuComponent
  ],
  entryComponents: [
    ConfirmDeleteDialogComponent,
    ChangePasswordDialogComponent,
    AddEditEmploymentComponent,
    ColumnVisibilityDialogComponent,
    UpdateEmployerDetailsComponent
  ]
})

export class SharedModule { }


