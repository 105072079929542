import { Component, OnInit, ViewChild } from '@angular/core';
import { Link } from 'src/@core/interfaces/theme/link.interface';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions, MatSort, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { fadeInRight400ms } from 'src/@core/animations/fade-in-right.animation';
import { stagger40ms } from 'src/@core/animations/stagger.animation';
import { fadeInUp400ms } from 'src/@core/animations/fade-in-up.animation';
import { Payment } from 'src/@core/interfaces/payment/payment.model';
import { ActivatedRoute } from '@angular/router';
import { Search } from 'src/@core/interfaces/search';
import { BehaviorSubject, merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { PaymentService } from 'src/@core/services/payment/payment.service';
import { EmploymentService } from 'src/@core/services/employment/employment.service';
import { NotificationService } from 'src/@core/services/notification.service';
import { PaymentStatusDialogComponent } from '../payment-status-dialog/payment-status-dialog.component';
import { PaymentFilterDialogComponent } from '../payment-filter-dialog/payment-filter-dialog.component';

@Component({
  selector: 'hour4u-admin-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms,
    fadeInRight400ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    }
  ]
})

export class PaymentsComponent implements OnInit {

  term = '';
  isSearch = false;
  sortOrder = 'desc';
  links: Link[] = [
    {
      label: 'ALL ',
      route: '/payments'
    },
    {
      label: 'UNAPPROVED',
      route: '/payments/Unapproved'
    },
    {
      label: 'PAID ',
      route: '/payments/Paid'
    },
    {
      label: 'ON HOLD',
      route: '/payments/Hold'
    }
  ];

  columns = [
    // { columnDef: 'sno', visible: true , label: 'S.No.'},
    { columnDef: 'employment', visible: true, label: 'Employment' },
    // { columnDef: 'employer', visible: true , label: 'Employer'},
    { columnDef: 'job', visible: true, label: 'Job ' },
    { columnDef: 'jobSeeker', visible: true, label: 'Job Seeker' },
    { columnDef: 'amount', visible: true, label: 'Amount' },
    { columnDef: 'date', visible: true, label: 'Date' },
    { columnDef: 'status', visible: true, label: 'Status' },
    { columnDef: 'action', visible: true, label: 'Action' },
  ];
  displayedColumns = this.columns.map(x => x.columnDef);

  dataSource: MatTableDataSource<Payment> | null;
  dataChange: BehaviorSubject<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  status;
  searchParams: Search[] = [];
  resultsLength = 0;
  searchFilters: Search[] = [];
  filters: any;

  constructor(
    private route: ActivatedRoute,
    private service: PaymentService,
    private employmentService: EmploymentService,
    private notify: NotificationService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.dataChange = new BehaviorSubject(0);
    this.dataSource = new MatTableDataSource();
    this.searchParams = [];
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;

    this.route.params.subscribe(data => {
      this.status = data.status;
      if (this.status) {
        this.searchParams = [];
        if (this.status && this.status != '')
          this.searchParams.push(new Search('status', this.status));
        this.paginator.pageIndex = 0;
        this.dataChange.next(1);
      }
    })

    merge(this.sort.sortChange, this.paginator.page, this.dataChange)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.service!.searchAndSort(
            this.paginator.pageIndex, this.paginator.pageSize,
            this.sort.active, this.sort.direction, [...this.searchParams, ...this.searchFilters]);
        }),
        map(data => {
          this.resultsLength = data.totalElements;
          return data.content;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.dataSource.data = data;
      });
  }

  search(data: any) {
    this.searchParams = [];
    if (data.length > 1) {
      if (!isNaN(Number(data.toString()))) {
        this.searchParams.push(new Search('amount', data));
      } else {
        this.searchParams.push(new Search('jobTitle', data));
      }
    }

    this.reloadData();
  }

  openFilterDialog() {
    const dialogRef = this.dialog.open(PaymentFilterDialogComponent, {
      disableClose: false,
      width: '50%',
      height: '100%',
      position: { right: '0px' },
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // catch all filters
        this.filters = result;
        if (result.employmentId != null) {
          this.searchParams.push(new Search('employmentId', result.employmentId));
        }
        if (result.jobSeekerId != null) {
          this.searchParams.push(new Search('jobSeekerId', result.jobSeekerId));
        }

        this.dataChange.next(1);

      }
    });
  }



  getEmployer(employmentId: string) {

    return this.employmentService.getById(employmentId).pipe(
      map((data) => {
        return data ? data.employerId : '';
      })
    );
  }

  /**
   * Update payment status HOLD or PAID
   * @param id 
   * @param status 
   */
  async updatePaymentStatus(row, status: string, forCash?: boolean) {
    await this.service.updatePaymentStatus(row.id, status, row.settledAmount, forCash).toPromise().then(data => {
      this.notify.showMessage('Payment Status Updated Successsfully !', 2000);
      this.ngAfterViewInit();
    });
  }

  openPaymentStatusDialog(data) {

    const dialogRef = this.dialog.open(PaymentStatusDialogComponent, {

      width: '400px',
      disableClose: true,
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngAfterViewInit();
    });
  }

  sortChange(event) {
    this.sort.active = 'createdOn';
    this.sort.direction = event.value;
    this.dataChange.next(1);
  }


  reloadData() {
    this.dataChange.next(1);
  }



}



