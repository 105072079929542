import { Address } from '../address.model';

export class Employer {
    address: string;
    contactNo: string;
    createdBy: string;
    createdOn: Date;
    deleteBy: boolean;
    email: string;
    gstNumber: string;
    password: string;
    employerSince: Date;
    id: string;
    isDelete: boolean;
    name: string;
    profilePic: string;
    updatedBy: string;
    updatedOn: Date;
    averageRating: number;
    accountId?: any;
}
