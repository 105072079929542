import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpVideosComponent } from './help-videos.component';
import { Routes } from '@angular/router';

const routes: Routes = [
  { path: '', component: HelpVideosComponent },
]

@NgModule({
  declarations: [HelpVideosComponent],
  imports: [
    CommonModule
  ]
})
export class HelpVideosModule { }
