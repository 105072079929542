import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { API_EMPLOYER_UPLOAD, API_EMPLOYER_CONTRACT, API_EMPLOYER_SIGNATURE, API_EMPLOYER_SIGN_CONTRACT } from '../util/api.endpoints';
import { Injectable } from '@angular/core';
import { S3File } from 'src/@core/interfaces/s3-file.model';
import { Observable } from 'rxjs';

/**
 * Document and Profile image uploading in Employer.
 */
@Injectable({
  providedIn: 'root'
})
export class EmployerUploadService {
  constructor(private http: HttpClient) {
  }

  /**
   * Upload Profile Pic for Employer
   * @param file 
   * @param id 
   */
  uploadProfileImage(file: File): Observable<any> {
    // create a multipart-form
    const formData: FormData = new FormData();
    formData.append('profile', file, file.name);

    return this.http.post<S3File>(API_EMPLOYER_UPLOAD + `/profile`, formData, {
      reportProgress: true
    });
  }

  /**
   * Upload Document for Employer
   * @param file 
   * @param id 
   */
  uploadDocument(file: File, id: string): Observable<S3File> {
    // create a multipart-form
    const formData: FormData = new FormData();
    formData.append('document', file);
    return this.http.post<S3File>(API_EMPLOYER_UPLOAD + `/documenat/${id}`, formData);
  }

  // Upload any images
  uploadEmployerContractImages(file: any): Observable<any> {
    console.log(file)
    let filename = null;
    if (!file.name) {
      filename = "IMG-" + this.rendomFileName(5) + ".png";
    } else {
      filename = file.name;
    }
    const formData: FormData = new FormData();
    formData.append("profile", file, filename);

    return this.http.post<S3File>(API_EMPLOYER_CONTRACT, formData, {
      reportProgress: true
    });
  }

  // Upload any images
  uploadEmployerContractPDF(employerId, file: any): Observable<any> {
    console.log(file)
    let filename = null;
    if (!file.name) {
      filename = "Signed-Contract-" + employerId + ".pdf";
    } else {
      filename = file.name;
    }
    const formData: FormData = new FormData();
    formData.append("profile", file, filename);

    return this.http.post<S3File>(API_EMPLOYER_CONTRACT, formData, {
      reportProgress: true
    });
  }

  // Upload signature image
  uploadSignatureImages(file: any): Observable<any> {
    console.log(file)
    let filename = null;
    if (!file.name) {
      filename = "IMG-" + this.rendomFileName(5) + ".png";
    } else {
      filename = file.name;
    }
    const formData: FormData = new FormData();
    formData.append("signature", file, filename);

    return this.http.post<S3File>(API_EMPLOYER_SIGNATURE, formData, {
      reportProgress: true
    });
  }


  // Generate random file name
  rendomFileName(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  // Upload signed contract
  uploadSignedContract(obj: any): Observable<any> {
    return this.http.post<S3File>(API_EMPLOYER_SIGN_CONTRACT, obj, {
      reportProgress: true
    });
  }

}