import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  error(arg0: string) {
    throw new Error('Method not implemented.');
  }

  constructor(private snackBar: MatSnackBar, private zone: NgZone) {
  }

  public showMessage(message, duration, action?: any | null, className?: any | null) {
    this.zone.run(() => {
      this.snackBar.open(message, action, {
        duration,
        verticalPosition: 'bottom',
        horizontalPosition: 'center',
        panelClass: className != null ? [className] : '',
      });
    });
  }

  opnenComp(component: any) {
    this.snackBar.openFromComponent(component, {
      data: 'some data'
    });
  }
}
