import { BaseService } from '../base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_EMPLOYER } from '../util/api.endpoints';
import { Injectable } from '@angular/core';
import { Employer } from 'src/@core/interfaces/employer/employer.model';
import { Observable } from 'rxjs';
import { Search } from '../../interfaces/search';
import { PageableResponse } from '../../interfaces/pageable-response';

/**
 * Service response for fetch, create, update and delete the Employer.
 */

@Injectable({
  providedIn: 'root'
})
export class EmployerService extends BaseService<Employer> {
  constructor(private http: HttpClient) {
    super(http, API_EMPLOYER);
  }

  createUnecuredEmployer(request: any): Observable<any> {
    const url = API_EMPLOYER + `/unsecuredEmployer/`;
    return this.http.post<any>(url, request);
  }

  updateEmployer(id: any, request: any): Observable<any> {
    const url = API_EMPLOYER + `/` + id;
    return this.http.put<any>(url, request);
  }

  createEmployer(request: any): Observable<any> {
    const url = API_EMPLOYER;
    return this.http.post<any>(url, request);
  }

  getEmpList(): Observable<any> {
    const url = API_EMPLOYER + `/getEmpList`;
    return this.http.get<any>(url);
  }

  forceGetEmp(id: string): Observable<any> {
    const url = API_EMPLOYER + `/` + id;
    return this.http.get<any>(url);
  }

  searchByNameOrEmail(page: number, size: number, sort: string, order: string, searchList?: Search[], cacheKey?: any): Observable<PageableResponse<Employer>> {
    let params = new HttpParams();
    let url = API_EMPLOYER + `/searchByNameOrEmail` + '?page=' + page;
    
    if (size != null) {
      url += '&size=' + size;
    }

    if (order !== 'desc') {
      order = 'asc';
    }
    if (sort != null) {
      url += ('&sort=' + sort + ',' + order);
    } else {
      url += ('&sort=createdOn,desc');
    }

    if (searchList && searchList.length > 0) {
      searchList.forEach(search => {
        if (search.column && search.content) {
          params = params.append(search.column, search.content);
        }
      });
    }
    return this.http.get<PageableResponse<Employer>>(url, { params: params });
  }

}