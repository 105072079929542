import {UserAccount} from './account.model';

export class LoginResponse {

  private _access_token: string;
  private _accountName: string;
  private _roles: any[];
  private _expires_in: number;
  private _jti: string;
  private _scope: string;
  private _token_type: string;
  private _user_details: UserAccount;
  private _store_id: string;
  private _email: string;
  private _account_id: string;
  private _store_name: string;
  private _store_code: string;

  get user_details(): UserAccount {
    return this._user_details;
  }

  set user_details(value: UserAccount) {
    this._user_details = value;
  }

  get access_token(): string {
    return this._access_token;
  }

  set access_token(value: string) {
    this._access_token = value;
  }

  get accountName(): string {
    return this._accountName;
  }

  set accountName(value: string) {
    this._accountName = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get roles(): any[] {
    return this._roles;
  }

  set roles(value: any[]) {
    this._roles = value;
  }

  get expires_in(): number {
    return this._expires_in;
  }

  set expires_in(value: number) {
    this._expires_in = value;
  }

  get jti(): string {
    return this._jti;
  }

  set jti(value: string) {
    this._jti = value;
  }

  get scope(): string {
    return this._scope;
  }

  set scope(value: string) {
    this._scope = value;
  }

  get token_type(): string {
    return this._token_type;
  }

  set token_type(value: string) {
    this._token_type = value;
  }

  get storeId(): string {
    return this._store_id;
  }

  set storeId(value: string) {
    this._store_id = value;
  }

  get accountId(): string {
    return this._account_id;
  }

  set accountId(value: string) {
    this._account_id = value;
  }

  get storeName(): string {
    return this._store_name;
  }

  set storeName(value: string) {
    this._store_name = value;
  }

  get storeCode(): string {
    return this._store_code;
  }

  set storeCode(value: string) {
    this._store_code = value;
  }
  
}
