import { HttpClient } from '@angular/common/http';
import { API_EMPLOYMENT } from '../util/api.endpoints';
import { Injectable } from '@angular/core';
import { Observable , Subject} from 'rxjs';
import { ApprovalRequest } from 'src/@core/interfaces/employment/employment-approval-request.model';
import { share } from 'rxjs/operators';
import { Cacheable, CacheBuster } from 'ngx-cacheable';

/* cache buster Subject */
const cacheBuster$ = new Subject();

/**
 * Employment action service, responsible for any action on Employment.
 */
@Injectable({
  providedIn: 'root'
})

export class EmploymentActionService {
  constructor(private http: HttpClient) {
  }

  /**
   * Send Employment Approval request
   * @param id 
   * @param request 
   */
  @CacheBuster({
    cacheBusterNotifier: cacheBuster$
  })
  approve(id: any, request: ApprovalRequest): Observable<any> {
    const url = API_EMPLOYMENT + '/approve/' + id;
    return this.http.put<any>(url, request).pipe(share());
  }

  /**
   * Send Employment Activation Request
   * @param id 
   */
  @CacheBuster({
    cacheBusterNotifier: cacheBuster$
  })
  activate(id: any, request: any): Observable<any> {
    const url = API_EMPLOYMENT + '/activate/' + id;
    return this.http.put<any>(url, request).pipe(share());
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBuster$
  })
  deActivate(id: any, request: any): Observable<any> {
    const url = API_EMPLOYMENT + '/deactivate/' + id;
    return this.http.put<any>(url, request).pipe(share());
  }

}
