import { Injectable } from '@angular/core';
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {API_ACCOUNT, API_JOB_CATEGORY} from "./util/api.endpoints";
import {UserAccount} from "../interfaces/account.model";
import { ChangePasswordRequest } from '../interfaces/change-password.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseService<UserAccount> {
  constructor(private http: HttpClient) {
    super(http, API_ACCOUNT);
  }

  createEmployee(request: any): Observable<any> {
    const url = API_ACCOUNT + `/employee`;
    return this.http.post<any>(url, request);
  }

  createUnsecuredAcct(request: any): Observable<any> {
    const url = API_ACCOUNT + `/unsecuredAcct/`;
    return this.http.post<any>(url, request);
  }

  changePassword(request: ChangePasswordRequest): Observable<any> {
    const url = API_ACCOUNT + `/changePassword`;
    return this.http.put<any>(url, request);
  }

  adminChangePassword(request): Observable<any> {
    const url = API_ACCOUNT + `/admin/changePassword`;
    return this.http.put<any>(url, request);
  }

  deleteUser(code: string, id: string): Observable<any> {
    const url = API_ACCOUNT + `/deleteUser/${code}/${id}`;
    return this.http.delete<any>(url);
  }

}

