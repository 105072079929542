import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocietiesRoutingModule } from './societies-routing.module';
import { MatCardModule } from '@angular/material/card';
import { SharedModule } from 'src/app/shared/shared.module';
import { PageLayoutModule } from 'src/@core/components/page-layout/page-layout.module';
import { AddEditSocietiesComponent } from './add-edit-societies/add-edit-societies.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ViewSocietyComponent } from './view-society/view-society.component';
import { SocietyFilterDialogComponent } from './view-society/society-filter-dialog/society-filter-dialog.component';
import { BookSocietyDialogComponent } from './book-society-dialog/book-society-dialog.component';
import { BookingsComponent } from './bookings/bookings.component';

@NgModule({
  declarations: [AddEditSocietiesComponent, ViewSocietyComponent, SocietyFilterDialogComponent, BookSocietyDialogComponent, BookingsComponent],
  entryComponents: [
    SocietyFilterDialogComponent, // Add it to the entryComponents array
  ],
  imports: [CommonModule, SocietiesRoutingModule, MatCardModule, SharedModule, PageLayoutModule, ReactiveFormsModule],
})
export class SocietiesModule { }
