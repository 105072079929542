import { BaseService } from '../base.service';
import { JobCategory } from 'src/@core/interfaces/job/job-category.model';
import { HttpClient } from '@angular/common/http';
import { API_JOB_CATEGORY } from '../util/api.endpoints';
import { Injectable } from '@angular/core';

/**
 * Service response for fetch, create, update and delete the Job Category.
 */
@Injectable({
  providedIn: 'root'
})
export class JobCategoryService extends BaseService<JobCategory> {
  constructor(private http: HttpClient) {
      super(http, API_JOB_CATEGORY);
  }
}
