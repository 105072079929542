import { BaseService } from '../base.service';
import { JobType } from 'src/@core/interfaces/job/job-type.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_JOB_TYPE } from '../util/api.endpoints';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

declare const JOB_TYPES = 'JOB_TYPES';

/**
 * Service response for fetch, create, update and delete the Job Type.
 */
@Injectable({
  providedIn: 'root'
})
export class JobTypeService extends BaseService<JobType> {
  constructor(private http: HttpClient) {
      super(http, API_JOB_TYPE);
  }

  getTotalJobType(data: any): Observable<any> {
    const url = API_JOB_TYPE + '/count';
    let params = new HttpParams();
    // need to set params to send
    return this.http.get<any>(url, {params});
  }

}
