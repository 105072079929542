import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import icShoppingBasket from '@iconify/icons-ic/twotone-shopping-basket';
import { Router } from '@angular/router';
import { NavigationService } from '../../services/theme/navigation.service';
import { ConfigService } from 'src/@core/services/theme/config.service';
import { map } from 'rxjs/operators';
import { LayoutService } from 'src/@core/services/theme/layout.service';
import { roles } from 'src/app/shared/helpers/roles';


@Component({
  selector: 'codemiro-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {


  @Input() customTemplate: TemplateRef<any>;
  roles = roles;
  icShoppingBasket = icShoppingBasket;
  navigationItems = this.navigationService.items;
  isVerticalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isActive: boolean = false;




  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private configService: ConfigService,
    private layoutService: LayoutService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  openSidenav() {
    this.layoutService.openSidenav();
  }

  toggleActive() {
    this.isActive = !this.isActive;
    console.log(this.isActive);

  }
}
