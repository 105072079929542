import { trigger, state, style, transition, animate } from '@angular/animations';

export const expandCollapseAnimation = trigger('expandCollapse', [
  state('collapsed', style({
    height: '0',
    opacity: '0',
    padding: '0',
    overflow: 'hidden'
  })),
  state('expanded', style({
    height: '*',
    opacity: '1',
    padding: '*',
  })),
  transition('collapsed <=> expanded', [
    animate('300ms ease-in-out')
  ])
]);
