import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { API_CANCEL_SHIFT, API_CHECKOUT_SHIFT, API_INVOICE_BY_ID, API_JOB } from '../util/api.endpoints';
import { Injectable } from '@angular/core';
import { Job } from 'src/@core/interfaces/job/job.model';

/**
 * Service response for fetch, create, update and delete the Job.
 */
@Injectable({
  providedIn: 'root'
})
export class JobService extends BaseService<Job> {
  constructor(private http: HttpClient) {
    super(http, API_JOB);
  }

  getCancelShiftDetails(id) {
    const url = API_CANCEL_SHIFT + '/info/' + id;
    console.log(url)
    return this.http.get<any>(url);
  }

  cancelShift(request) {
    const url = API_CANCEL_SHIFT;
    console.log(url)
    return this.http.post<any>(url, request);
  }

  getEmployementDetails(id) {
    const url = API_CHECKOUT_SHIFT + '/fetch?employmentId=' + id;
    return this.http.get<any>(url);
  }

  deleteJobTemplate(id) {
    const url = API_JOB + '/' + id;
    return this.http.delete<any>(url);
  }

  getInvoiceDetails(id) {
    const url = API_INVOICE_BY_ID + '/' + id;
    return this.http.get<any>(url);
  }

}
