import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { LoginResponse } from '../interfaces/login-response.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppService } from './app.service';
import { API_AUTH, API_AUTH_LOGIN, API_UPDATE_ENTITY, CHANGE_RESET_PASSWORD, SEND_OTP, VERIFY_OTP_WITH_DETAILS } from './util/api.endpoints';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  cachedRequests: Array<HttpRequest<any>> = [];

  public basicAuthHeader = 'Basic ' + btoa('web-client:hour4u');

  constructor(private http: HttpClient, private appService: AppService) {
  }

  login(username: string, password: string): Observable<LoginResponse> {
    const headers = new HttpHeaders({
      Authorization: this.basicAuthHeader,
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const options = { headers };
    // tslint:disable-next-line:max-line-length
    const params = new HttpParams().set('grant_type', 'password').set('username', username).set('password', password);
    return this.http.post<LoginResponse>(API_AUTH_LOGIN + '/oauth/token',
      params,
      options).pipe(map(user => {
        this.appService.setCurrentAccount(user);
        return user;
      }));
  }

  loginEnterprise(loginType: string, username: string, password: string): Observable<LoginResponse> {
    const headers = new HttpHeaders({
      Authorization: this.basicAuthHeader,
      'Content-Type': 'application/x-www-form-urlencoded',
      // observe: 'response'
    });
    // const headers = {
    //   headers: new HttpHeaders({
    //     Authorization: this.basicAuthHeader,
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //     // observe: 'response'
    //   }),
    //   observe: 'response'
    // };

    const options = { headers };
    console.log("options : ", options);
    // tslint:disable-next-line:max-line-length
    // const params = new HttpParams().set('loginType', 'PASSWORD').set('userName', 'zepto-dev@gmail.com').set('userNameType', 'EMAIL').set('authentication', 'xyz');
    let params = {
      'loginType': loginType,
      'userName': username,
      // 'userName': 'dhruviEnterpriseTeam@gmail.com',
      // 'userName': '8279305654',
      // 'userNameType': 'EMAIL',
      'userNameType': username.includes('@') ? 'EMAIL' : 'MOBILE',
      'authentication': password,
    };
    return this.http.post<any>(API_AUTH + '/v1/enterprise/user/login',
      params,
      options).pipe(map((user: any) => {
        console.log("user", user);
        let userData = user.entityUserMapping[0];
        userData.user = user.user;
        userData.roles = [];
        userData.permissions = [];
        userData.roles.push(userData.role.roleName);
        userData.permissions.push(userData.role.permissions);
        this.appService.setCurrentAccount(userData);
        // const header = user.headers.get('Token');
        // console.log("eader-------", header)
        return userData;
      }));
  }

  sendForgotLink(email: string): Observable<any> {
    const url = API_AUTH + `/v1/auth/forgot-password?email=${email}`;
    return this.http.get<any>(url);
  }

  sendOtpLink(mobile: string, type: string): Observable<any> {
    const url = API_AUTH + `/v1/auth/employer-signup-otp?${type}=${mobile}`;
    return this.http.get<any>(url);
  }

  checkOtpLink(mobile: string, otp: string): Observable<any> {
    const url = API_AUTH + `/v1/auth/employer-otp-check/${mobile}/${otp}`;
    return this.http.get<any>(url);
  }

  verifyAndReset(request: any): Observable<any> {
    const url = API_AUTH + `/v1/enterprise/verify-reset-password`;
    return this.http.put(url, request);
  }

  public collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }

  public retryFailedRequests(): void {
    // retry the requests. this method can
    // be called after the token is refreshed
  }

  generateToken(mobile, otp) {
    // let obj = { "username": mobile, "password": otp, "grant_type": "password" }
    // const url = API_AUTH + `/auth-service/oauth/token`;
    // return this.http.post<any>(url, obj);
    const headers = new HttpHeaders({
      Authorization: this.basicAuthHeader,
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const options = { headers };

    const params = new HttpParams().set('grant_type', 'password').set('username', mobile).set('password', otp);
    return this.http.post<LoginResponse>(API_AUTH + '/oauth/token',
      params, options).pipe(map(user => {
        console.log("call", user);
        // this.appService.setCurrentAccount(user);
        return user;
      }));
  }


  sendForgotOTPLink(payload: any): Observable<any> {
    const url = SEND_OTP;
    return this.http.post<any>(url, payload);
  }

  verifyOTPWithDetails(payload: any): Observable<any> {
    const url = VERIFY_OTP_WITH_DETAILS;
    return this.http.post<any>(url, payload);
  }

  changeResetPassword(payload: any): Observable<any> {
    const url = CHANGE_RESET_PASSWORD;
    return this.http.put<any>(url, payload);
  }

  updateProfile(payload: any, id: any): Observable<any> {
    const url = API_UPDATE_ENTITY + '/' + id;
    return this.http.put<any>(url, payload);
  }

}