import { Injectable } from "@angular/core";
import { strictEqual } from "assert";
import { Search } from "../interfaces/search";

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    jsListPage: number;
    jsLastStatus: string;
    emptListPage: number;
    emptLastStatus: string;
    emprListPage: number;
    jobListPage: number;
    jobTypeListPage: number;
    jsSearchFilters: Search[] = [];
    jSearchFilters: Search[] = [];
    jSearchParams: Search[] = [];
    tSearchParams: Search[] = [];
    eSearchParams: Search[] = [];
    jsSearchParams: Search[] = [];
    jSortParams: any = null;
    tSortParams: any = null;
    eSortParams: any = null;
    jsSortParams: any = null;
    InvoiceListPage: number;

    setJSListPage(i: number, status: string) {
        this.jsListPage = i;
        this.jsLastStatus = status;
    }

    getJSListPage() {
        return this.jsListPage;
    }

    getJSLastStatus() {
        return this.jsLastStatus;
    }

    setJSSortFilters(js: any) {
        this.jsSortParams = js;
    }

    getJSSortFilters() {
        return this.jsSortParams;
    }

    setJSSearchParams(js: Search[]) {
        this.jsSearchParams = js;
    }

    getJSSearchParams() {
        return this.jsSearchParams;
    }
    setJSSearchFilters(sf: Search[]) {
        this.jsSearchFilters = sf;
    }

    getJSSearchFilters() {
        return this.jsSearchFilters;
    }

    setJSearchFilters(js: Search[]) {
        this.jSearchFilters = js;
    }

    getJSearchFilters() {
        return this.jSearchFilters;
    }

    setJSearchParams(js: Search[]) {
        this.jSearchParams = js;
    }

    getJSearchParams() {
        return this.jSearchParams;
    }

    setJSortParams(js: any) {
        this.jSortParams = js;
    }

    getJSortParams() {
        return this.jSortParams;
    }


    setJASearchFilters(js: Search[]) {
        this.jSearchFilters = js;
    }

    getJASearchFilters() {
        return this.jSearchFilters;
    }

    setJASearchParams(js: Search[]) {
        this.jSearchParams = js;
    }

    getJASearchParams() {
        return this.jSearchParams;
    }

    setJASortParams(js: any) {
        this.jSortParams = js;
    }

    getJASortParams() {
        return this.jSortParams;
    }


    setTSortParams(ts: any) {
        this.tSortParams = ts;
    }

    getTSortParams() {
        return this.tSortParams;
    }

    setTSearchParams(js: Search[]) {
        this.tSearchParams = js;
    }

    getTSearchParams() {
        return this.tSearchParams;
    }


    setEmptListPage(i: number, status: string) {
        this.emptListPage = i;
        this.emptLastStatus = status;
    }

    getEmptListPage() {
        return this.emptListPage;
    }

    getEmptLastStatus() {
        return this.emptLastStatus;
    }

    setEmprListPage(i: number) {
        this.emprListPage = i;
    }

    setESortParams(ts: any) {
        this.eSortParams = ts;
    }

    getESortParams() {
        return this.eSortParams;
    }

    setESearchParams(js: Search[]) {
        this.eSearchParams = js;
    }

    getESearchParams() {
        return this.eSearchParams;
    }


    getEmprListPage() {
        return this.emprListPage;
    }

    setJobListPage(i: number) {
        this.jobListPage = i;
    }

    getJobListPage() {
        return this.jobListPage;
    }

    setJobTypeListPage(i: number) {
        this.jobTypeListPage = i;
    }

    getJobTypeListPage() {
        return this.jobTypeListPage;
    }

    setInvoiceListPage(i: number) {
        this.InvoiceListPage = i;
    }

    getInvoiceListPage() {
        return this.InvoiceListPage;
    }

}