export class PaymentAttendance {
    id: string;
  
    jobSeekerId: string;
	jobSeekerName: string;
    employmentId: string;
    employerId: string;
    jobTitle: string;
    amount: number;
    date: string;
    hours: number;
    status: string;
    employmentTitle: string;
    isDelete: boolean;
    expectedAmount: number;
    settledAmount: number;
    checkIn: Date;
    checkOut: Date;
    totalRecordedTime: string;
    rate: number;
    ratingId: string;
  }