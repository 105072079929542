import { Injectable } from '@angular/core';
import {
    CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route,
    CanActivateChild, Router, NavigationExtras
} from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';
import { LoginResponse } from '../interfaces/login-response.model';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    user: any;
    constructor(private app: AppService, private router: Router) { }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.canActivate(childRoute, state);
    }
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkLogin(state.url, next);
    }
    checkLogin(url: string, route: ActivatedRouteSnapshot): boolean {
        this.user = this.app.getCurrentAccount();
        if (this.app.isLoggedIn()) {
            // check if route is restricted by role
            // if (route.data.roles && this.user.roles.filter(x => route.data.roles.indexOf(x) === -1).length > 0) {
            //     //if (route.data.roles && route.data.roles.indexOf(this.user.roles[0]) === -1) {
            //     // role not authorised so redirect to home page
            //     this.router.navigate(['/dashboard']);
            //     return false;
            // }
            // return true;
            // Check if the route is restricted by permission
            if (this.user.permissions && this.user.permissions.length) {
                if (this.user && route.data.permissions && !this.hasRequiredPermission(this.user.permissions, route.data.permissions)) {
                    this.router.navigate(['/dashboard']);
                    return false;
                }
            }
            return true;
        }
        // this.router.navigate(['/dashboard']);
        // return;
        // this.router.navigate(['/login']);
        this.app.setRedirectURL(url);
        return false;
        // store the attempted URL for redirecting
        // create session_id
        const sessionId = 12345567;
        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: { 'session_id': sessionId },
            fragment: 'anchor'
        }
        console.log("##############3", url)
        // this.router.navigate(['/'], navigationExtras);
        return false;
    }

    // private hasRequiredPermission(userPermissions: string[][], requiredPermissions: string[]): boolean {
    //     return requiredPermissions.some(permission => userPermissions.flat().includes(permission));
    // }
    private hasRequiredPermission(userPermissions: string[][], requiredPermissions: string[]): boolean {
        if (userPermissions && Array.isArray(userPermissions) && userPermissions.length > 0) {
            return requiredPermissions.some(permission => userPermissions.flat().includes(permission));
        } else {
            // If userPermissions is undefined or empty, navigate to the home page
            this.router.navigateByUrl('/');
            return false;
        }
    }
}