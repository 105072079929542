import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'hour4u-admin-success-screen',
  templateUrl: './success-screen.component.html',
  styleUrls: ['./success-screen.component.scss']
})
export class SuccessScreenComponent implements OnInit {
  shiftname: string = '';
  amount: any;

  constructor(
    public router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(async data => {
      if (data) {
        this.shiftname = data.obj.shiftName
        this.amount = data.obj.amount
      }
    })
  }

  postAnotherShift() {
    this.router.navigateByUrl('employment/all/all');
  }

  chatWithSupport() {
    window.open('https://api.whatsapp.com/send?phone=' + environment.contactSuppportNumber, "_blank");
  }
}
