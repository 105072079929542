import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from 'src/app/shared/shared.module';
import {CurdBaseModule} from 'src/app/shared/base/curd-base.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {IconModule} from '@visurel/iconify-angular';
import {ColorFadeModule} from 'src/@core/pipes/color/color-fade.module';
import {MaterialShared} from 'src/app/shared/material-shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import { PaymentsComponent } from './payments/payments.component';
import { PaymentStatusDialogComponent } from './payment-status-dialog/payment-status-dialog.component';
import { PaymentFilterDialogComponent } from './payment-filter-dialog/payment-filter-dialog.component';


const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: ''
  // },
  {
    path: '', component: PaymentsComponent
  },
  {
    path: 'payments', component: PaymentsComponent
  },
  {
    path: ':status', component: PaymentsComponent
  }
];


@NgModule({
  declarations: [ PaymentsComponent, PaymentStatusDialogComponent, PaymentFilterDialogComponent],
  imports: [
    FlexLayoutModule,
    IconModule,
    ColorFadeModule,
    MaterialShared,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    CurdBaseModule,
    RouterModule.forChild(routes)
  ],
  exports: [PaymentStatusDialogComponent],
  entryComponents: [ PaymentStatusDialogComponent , PaymentFilterDialogComponent]
})



export class PaymentsModule { }
