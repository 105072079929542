import { BaseService } from '../base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  API_EMPLOYMENT, API_EMPLOYMENT_ASSIGN_JOB_ADMIN, API_EMPLOYMENT_GET_JOB_ADMIN,
  API_EMPLOYMENT_LIST,
  API_GET_ASSIGNED_ADMINS,
  API_JOB_APPLICATION,
  API_JOB_APPLICATION_V2
} from '../util/api.endpoints';
import { Injectable } from '@angular/core';
import { Employment } from 'src/@core/interfaces/employment/employment.model';
import { Observable } from 'rxjs';
import { PageableResponse } from 'src/@core/interfaces/pageable-response';
import { Search } from 'src/@core/interfaces/search';
import * as _ from 'lodash';

/**
 * Service response for fetch, create, update and delete the Employment.
 */
@Injectable({
  providedIn: 'root'
})

export class EmploymentService extends BaseService<Employment> {
  constructor(private http: HttpClient) {
    super(http, API_EMPLOYMENT);
  }

  searchAndSortEmployment(page: number, size: number, sort: string, order: string, searchList?: Search[], cacheKey?: any): Observable<PageableResponse<any>> {
    let params = new HttpParams();
    let url = API_EMPLOYMENT_LIST + '?page=' + page;

    if (size != null) {
      url += '&size=' + size;
    }

    if (order !== 'desc') {
      order = 'asc';
    }
    if (sort != null) {
      url += ('&sort=' + sort + ',' + order);
    } else {
      url += ('&sort=createdOn,desc');
    }

    if (searchList && searchList.length > 0) {
      _.forEach(searchList, (search) => {
        if (search.column && search.content) {
          params = params.append(search.column, search.content);
        }
      });
    }
    return this.http.get<PageableResponse<any>>(url, { params: params });
  }

  updateV2(id: any, data: Employment): Observable<Employment> {
    const url = API_EMPLOYMENT + '/updateV2/' + id;
    return this.http.put<Employment>(url, data);
  }

  assignJobSupervisorAdmin(data: any): Observable<Employment> {
    const url = API_EMPLOYMENT_ASSIGN_JOB_ADMIN;
    return this.http.post<Employment>(url, data);
  }

  updateAssignedJobSupervisorAdmin(data: any): Observable<Employment> {
    const url = API_EMPLOYMENT_ASSIGN_JOB_ADMIN;
    return this.http.put<Employment>(url, data);
  }

  getJobSupervisorAdmin(pageSize: number, pageIndex: number, status: string, role: string, id: any): Observable<Employment> {
    let idParam = '?page=' + pageIndex;
    if (pageSize != null) {
      idParam += '&size=' + pageSize;
    }
    if (status) {
      idParam += '&status=' + status;
    }
    if (role == 'JOB_ADMIN') {
      idParam += '&jobAdminId=' + id;
    }

    if (role == 'JOB_SUPERVISOR') {
      idParam += '&jobSuperVisorId=' + id;
    }
    idParam += '&sort=' + 'createdOn,desc';
    const url = API_EMPLOYMENT_ASSIGN_JOB_ADMIN + '/' + role + idParam;
    return this.http.get<Employment>(url);
  }

  getAssignedAdmins(id: any): Observable<Employment> {
    const url = API_GET_ASSIGNED_ADMINS + '/' + id;
    return this.http.get<any>(url);
  }



  getEmployementForAdmins(page: number, size: number, order: string, searchList?: Search[], cacheKey?: any): Observable<PageableResponse<any>> {
    let params = new HttpParams();
    let url = API_JOB_APPLICATION_V2 + '?page=' + page;

    if (size != null) {
      url += '&size=' + size;
    }

    if (order !== 'desc') {
      order = 'asc';
    }
    // if (sort != null) {
    //   url += ('&sort=' + sort + ',' + order);
    // } else {
    url += ('&sort=createdOn,desc');
    // }

    if (searchList && searchList.length > 0) {
      _.forEach(searchList, (search) => {
        if (search.column && search.content) {
          params = params.append(search.column, search.content);
        }
      });
    }
    return this.http.get<PageableResponse<any>>(url, { params: params });
  }
}
