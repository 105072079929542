import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import icSearch from '@iconify/icons-ic/twotone-search';
import icAdd from '@iconify/icons-ic/twotone-add';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import iccached from '@iconify/icons-ic/twotone-cached';

@Component({
  selector: 'codemiro-header-layout',
  templateUrl: './codemiro-header.component.html',
  styleUrls: ['./codemiro-header.component.scss']
})

export class CodemiroHeaderComponent implements OnInit {

  @Input("title") title: any;
  @Output("create") create = new EventEmitter();
  @Output("openDialogColumnOptions") openDialogColumnOptions = new EventEmitter();
  @Output("reloadTable") reloadTable = new EventEmitter();
  @Output("sortData") sortData = new EventEmitter();
  @Output("search") search = new EventEmitter();
  term=''
  isSearch=false;
  icAdd = icAdd;
  icFilterList = icFilterList;
  icSearch = icSearch;
  iccached = iccached;

  constructor() { }

  ngOnInit() { }
}
