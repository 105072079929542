import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { API_EMPLOYMENT_HISTORY } from '../util/api.endpoints';
import { Injectable } from '@angular/core';
import { EmploymentHistory } from 'src/@core/interfaces/employment/employment-history.model';

/**
 * Service response for fetch, create, update and delete the Employment history.
 */
@Injectable({
  providedIn: 'root'
})

export class EmploymentHistoryService extends BaseService<EmploymentHistory> {
  totalTasks: number = 0;

  constructor(private http: HttpClient) {
    super(http, API_EMPLOYMENT_HISTORY);
  }
}
