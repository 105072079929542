import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { API_STATS} from "../util/api.endpoints";
import {share} from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatsService  {
  constructor(private http: HttpClient) {
  }


  getGrandTotalHours(): Observable<any>  {
    return this.http.get<any>(API_STATS + '/totalhours').pipe(share());;
  }

  getTotalHoursByEmployer(id: string): Observable<any>  {
    return this.http.get<any>(API_STATS + '/totalhours/employer/'+ id).pipe(share());;
  }

  getTotalHoursByJobSeeker(id: string): Observable<any>  {
    return this.http.get<any>(API_STATS + '/totalhours/jobseeker/' + id).pipe(share());;
  }

  getTotalShiftsByJobSeeker(id: string): Observable<any>  {
    return this.http.get<any>(API_STATS + '/totalshifts/jobseeker/' + id).pipe(share());;
  }
  
  getJobSeekersStats(ids: string): Observable<any>  {
    return this.http.get<any>(API_STATS + '/jobseeker?jobSeekerIds=' + ids);
  }

}
