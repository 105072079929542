import { ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import { fadeInUp400ms } from 'src/@core/animations/fade-in-up.animation';
import { LoginResponse } from 'src/@core/interfaces/login-response.model';
import { AuthService } from 'src/@core/services/auth.service';
import { AppService } from 'src/@core/services/app.service';
import { NotificationService } from 'src/@core/services/notification.service';
import { MatDialog } from '@angular/material';
import { first } from 'rxjs/operators';
import { MessageConstant } from 'src/app/shared/helpers/message.constant';
import { AccountService } from 'src/@core/services/account.service';
import { roles } from 'src/app/shared/helpers/roles';
import { EmployerService } from 'src/@core/services/employer/employer.service';
import { Employer } from 'src/@core/interfaces/employer/employer.model';

@Component({
  selector: 'hour4u-admin-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeInUp400ms
  ]
})
export class LoginComponent implements OnInit {
  loading = false;
  model: any = {};
  loginStatus: string = null;
  user: LoginResponse;
  form: FormGroup;
  isLoading = false;

  inputType = 'password';
  visible = false;

  icVisibility = icVisibility;
  icVisibilityOff = icVisibilityOff;

  constructor(private authService: AuthService,
    private router: Router,
    private app: AppService,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private notify: NotificationService,
    private accountService: AccountService,
    private dialog: MatDialog,
    private employerService: EmployerService,
    private route: ActivatedRoute) {
    //Employer signup
    //this.empSignup();
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  toggleVisibility() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }

  signIn() {
    this.loginStatus = 'progress';
    this.isLoading = true;

    this.authService.login(this.form.value.email, this.form.value.password)
      .pipe(first())
      .subscribe(async data => {
        await this.setUserData(data.accountId);
        this.isLoading = true;
      },
        error => {
          this.loginStatus = null;
          this.notify.showMessage(MessageConstant.Common.InvalidCred, 3000);
          this.isLoading = false;
          this.cd.detectChanges();
        });
  }

  empSignup() {
    this.router.navigate(['/employer-signup'], {
      queryParams: {
        pageStatus: 'setup'
      }
    });
  }

  async setUserData(accountId) {
    await this.accountService.getById(accountId).toPromise()
      .then(async data => {
        const user = this.app.getCurrentAccount();
        user.user_details = data;
        this.app.setCurrentAccount(user);
        if (data.employerId != null) {
          this.app.setIsEmployerLogin(true);
          await this.loadEmployerInfo(data.employerId);
        }
      }).then(data => {
        this.loginStatus = null;
        this.loading = false;
        this.redirectNow();
      });
  }


  async loadEmployerInfo(id: string) {
    await this.employerService.getById(id).toPromise()
      .then((employer: Employer) => {
        if (employer) {
          this.app.setEmployer(employer);
        }
      }).catch(error => {
        this.notify.showMessage('Something went wrong with your details, try again after sometime...', 3000);
        this.app.logout();
      });
  }

  redirectNow() {
    const user = this.app.getCurrentAccount();

    if (user.roles.includes('JOB_SUPERVISOR')) {
      this.router.navigate(['/supervisor-admin']);
    } else if (user.roles.includes('PERMISSION_USER')) {
      this.router.navigate(['/societies']);
    } else {
      this.router.navigateByUrl(this.app.getRedirectURL());
    }

  }
}
