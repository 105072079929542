import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EmploymentActionService } from 'src/@core/services/employment/employment-action.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NotificationService } from 'src/@core/services/notification.service';
import { JobTypeService } from 'src/@core/services/job/job-type.service';
import { JobService } from 'src/@core/services/job/job.service';
import { Job } from 'src/@core/interfaces/job/job.model';
import { Employment, JobSeekerPaymentInfos } from 'src/@core/interfaces/employment/employment.model';
import { JobTypePrice, JobTypeHourlyPriceRange } from 'src/@core/interfaces/job/job-type.model';
import { ApprovalRequest } from 'src/@core/interfaces/employment/employment-approval-request.model';
import { AddEditEmploymentComponent } from '../add-edit-employment/add-edit-employment.component';
import { BehaviorSubject } from 'rxjs';
import { DateTime } from 'luxon';

@Component({
  selector: 'hour4u-admin-approve-employment',
  templateUrl: './approve-employment.component.html',
  styleUrls: ['./approve-employment.component.scss']
})

export class ApproveEmploymentComponent implements OnInit {

  notifyEmployer = false;
  notifyJobSeeker = false;
  flatRate = false;

  maleRangeInfo: JobSeekerPaymentInfos;
  femaleRangeInfo: JobSeekerPaymentInfos;

  rangeInfo: JobSeekerPaymentInfos[] = [];
  request: ApprovalRequest = new ApprovalRequest();
  minTotal: number = 0;
  maxTotal: number = 0;
  fixedTotal: number = 0;
  submitted: boolean = false;
  selectedJobSupervisor: boolean = false;
  selectedJobAdmin: boolean = false;
  selectedBidding: boolean = true;
  commissionForJobAdmin: any[] = [];
  commissionForJobSupervisor: any[] = [];
  totalCommissionAmount: any[] = [];

  constructor(public dialogRef: MatDialogRef<ApproveEmploymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Employment,
    private service: EmploymentActionService,
    private notify: NotificationService,
    private jobTypeService: JobTypeService,
    private jobService: JobService,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef
  ) { }

  async ngOnInit() {
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  async ngAfterViewInit() {
    console.log(this.data)
    if (this.data.flatRate)
      this.flatRate = true;

    // load job type
    await this.jobService.getById(this.data.jobId).toPromise()
      .then(async (job: Job) => {
        await this.jobTypeService.getById(job.jobTypeId).toPromise()
          .then(jobType => {
            job.jobType = jobType;
          })
        this.data.job = job;
        this.data.employerPaymentInfos.forEach((ele: any) => {
          ele.totalShiftPayment = 0;
          if (!ele.flatRate) {
            this.data.dates.forEach(date => {
              const hours = this.hoursOfJob(date.date, date.timeFrom, date.timeTo);
              if (ele.gender == 'Female') {
                ele.totalShiftPayment = (ele.maxRate * hours) + this.data.job.jobType.employerPrices[1].basePrice;
              }
              if (ele.gender == 'Male') {
                ele.totalShiftPayment = (ele.maxRate * hours) + this.data.job.jobType.employerPrices[0].basePrice;
              }
            });
          }
        })
      }).finally(() => {
        this.setPaymentInfo();
      });

  }

  setPaymentInfo() {
    if (this.data.jobSeekerPaymentInfo && this.data.jobSeekerPaymentInfo_female) {
      this.selectedBidding = this.data.jobSeekerPaymentInfo.biddingAllowed;
      this.rangeInfo.push(
        {
          level: this.data.jobSeekerPaymentInfo_female.level,
          gender: this.data.jobSeekerPaymentInfo_female.gender,
          maxRate: this.data.jobSeekerPaymentInfo_female.maxRate,
          minRate: this.data.jobSeekerPaymentInfo_female.minRate,
          basePrice: this.data.jobSeekerPaymentInfo_female.basePrice,
          minTotal: this.getMinTotal(this.data.jobSeekerPaymentInfo_female),
          maxTotal: this.getMaxTotal(this.data.jobSeekerPaymentInfo_female),
          fixedRate: this.data.jobSeekerPaymentInfo_female.fixedRate ? this.data.jobSeekerPaymentInfo_female.fixedRate : null,
          fixedTotal: this.getFixedTotal(this.data.jobSeekerPaymentInfo_female)
        },
        {
          level: this.data.jobSeekerPaymentInfo.level,
          gender: this.data.jobSeekerPaymentInfo.gender,
          maxRate: this.data.jobSeekerPaymentInfo.maxRate,
          minRate: this.data.jobSeekerPaymentInfo.minRate,
          basePrice: this.data.jobSeekerPaymentInfo.basePrice,
          minTotal: this.getMinTotal(this.data.jobSeekerPaymentInfo),
          maxTotal: this.getMaxTotal(this.data.jobSeekerPaymentInfo),
          fixedRate: this.data.jobSeekerPaymentInfo.fixedRate ? this.data.jobSeekerPaymentInfo.fixedRate : null,
          fixedTotal: this.getFixedTotal(this.data.jobSeekerPaymentInfo)
        }
      );
    }
    this.setJobAdminAndSupervisor();
  }

  setJobAdminAndSupervisor() {
    this.commissionForJobAdmin = [];
    this.commissionForJobSupervisor = [];
    this.totalCommissionAmount = [];
    this.rangeInfo.forEach(ele => {
      let obj = {
        gender: ele.gender,
        level: ele.level,
        maxRate: ele.maxRate,
        minRate: ele.minRate,
        basePrice: ele.basePrice,
        minTotal: ele.minTotal,
        maxTotal: ele.maxTotal,
        fixedRate: ele.fixedRate ? ele.fixedRate : 0,
        commissionAmount: null,
        commissionPercentage: null,
        finalShiftFixedAmount: 0,
        finalShiftMinAmount: 0,
        finalShiftMaxAmount: 0,
        fixedTotal: ele.fixedTotal
      }
      this.commissionForJobAdmin.push(obj);
      this.commissionForJobSupervisor.push(obj);
      this.totalCommissionAmount.push(obj);
      this.getFinalShiftFixedAmount(this.totalCommissionAmount);
    })

    if (this.data.jobFulfilerCommission) {
      if (this.data.jobFulfilerCommission.joFulfilerType == 'JOB_ADMIN') {
        this.commissionForJobAdmin = [];
      } else {
        this.commissionForJobSupervisor = [];
      }
      this.rangeInfo.forEach(ele => {
        let obj = {
          gender: ele.gender,
          level: ele.level,
          maxRate: ele.maxRate,
          minRate: ele.minRate,
          basePrice: ele.basePrice,
          minTotal: ele.minTotal,
          maxTotal: ele.maxTotal,
          fixedRate: ele.fixedRate,
          commissionAmount: (ele.gender == 'Male' ? this.data.jobFulfilerCommission.maleCommission.commissionAmount : (ele.gender == 'Female' ? this.data.jobFulfilerCommission.femaleCommission.commissionAmount : null)),
          commissionPercentage: (ele.gender == 'Male' ? this.data.jobFulfilerCommission.maleCommission.commissionPercentage : (ele.gender == 'Female' ? this.data.jobFulfilerCommission.femaleCommission.commissionPercentage : null)),
          finalShiftFixedAmount: 0,
          finalShiftMinAmount: 0,
          finalShiftMaxAmount: 0,
          fixedTotal: ele.fixedTotal
        }
        if (this.data.jobFulfilerCommission.joFulfilerType == 'JOB_ADMIN') {
          this.commissionForJobAdmin.push(obj);
          this.selectedJobAdmin = true;
          this.selectAssigner('JobAdmin');
          this.getFinalShiftFixedAmount(this.commissionForJobAdmin);
        } else {
          this.commissionForJobSupervisor.push(obj);
          this.selectedJobSupervisor = true;
          this.selectAssigner('JobSupervisor');
          this.getFinalShiftFixedAmount(this.commissionForJobSupervisor);
        }
      })
    }
  }

  // set final amount at clone shift
  getFinalShiftFixedAmount(array) {
    let selectedShiftPayment = null;
    array.forEach(element => {
      this.data.employerPaymentInfos.forEach((emp: any) => {
        if (emp.gender == element.gender) {
          selectedShiftPayment = emp;
          if (this.flatRate) {
            if (!this.selectedBidding) {
              element.finalShiftFixedAmount = selectedShiftPayment.flatRate - element.fixedTotal - element.commissionAmount;
            } else {
              element.finalShiftMinAmount = selectedShiftPayment.flatRate - element.minTotal - element.commissionAmount;
              element.finalShiftMaxAmount = selectedShiftPayment.flatRate - element.maxTotal - element.commissionAmount;
            }
          } else {
            if (!this.selectedBidding) {
              element.finalShiftFixedAmount = selectedShiftPayment.totalShiftPayment - element.fixedTotal - element.commissionAmount;
            } else {
              element.finalShiftMinAmount = selectedShiftPayment.totalShiftPayment - element.minTotal - element.commissionAmount;
              element.finalShiftMaxAmount = selectedShiftPayment.totalShiftPayment - element.maxTotal - element.commissionAmount;
            }
          }
        }
      })
    });
  }

  getFixedTotal(info) {
    this.fixedTotal = 0;
    try {
      this.data.dates.forEach(date => {
        const hours = this.hoursOfJob(date.date, date.timeFrom, date.timeTo);
        this.fixedTotal += Math.round(info.basePrice + (info.fixedRate * hours));
      });
    } catch {
    }
    return this.fixedTotal;
  }

  getMinTotal(info) {
    this.minTotal = 0;
    try {
      this.data.dates.forEach(date => {
        const hours = this.hoursOfJob(date.date, date.timeFrom, date.timeTo);
        this.minTotal += Math.round(info.basePrice + (info.minRate * hours));
      });
    } catch {
    }
    return this.minTotal;
  }

  getMaxTotal(info) {
    this.maxTotal = 0;
    try {
      this.data.dates.forEach(date => {
        const hours = this.hoursOfJob(date.date, date.timeFrom, date.timeTo);
        this.maxTotal += Math.round(info.basePrice + (info.maxRate * hours));
      });
    } catch {
    }
    return this.maxTotal;
  }

  /**
 * Simple timer
 */
  hoursOfJob(dateObject: any, timeFrom: any, timeTo: any): number {
    let scheduledStartDate: DateTime = DateTime.local(dateObject[0], dateObject[1], dateObject[2], timeFrom[0], timeFrom[1]);
    let scheduledEndDate: DateTime = DateTime.local(dateObject[0], dateObject[1], dateObject[2], timeTo[0], timeTo[1]);
    scheduledStartDate.setLocale('in-IN');
    scheduledEndDate.setLocale('in-IN');

    let dateEndObject: any[] = [];
    dateEndObject = Object.assign(dateEndObject, dateObject);
    if (scheduledStartDate.toString() > scheduledEndDate.toString()) {
      dateEndObject[2] = this.addDaysInDate(dateObject, 1)
    }

    let jobStartDate = new Date(dateObject[0], dateObject[1] - 1, dateObject[2]);
    jobStartDate.setHours(timeFrom[0]);
    jobStartDate.setMinutes(timeFrom[1]);


    let jobEndDate = new Date(dateEndObject[0], dateEndObject[1] - 1, dateEndObject[2]);
    jobEndDate.setHours(timeTo[0]);
    jobEndDate.setMinutes(timeTo[1]);
    const jobEndTime = (jobEndDate.getTime() - jobStartDate.getTime()) / 1000;
    return Math.abs(jobEndTime / 3600);
  }

  // Add days into given date
  addDaysInDate(dateObject: any, days: number) {
    var futureDate = new Date(dateObject[0], dateObject[1] - 1, dateObject[2]);
    futureDate.setDate(futureDate.getDate() + days);
    return futureDate.getDate();
  }

  onSubmit() {
    this.submitted = true;
    let validate = this.checkValidations();
    if (validate) {
      let jobSeekerPaymentInfoMale = this.rangeInfo.filter(ele =>
        ele.gender == 'Male'
      );
      let jobSeekerPaymentInfoFemale = this.rangeInfo.filter(ele =>
        ele.gender == 'Female'
      );

      let jobSeekerMale = {
        "basePrice": jobSeekerPaymentInfoMale[0].basePrice,
        "biddingAllowed": this.selectedBidding,
        "fixedRate": !this.selectedBidding ? jobSeekerPaymentInfoMale[0].fixedRate : 0,
        "gender": jobSeekerPaymentInfoMale[0].gender,
        "level": jobSeekerPaymentInfoMale[0].level,
        "maxRate": jobSeekerPaymentInfoMale[0].maxRate,
        "minRate": jobSeekerPaymentInfoMale[0].minRate
      }

      let jobSeekerFemale = {
        "basePrice": jobSeekerPaymentInfoFemale[0].basePrice,
        "biddingAllowed": this.selectedBidding,
        "fixedRate": !this.selectedBidding ? jobSeekerPaymentInfoFemale[0].fixedRate : 0,
        "gender": jobSeekerPaymentInfoFemale[0].gender,
        "level": jobSeekerPaymentInfoFemale[0].level,
        "maxRate": jobSeekerPaymentInfoFemale[0].maxRate,
        "minRate": jobSeekerPaymentInfoFemale[0].minRate
      }
      // let obj = {
      //   "jobSeekerPaymentInfo": jobSeekerPaymentInfoMale[0],
      //   "jobSeekerPaymentInfo_female": jobSeekerPaymentInfoFemale[0]
      // }
      let joFulfilerType = "JOB_ADMIN";
      if (this.selectedJobSupervisor) {
        joFulfilerType = "JOB_SUPERVISOR";
      }
      if (this.selectedJobAdmin) {
        joFulfilerType = "JOB_ADMIN";
      }

      let commissionObjFemale = {
        "commissionAmount": this.selectedJobAdmin ? this.commissionForJobAdmin[0].commissionAmount : this.commissionForJobSupervisor[0].commissionAmount,
        "commissionPercentage": this.selectedJobAdmin ? this.commissionForJobAdmin[0].commissionPercentage : this.commissionForJobSupervisor[0].commissionPercentage,
        "gender": this.selectedJobAdmin ? this.commissionForJobAdmin[0].gender : this.commissionForJobSupervisor[0].gender,
      }
      let commissionObjMale = {
        "commissionAmount": this.selectedJobAdmin ? this.commissionForJobAdmin[1].commissionAmount : this.commissionForJobSupervisor[1].commissionAmount,
        "commissionPercentage": this.selectedJobAdmin ? this.commissionForJobAdmin[1].commissionPercentage : this.commissionForJobSupervisor[1].commissionPercentage,
        "gender": this.selectedJobAdmin ? this.commissionForJobAdmin[1].gender : this.commissionForJobSupervisor[1].gender,
      }

      let obj = {
        "jobFulfilerCommission": {
          "joFulfilerType": joFulfilerType,
          "femaleCommission": commissionObjFemale,
          "maleCommission": commissionObjMale
        },
        "jobSeekerPaymentInfo": jobSeekerMale,
        "jobSeekerPaymentInfo_female": jobSeekerFemale
      }
      this.service.approve(this.data.id, obj).toPromise()
        .then(data => {
          this.submitted = false;
          this.notify.showMessage('Employment Approved Successfully!', 2000, 'OK');
          this.dialogRef.close(true);
        }).catch(error => {
          this.submitted = false;
          this.notify.showMessage('Could not approve the employment!', 2000, 'OK');
        });
    }
  }

  // check required fields validation
  checkValidations() {
    let validate: boolean = false;
    if (!this.data || !this.data.id) {
      this.notify.showMessage('Something went wrong!', 2000);
      this.submitted = false;
      validate = false;
      return;
    } else {
      validate = true;
    }

    return validate;
    if (!this.selectedJobSupervisor && !this.selectedJobAdmin) {
      this.notify.showMessage(`Please select Job assigner type and it's commission`, 2000);
      this.submitted = false;
      validate = false;
      return;
    } else {
      validate = true;
    }

    if (this.selectedJobAdmin) {
      if (this.commissionForJobAdmin.length && this.commissionForJobAdmin.length == 2) {
        if (!this.commissionForJobAdmin[0].commissionAmount) {
          this.notify.showMessage(`Please add commission amount for Female`, 2000);
          this.submitted = false;
          validate = false;
          return;
        } else {
          validate = true;
        }
        if (!this.commissionForJobAdmin[0].commissionPercentage) {
          this.notify.showMessage(`Please add commission percentage for Female`, 2000);
          this.submitted = false;
          validate = false;
          return;
        } else {
          validate = true;
        }
        if (!this.commissionForJobAdmin[1].commissionAmount) {
          this.notify.showMessage(`Please add commission amount for Male`, 2000);
          this.submitted = false;
          validate = false;
          return;
        } else {
          validate = true;
        }
        if (!this.commissionForJobAdmin[1].commissionPercentage) {
          this.notify.showMessage(`Please add commission percentage for Male`, 2000);
          this.submitted = false;
          validate = false;
          return;
        } else {
          validate = true;
        }
      }
    }
    return validate;
  }

  editEmpl() {
    let jobSeekerPaymentInfoMale = this.rangeInfo.filter(ele =>
      ele.gender == 'Male'
    );
    let jobSeekerPaymentInfoFemale = this.rangeInfo.filter(ele =>
      ele.gender == 'Female'
    );
    let obj = {
      "jobSeekerPaymentInfo": jobSeekerPaymentInfoMale[0],
      "jobSeekerPaymentInfo_female": jobSeekerPaymentInfoFemale[0]
    }
    const dialogRef = this.dialog.open(AddEditEmploymentComponent, {
      width: '770px',
      panelClass: 'add-dialog',
      disableClose: true,
      data: this.data
    });

    dialogRef.afterClosed().subscribe(
      result => {
        this.dialogRef.close(false);
      }
    )
  }

  close() {
    this.dialogRef.close();
  }

  changeRange(range, forWhich?) {
    if (forWhich == 'fixed') {
      range.fixedTotal = this.getFixedTotal(range);
    }
    if (forWhich == 'min') {
      range.minTotal = this.getMinTotal(range);
    }
    if (forWhich == 'max') {
      range.maxTotal = this.getMaxTotal(range);
    }
    if (!forWhich) {
      range.minTotal = this.getMinTotal(range);
      range.maxTotal = this.getMaxTotal(range);
      range.fixedTotal = this.getFixedTotal(range);
    }
    this.changeCommissionAndFinalTotal(range);
    return false;
  }

  // change Commission And Final Total
  changeCommissionAndFinalTotal(range) {
    let amountObj = null;
    if (this.selectedJobAdmin) {
      this.commissionForJobAdmin.forEach(ele => {
        if (ele.gender == range.gender) {
          amountObj = ele;
        }
      })
    }

    if (this.selectedJobSupervisor) {
      this.commissionForJobSupervisor.forEach(ele => {
        if (ele.gender == range.gender) {
          amountObj = ele;
        }
      })
    }
    if (!this.selectedJobAdmin && !this.selectedJobSupervisor) {
      this.totalCommissionAmount.forEach(ele => {
        if (ele.gender == range.gender) {
          amountObj = ele;
        }
      })
    }
    if (amountObj)
      this.getCommissionInRs(amountObj);
  }

  // select assigner type
  selectAssigner(selectedAssigner) {
    this.submitted = false;
    if (selectedAssigner == 'JobAdmin') {
      this.selectedJobSupervisor = false;
    } else {
      this.selectedJobAdmin = false;
    }
  }

  // Get commission in RS
  getCommissionInRs(amountObj) {
    let selectedShiftPayment = null;
    this.data.employerPaymentInfos.forEach((emp: any) => {
      if (emp.gender == amountObj.gender) {
        selectedShiftPayment = emp;
        let jobseekerObj = this.rangeInfo.filter(ele =>
          ele.gender == selectedShiftPayment.gender
        );
        if (selectedShiftPayment.flatRate) {
          amountObj.commissionPercentage = (amountObj.commissionAmount * 100 / selectedShiftPayment.flatRate).toFixed(2);
        } else {
          amountObj.commissionPercentage = (amountObj.commissionAmount * 100 / selectedShiftPayment.totalShiftPayment).toFixed(2);
        }
        if (this.flatRate) {
          if (jobseekerObj && jobseekerObj.length) {
            if (!this.selectedBidding) {
              amountObj.finalShiftFixedAmount = selectedShiftPayment.flatRate - jobseekerObj[0].fixedTotal - amountObj.commissionAmount;
            } else {
              amountObj.finalShiftMinAmount = selectedShiftPayment.flatRate - jobseekerObj[0].minTotal - amountObj.commissionAmount;
              amountObj.finalShiftMaxAmount = selectedShiftPayment.flatRate - jobseekerObj[0].maxTotal - amountObj.commissionAmount;
            }
          }
        } else {
          if (jobseekerObj && jobseekerObj.length) {
            if (!this.selectedBidding) {
              amountObj.finalShiftFixedAmount = selectedShiftPayment.totalShiftPayment - jobseekerObj[0].fixedTotal - amountObj.commissionAmount;
            } else {
              amountObj.finalShiftMinAmount = selectedShiftPayment.totalShiftPayment - jobseekerObj[0].minTotal - amountObj.commissionAmount;
              amountObj.finalShiftMaxAmount = selectedShiftPayment.totalShiftPayment - jobseekerObj[0].maxTotal - amountObj.commissionAmount;
            }
          }
        }
        return;
      }
    })
  }

  // Get commission in Percentage
  getCommissionInPercentage(amountObj) {
    let selectedShiftPayment = null;
    this.data.employerPaymentInfos.forEach((emp: any) => {
      if (emp.gender == amountObj.gender) {
        selectedShiftPayment = emp;
        let jobseekerObj = this.rangeInfo.filter(ele =>
          ele.gender == selectedShiftPayment.gender
        );
        if (selectedShiftPayment.flatRate) {
          amountObj.commissionAmount = (amountObj.commissionPercentage * selectedShiftPayment.flatRate / 100).toFixed(2);
        } else {
          amountObj.commissionAmount = (amountObj.commissionPercentage * selectedShiftPayment.totalShiftPayment / 100).toFixed(2);
        }
        if (this.flatRate) {
          if (jobseekerObj && jobseekerObj.length) {
            if (!this.selectedBidding) {
              amountObj.finalShiftFixedAmount = selectedShiftPayment.flatRate - jobseekerObj[0].fixedTotal - amountObj.commissionAmount;
            } else {
              amountObj.finalShiftMinAmount = selectedShiftPayment.flatRate - jobseekerObj[0].minTotal - amountObj.commissionAmount;
              amountObj.finalShiftMaxAmount = selectedShiftPayment.flatRate - jobseekerObj[0].maxTotal - amountObj.commissionAmount;
            }
          }
        } else {
          if (jobseekerObj && jobseekerObj.length) {
            if (!this.selectedBidding) {
              amountObj.finalShiftFixedAmount = selectedShiftPayment.totalShiftPayment - jobseekerObj[0].fixedTotal - amountObj.commissionAmount;
            } else {
              amountObj.finalShiftMinAmount = selectedShiftPayment.totalShiftPayment - jobseekerObj[0].minTotal - amountObj.commissionAmount;
              amountObj.finalShiftMaxAmount = selectedShiftPayment.totalShiftPayment - jobseekerObj[0].maxTotal - amountObj.commissionAmount;
            }
          }
        }
        return;
      }
    })
  }
}

