import { AuthService } from '../services/auth.service';
import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } 
from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppService } from '../services/app.service';
import { Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {  
    
    constructor(public auth: AuthService, private app: AppService, private router: Router, private notify: NotificationService) {}  
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    return next.handle(request).pipe(
        tap(  (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              // do stuff with response if you want
            }
          }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                this.auth.collectFailedRequest(request);
                // redirect to the login route
                // or show a modal
                this.app.logout();
                this.router.navigate(['/']);
              }
            }
          }) 
          );

  }
}