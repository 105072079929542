export class EmployementPaymentOrder {
    employer_id: string;
    amount: number;
    employment_id: string;
    status: string;
    result: {
        "amount": number;
        "razorPayOrderId": string
    };
    razorpay_payment_id: string
    razorpay_order_id: string
    razorpay_signature: string
    razorpay_invoice_id?: string;
}