import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import {share} from 'rxjs/operators';
import { API_NOTIFICATION } from './util/api.endpoints';
import { ToolbarNotification } from '../interfaces/toolbar-notification.model';
import { AppService } from './app.service';
import { Cacheable } from 'ngx-cacheable';

export const NOTIFICATIONS = 'my-notifications';

@Injectable({
  providedIn: 'root'
})
export class ToolbarNotificationService {

  private user = this.appService.getCurrentAccount();
  private employer = this.appService.getEmployer();
  notifications = new BehaviorSubject<ToolbarNotification[]>(null);

    constructor(
          private http: HttpClient,
          private appService: AppService) {}


setLocalNotif() {
     this.getMyNotifications().subscribe((notif: ToolbarNotification[]) => {
       // compare list and remove duplicates
       // then mark as unread
       localStorage.setItem(NOTIFICATIONS, JSON.stringify(notif));
       this.notifications.next(notif);
        });
      }

 getLocalNotif(): ToolbarNotification[] {
     return this.notifications.getValue();
        }

 getNotifUnreadCount(): number {
  let unread = [];
  const all  = this.notifications.getValue();
  if (all != null) {
    return all.length;
     unread = all.filter(notif => notif.read === false);
  }
  // return unread.length;
  }

 
  @Cacheable()
  public getMyNotifications(): Observable<ToolbarNotification[]> {
    let url = API_NOTIFICATION + '/all';
    if(this.employer != null) {
      url += '?employerId=' + this.user.user_details.employerId ;
    }
    return this.http.get<ToolbarNotification[]>(url).pipe(share());
  }

  public markAllRead(): Observable<any> {
    const url = API_NOTIFICATION + '/role/' + this.user.roles[0];
    return this.http.put<any>(url, {}).pipe(share());
  }

}