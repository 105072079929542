import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '@visurel/iconify-angular';
import { MaterialShared } from '../shared/material-shared.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { EmployerSignupComponent } from './employer-signup/employer-signup.component';
import { ConfirmSignatureComponent } from './confirm-signature/confirm-signature.component';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SharedModule } from '../shared/shared.module';
import { SignContractComponent } from './sign-contract/sign-contract.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
  },
  {
    path: 'employer-signup',
    component: EmployerSignupComponent,
  }
];

@NgModule({
  declarations: [LoginComponent, ForgotPasswordComponent, ChangePasswordComponent, EmployerSignupComponent, ConfirmSignatureComponent, SignContractComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconModule,
    MaterialShared,
    RouterModule.forChild(routes),
    // PdfViewerModule,
    SharedModule
  ], entryComponents: [ConfirmSignatureComponent, SignContractComponent]
})

export class PublicModule { }
