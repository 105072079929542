export const roles = {
  JOB_SEEKER: 'JOB_SEEKER',
  JOB_SUPERVISOR: 'JOB_SUPERVISOR',
  SUPER_ADMIN: 'SUPER_ADMIN',
  EMPLOYER_STAFF: 'EMPLOYER_STAFF',
  JOB_ADMIN: 'JOB_ADMIN',
  EMPLOYER_ADMIN: 'EMPLOYER_ADMIN',
  ON_BOARDING_STAFF: 'ON_BOARDING_STAFF',
  PERMISSION_USER: 'PERMISSION_USER'
};
