import {BaseService} from '../base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {API_JOB_APPLICATION} from '../util/api.endpoints';
import {Injectable} from '@angular/core';
import {JobApplication} from 'src/@core/interfaces/job/job-application.model';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import { PageableResponse } from 'src/@core/interfaces/pageable-response';
import { AttendanceTracker } from 'src/@core/interfaces/job/attendance-tracker.model';
import { PaymentTracker } from 'src/@core/interfaces/job/payment-tracker.model';
import { Search } from '../../interfaces/search';

/**
 * Service response for fetch, create, update and delete the Job Application.
 */
@Injectable({
  providedIn: 'root'
})

export class JobApplicationService extends BaseService<JobApplication> {
  constructor(private http: HttpClient) {
      super(http, API_JOB_APPLICATION);
  }
  
  /**
   *  Get all details of the job applications based on provide filter
   * @param request Job Application List
   */
   getAllDetails(page: number, size: number, sort: string, order: string, searchList?: Search[], cacheKey?: any): Observable<any>{
     let params = new HttpParams();
      let url = API_JOB_APPLICATION + '/getAllDetails' + '?page=' + page;
  
      if (size != null) {
        url += '&size=' + size;
      }
      if (order !== 'desc') {
        order = 'asc';
      }
      if (sort != null) {
        url += ('&sort=' + sort + ',' + order);
      } else {
        url += ('&sort=createdOn,desc');
      }
      if (searchList && searchList.length > 0) {
        searchList.forEach((search) => {
          if (search.column && search.content) {
            params = params.append(search.column, search.content);
          }
        });
      }
      return this.http.get<any>(url, { params: params });
   }

  /**
   *  Send selected job application to the server
   * @param request Job Application List
   */
  sendForemployerReview(request: string[], employerId: string): Observable<any> {
    const url = API_JOB_APPLICATION + '/sendForemployerReview/' + employerId;
    return this.http.put<any>(url, request).pipe(share());
  }

  getAppsWAttendance(employmentId: string): Observable<any> {
    const url = API_JOB_APPLICATION + '/getAppsWAttendance/' + employmentId;
    return this.http.get<any>(url);
  }

  attendanceTracking(pageIndex: number, pageSize: number): Observable<any> {
    const url = API_JOB_APPLICATION + '/attendanceTracking?page=' + pageIndex + '&size=' + pageSize + '&sort=createdOn,desc';
    return this.http.get<PageableResponse<AttendanceTracker>>(url);
  }

  paymentTracking(pageIndex: number, pageSize: number): Observable<any> {
    const url = API_JOB_APPLICATION + '/paymentTracking?page=' + pageIndex + '&size=' + pageSize + '&sort=createdOn,desc';
    return this.http.get<PageableResponse<PaymentTracker>>(url);
  }

  paymentTrackingByJS(jobSeekerId: string, pageIndex: number, pageSize: number): Observable<PageableResponse<PaymentTracker>> {
    console.log('js ' + jobSeekerId);
    const url = API_JOB_APPLICATION + '/paymentTrackingByJS/' + jobSeekerId +'?page=' + pageIndex + '&size=' + pageSize + '&sort=createdOn,desc';
    return this.http.get<PageableResponse<PaymentTracker>>(url);
  }

  async getAttendanceLog(): Promise<Blob> {
    let url = API_JOB_APPLICATION + `/attendanceTrackerLog`;
    return await this.http.get<Blob>(url, { responseType: 'blob' as 'json' }).toPromise();
  }

  getWorkHistory(jobSeekerId: string): Observable<any[]> {
    const url = API_JOB_APPLICATION + `/getWorkHistory/${jobSeekerId}`;
    return this.http.get<any[]>(url);
  }
}
