import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit, AfterViewChecked, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { JobService } from 'src/@core/services/job/job.service';
import { Search } from 'src/@core/interfaces/search';
import { Job } from 'src/@core/interfaces/job/job.model';
import { NotificationService } from 'src/@core/services/notification.service';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { JobType } from 'src/@core/interfaces/job/job-type.model';
import { JobTypeService } from 'src/@core/services/job/job-type.service';
import { EmploymentService } from 'src/@core/services/employment/employment.service';
import { Employment } from 'src/@core/interfaces/employment/employment.model';
import { AppService } from 'src/@core/services/app.service';
import { stagger20ms } from 'src/@core/animations/stagger.animation';
import { fadeInUp400ms } from 'src/@core/animations/fade-in-up.animation';
import { fadeInRight400ms } from 'src/@core/animations/fade-in-right.animation';


@Component({
  selector: 'hour4u-admin-add-edit-employmentV2',
  templateUrl: './add-edit-employmentV2.component.html',
  styleUrls: ['./add-edit-employmentV2.component.scss'],
  animations: [fadeInRight400ms, stagger20ms, fadeInUp400ms]
})
export class AddEditEmploymentV2Component implements OnInit {

  employment: Employment;
  show: boolean = false;
  hide: boolean = true;
  form: FormGroup;
  button_name: any = 'Cancel'
  chkselect: boolean;
  stepper = 1;
  jobs: Job[];
  jobType: JobType;
  dateArr = [];
  maleLevelLabel = 'Beginner';
  femaleLevelLabel = 'Beginner';
  profile = this.appService.getCurrentAccount();
  isEmployerLogin = this.appService.getIsEmployerLogin();

  recurringDays = [
    { day: 'Every day', val: 'ALL', value: 'ALL', checked: false },
    { day: 'Every Sunday', val: 0, value: 'SUNDAY', checked: false },
    { day: 'Every Monday', val: 1, value: 'MONDAY', checked: false },
    { day: 'Every Tuesday', val: 2, value: 'TUESDAY', checked: false },
    { day: 'Every Wednesday', val: 3, value: 'WEDNESDAY', checked: false },
    { day: 'Every Thursday', val: 4, value: 'THURSDAY', checked: false },
    { day: 'Every Friday', val: 5, value: 'FRIDAY', checked: false },
    { day: 'Every Saturday', val: 6, value: 'SATURDAY', checked: false },
  ];

  briefingData = [
    { value: 'Telephonic', checked: false },
    { value: 'Videos', checked: false },
    { value: 'One to One', checked: false },
  ]

  terms = [
    { value: "7 Days", text: '7 Days selected' },
    { value: "15 Days", text: '15 Days selected' },
    { value: "30 Days", text: '30 Days selected' },
    { value: "45 Days", text: '45 Days selected' }
  ]

  constructor(
    private jobService: JobService,
    private notify: NotificationService,
    private _fb: FormBuilder,
    private jobTypeService: JobTypeService,
    private employmentService: EmploymentService,
    private appService: AppService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnDestroy(): void {
    this.cdRef.detach();
  }


  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
 }


  ngOnInit() {
    this.employment = new Employment()
  }

  onView(){

  }


   /**
   * Job display function for list
   * @param job 
   */
  jobDisplayFn(job: Job) {
    return job ? job.title : job;
  }


   /**
  * Job Search and select
  * @param term 
  */
 async onJobSearch(term) {
  const params: Search[] = [];
  params.push( new Search('title', term));
  if (this.isEmployerLogin || this.profile.user_details.employerId != null){
    params.push( new Search('employerId', this.profile.user_details.employerId));
  }
  
  this.jobService.searchAndSort(0, 10, null, null, params).subscribe(data => {
    this.jobs = data.content;
  }, error => {
    this.notify.showMessage("No Employer Found!", 2000);
  });
}

/**
   * On Job Select pass the employer id of job into current form
   * @param job 
   */
  jobSelected(job: Job) {
    // setting select job's employer id for employment
    this.form.controls.employerId.setValue(job.employerId);
    this.getJobType(job);
  }

  /**
   * Get job type for selected job
   */
  getJobType(job: Job) {
    if (job.jobTypeId)
      this.jobTypeService.getById(job.jobTypeId).subscribe(type => this.jobType = type);
  }



}
