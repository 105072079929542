import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, of as observableOf } from "rxjs";
import { fadeInRight400ms } from "src/@core/animations/fade-in-right.animation";
import { fadeInUp400ms } from "src/@core/animations/fade-in-up.animation";
import { stagger40ms } from "src/@core/animations/stagger.animation";
import { Attendance } from "src/@core/interfaces/job/attendance.model";
import { PaymentAttendance } from "src/@core/interfaces/payment/payment-attendance.model";
import { Payment } from "src/@core/interfaces/payment/payment.model";
import { Rating } from "src/@core/interfaces/rating/rating.model";
import { Search } from "src/@core/interfaces/search";
import { AppService } from "src/@core/services/app.service";
import { NotificationService } from "src/@core/services/notification.service";
import { SelectionModel } from "@angular/cdk/collections";
import { WorkLogService } from "src/@core/services/employment/worklog.service";
import { EmployementPaymentOrder } from "src/@core/interfaces/employment/employement-payment-order.model";
import { EmployeerPaymentOrderService } from "src/@core/services/employment/employment-payment-order.service";
import { environment } from "src/environments/environment";
import { STATUS } from "src/app/shared/helpers/status";
import { roles } from "src/app/shared/helpers/roles";
import { Employment } from "src/@core/interfaces/employment/employment.model";
import { EmploymentService } from "src/@core/services/employment/employment.service";
import { JobService } from "src/@core/services/job/job.service";
declare var Razorpay: any;

@Component({
    selector: 'hour4u-admin-view-employment-payments',
    templateUrl: './view-employment-payments.component.html',
    styleUrls: ['./view-employment-payments.component.scss'],
    animations: [
        fadeInUp400ms,
        stagger40ms,
        fadeInRight400ms]
})

export class ViewEmploymentPaymentsComponent implements OnInit {
    employmentId: string;
    employmentTitle: string;
    employerId: string;
    jobTitle: string;
    date: any;
    searchParams: Search[] = [];
    searchFilters: Search[] = [];
    dataSourcePayment: MatTableDataSource<Payment> | null;
    dataSourceRating: MatTableDataSource<Rating> | null;
    dataSourceAttendance: MatTableDataSource<Attendance> | null;
    dataSource: any = null;
    dataChange: BehaviorSubject<any>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    pageSize = 10;
    pageSizeOptions: number[] = [10, 20, 50, 100];
    resultsLength = 0;
    selection = new SelectionModel<PaymentAttendance>(true, []);
    columns = [
        { columnDef: 'checkbox', visible: true, label: '' },
        { columnDef: 'userName', visible: true, label: 'Job Seeker' },
        { columnDef: 'checkIn', visible: true, label: 'In Time' },
        { columnDef: 'checkOut', visible: true, label: 'Out Time' },
        { columnDef: 'hoursWorked', visible: true, label: 'Duration' },
        { columnDef: 'agreedAmount', visible: true, label: 'Agreed Amount' },
        { columnDef: 'payableAmount', visible: true, label: 'Payable Amount' },
        { columnDef: 'remarks', visible: true, label: 'Remarks' },
        { columnDef: 'status', visible: true, label: 'Status' },
        { columnDef: 'rate', visible: true, label: 'Rating' },
        { columnDef: 'action', visible: true, label: 'Action' },
    ];
    displayedColumns = this.columns.map(x => x.columnDef);
    currentUserRole: any = null;
    starList: boolean[] = [true, true, true, true, true];
    level = '';
    rating: number;
    workLogList: any;
    allRowsSelected: boolean = false;
    allRecordsRate: any = null;
    isLoading: boolean = false;
    mode = 'indeterminate';
    diameter = 30;
    color: string;
    status = STATUS;
    roles = roles;
    employment: Employment;
    showExpand: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private notify: NotificationService,
        private appService: AppService,
        private workLogService: WorkLogService,
        private employeerPaymentOrderService: EmployeerPaymentOrderService,
        private employmentService: EmploymentService,
        public router: Router,
        private jobService: JobService
    ) { }

    ngOnInit() {
        this.employmentId = this.route.snapshot.paramMap.get('id');
        this.loadEmploymentData(this.employmentId);
        this.route.queryParams.subscribe(params => {
            this.jobTitle = params['jobTitle'];
            this.employmentTitle = params['employmentTitle'];
            this.employerId = params['employerId'];
            this.date = params['date'];
        });
        this.searchParams = [];
        this.searchParams.push(new Search('employmentId', this.employmentId));
        this.dataChange = new BehaviorSubject(0);
        this.dataSource = new MatTableDataSource();
        this.dataSourcePayment = new MatTableDataSource();
        this.dataSourceAttendance = new MatTableDataSource();

        this.appService.currentUser.subscribe(currentUser => {
            currentUser.roles.forEach(ele => {
                this.currentUserRole = ele;
            })
        })

        this.getAllWorkLogs();
    }

    async loadEmploymentData(id: string) {
        await this.employmentService.getById(id).toPromise()
            .then(data => {
                this.employment = data;
                console.log(this.employment)
                this.loadJobs();
            })
    }

    // Get job from server
    loadJobs() {
        this.jobService.getById(this.employment.jobId).subscribe(job => {
            this.employment.job = job;
        });
    }

    // get all work-log paymnet request
    getAllWorkLogs() {
        this.isLoading = true;
        document.getElementById('application-pending-section').style.display = 'none';
        this.workLogService.getWorkLogList(this.employmentId).then((res: any) => {
            this.isLoading = false;
            this.dataSource.data = [];
            // this.workLogList = null;
            this.workLogList = res;
            if (res && res.status != 'CREATED') {
                res.workLogItem.forEach(element => {
                    element.starList = [];
                    // element.rating = null;
                    this.starList.forEach(ele => {
                        let obj = Object.assign({}, ele);
                        element.starList.push(obj)
                    })
                    if (element.rating)
                        this.setStar(element.rating - 1, element);
                });
                this.dataSource.data = res.workLogItem;
                console.log("this.dataSource.data", this.dataSource.data)
            }

            if (this.dataSource.data && this.dataSource.data.length == 0) {
                // document.getElementById('application-pending-section').style.display = 'block';
            }
        }).catch((err: any) => {
            this.isLoading = false;
            console.log(err);
        })
    }

    // get total duration
    getTotalDuration() {
        let duration = 0;
        if (this.dataSource.data && this.dataSource.data.length != 0) {
            this.dataSource.data.forEach((ele: any) => {
                duration += ele.hoursWorked;
            })
        }
        return duration.toFixed(2);
    }

    // get total agreed amount
    getPayableAmount() {
        let payableAmount = 0;
        if (this.dataSource.data && this.dataSource.data.length != 0) {
            this.dataSource.data.forEach((ele: any) => {
                payableAmount += ele.payableAmount;
            })
        }
        return payableAmount.toFixed(2);
    }

    // get total agreed amount
    getPayableAmountWithGST() {
        let payableAmount = 0;
        if (this.dataSource.data && this.dataSource.data.length != 0) {
            this.dataSource.data.forEach((ele: any) => {
                payableAmount += ele.payableAmount;
            })
        }
        payableAmount = (payableAmount * 1.18);
        return payableAmount.toFixed(2);
    }

    // get already paid amount
    getAlreadyPaidAmount() {
        let alreadyPaidAmount = 0;
        if (this.workLogList && this.workLogList.paidAmount) {
            alreadyPaidAmount += this.workLogList.paidAmount;
        }
        return alreadyPaidAmount.toFixed(2);
    }

    // get total additional payable amount
    getAdditionalPayableAmount() {
        let totalPayableAmount = 0;
        if (this.dataSource.data && this.dataSource.data.length != 0) {
            this.dataSource.data.forEach((ele: any) => {
                totalPayableAmount += ele.payableAmount;
            })
        }
        if (totalPayableAmount != 0) {
            let amount = (totalPayableAmount * 1.18) - this.workLogList.paidAmount;
            return amount;
        } else {
            return totalPayableAmount;
        }
    }

    // Select all rows 
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
        this.isAllSelected() ? this.allRowsSelected = true : this.allRowsSelected = false;

    }

    logSelection() {
        this.selection.selected.forEach(s => console.log(s.jobSeekerId));
    }

    // set single record rating
    setStar(index: any, data?: any) {
        for (var i = 0; i <= 4; i++) {
            if (i <= index) {
                data.starList[i] = false;
            }
            else {
                data.starList[i] = true;
            }
        }
        if (index > -1 && index < 2) this.level = 'Fair'
        else if (index > 1 && index < 4) this.level = 'Good'
        else this.level = 'Excellent'
        data.rating = index + 1;
    }

    // set rating for all records
    setRateForAll(index: any) {
        for (var i = 0; i <= 4; i++) {
            if (i <= index) {
                this.starList[i] = false;
            }
            else {
                this.starList[i] = true;
            }
        }
        if (index > -1 && index < 2) this.level = 'Fair'
        else if (index > 1 && index < 4) this.level = 'Good'
        else this.level = 'Excellent'
        this.rating = index + 1;
    }

    // Approve individual record
    async approveIndividualRecord(data: any) {
        // let workLogItem = [];
        this.workLogList.workLogItem.forEach(ele => {
            ele.status = data.id == ele.id ? 'APPROVED' : ele.status;
        });
        // this.dataSource.data.forEach(element => {
        //     workLogItem.push({
        //         "id": element.id,
        //         "status": data.id == element.id ? 'APPROVED' : element.status,
        //         "paymentRequestId": element.paymentRequestId,
        //         "agreedAmount": element.agreedAmount,
        //         "payableAmount": element.payableAmount,
        //         "remarks": element.remarks,
        //         "userName": element.userName,
        //         "checkIn": element.checkIn,
        //         "checkOut": element.checkOut,
        //         "hoursWorked": element.hoursWorked,
        //         "rating": element.rating
        //     })
        // });
        await this.submitForApproval();
    }


    // Appvoe all and pay for all records
    async approveAndPayAmount() {
        this.workLogList.rating = this.rating;
        this.workLogList.workLogItem.forEach(ele => {
            ele.status = 'APPROVED';
            ele.rating = this.rating;
        });
        // let workLogItem = [];
        // this.dataSource.data.forEach(element => {
        //     workLogItem.push({
        //         "id": element.id,
        //         "status": 'APPROVED',
        //         "paymentRequestId": element.paymentRequestId,
        //         "agreedAmount": element.agreedAmount,
        //         "payableAmount": element.payableAmount,
        //         "remarks": element.remarks,
        //         "userName": element.userName,
        //         "checkIn": element.checkIn,
        //         "checkOut": element.checkOut,
        //         "hoursWorked": element.hoursWorked,
        //         "rating": element.rating ? element.rating : this.rating
        //     })
        // });
        await this.submitForApproval(true);
    }

    // submit for approve record
    submitForApproval(forAll?: boolean) {
        if (this.workLogList.status != 'APPROVED') {
            // let obj = {
            //     "id": this.workLogList.id,
            //     "createdBy": this.workLogList.createdBy,
            //     "updatedBy": this.workLogList.updatedBy,
            //     "createdOn": this.workLogList.createdOn,
            //     "updatedOn": this.workLogList.updatedOn,
            //     "isDelete": this.workLogList.isDelete,
            //     "deleteBy": this.workLogList.deleteBy,
            //     "status": this.workLogList.status,
            //     "employmentId": this.employmentId,
            //     "employerId": this.employerId,
            //     "workLogItem": workLogItem,
            //     "paidAmount": this.workLogList.paidAmount,
            //     "rating": this.rating
            // }
            this.workLogService.updateWorkLog(this.employmentId, this.workLogList).then(res => {
                this.notify.showMessage('Work log approved successfully!', 2000);
                this.getAllWorkLogs();
                if (forAll) {
                    this.payAmount();
                }
            }).catch((err: any) => {
                this.notify.showMessage('Something went wrong!', 2000);
            })
        } else {
            this.payAmount();
            this.notify.showMessage('This work log already approved!', 2000);
        }
    }

    // Payment option open razorpay
    payAmount() {
        this.isLoading = true;
        let paymentOrderRequest = new EmployementPaymentOrder();
        paymentOrderRequest.amount = this.getAdditionalPayableAmount();
        paymentOrderRequest.employer_id = this.employerId;
        paymentOrderRequest.employment_id = this.employmentId;

        if (paymentOrderRequest.amount >= 0) {
            this.employeerPaymentOrderService.create(paymentOrderRequest).toPromise().then(
                (order: any) => {
                    this.isLoading = false;
                    if (order != null && order.status == 'SUCCESS') {
                        this.razorPayOptions.amount = order.result.amount * 100;
                        this.razorPayOptions.currency = 'INR';
                        this.razorPayOptions.key = environment.rezorpayKey;
                        this.razorPayOptions.order_id = order.result.razorPayOrderId;
                        var rzp = new Razorpay(this.razorPayOptions);
                        this.getAllWorkLogs();
                        rzp.open()
                        rzp.on('payment.failed', function (response) {
                            alert(response.error.code);
                            alert(response.error.description);
                            alert(response.error.source);
                            alert(response.error.step);
                            alert(response.error.reason);
                            alert(response.error.metadata.order_id);
                            alert(response.error.metadata.payment_id);
                        });
                    } else {
                        this.notify.showMessage("Something went wrong!", 3000);
                    }
                }).catch(err => {
                    this.isLoading = false;
                    this.notify.showMessage('This work log already approved!Please pay from Invoice', 3000);
                    console.log(err)
                })
        } else {
            this.notify.showMessage("Please select valid rate", 3000);
            this.isLoading = false;
        }
    }

    // Payment options
    razorPayOptions = {
        "key": '',
        "amount": 0,
        "currency": '',
        "name": '',
        "discription": "hours4u payment",
        "order_id": '',
        "handler": (response) => {
            let paymentOrderRequest = new EmployementPaymentOrder();
            paymentOrderRequest.razorpay_order_id = response.razorpay_order_id;
            paymentOrderRequest.razorpay_payment_id = response.razorpay_payment_id;
            paymentOrderRequest.razorpay_signature = response.razorpay_signature;
            this.employeerPaymentOrderService.update(response.razorpay_order_id, paymentOrderRequest).toPromise().then(res => {
                this.notify.showMessage("Paid work logs amount successfully!", 3000);
                this.getAllWorkLogs();
            })
        }
    };

    dataRefresh() {
        this.router.navigateByUrl('/employment/all/all');
    }
}