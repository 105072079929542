import { HttpClient } from '@angular/common/http';
import { API_GAI } from '../util/api.endpoints';
import { Injectable } from '@angular/core';
import { Gai } from "src/@core/interfaces/gai/gai.model";

/**
 * Service response to get GAI API response.
 */

@Injectable({
  providedIn: 'root'
})
export class GaiService {
  promptText: string = "You are an employer. Generate job description for below details. Mention mandatory details in job title. The response should not be more than 100 words. In response do not mention about total word count or position summary/title details. Response should not have any heading\n\n.";
  constructor(private http: HttpClient) {
  }

  async getGaiResponse(inputText: string, responseWordLimit: number) {
    var gai = new Gai();
    gai.prompt = this.promptText + inputText;
    gai.responseWordLimit = responseWordLimit;
    const url = API_GAI;
    return this.http.post<any>(url, gai).toPromise();
  }

}