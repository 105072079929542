import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,

  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { ProgressBarService } from '../services/progress-bar.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private app: AppService, private router: Router,
    private progressService: ProgressBarService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = "";
    if (req.url.indexOf('/oauth/') === -1 && !req.url.includes('auth/employer-signup-otp')) {
      const token = this.app.getToken();
      const contentType = req.url.indexOf('/fileupload') >= 0 ? 'multipart/form-data' : 'application/json; charset=utf-8';
      let changeReg = req.clone({
        url: url + req.url,
        headers: req.headers
          .set('Content-Type', 'application/json; charset=utf-8')
          .set('Accept', 'application/json; charset=utf-8')
          .set('Authorization', token)
      });
      if (req.url.indexOf('/upload') >= 0 || req.url.indexOf('/fileupload') >= 0) {
        changeReg = req.clone({
          url: url + req.url,
          headers: req.headers
            .set('Authorization', token)
        });
      }
      this.progressService.show();
      return next.handle(changeReg).pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.progressService.hide();
          }
        }, (error) => {
          this.progressService.hide();
        })
      );
    } else {
      this.progressService.show();
      const changeReg = req.clone({ url: url + req.url });
      return next.handle(changeReg).pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.progressService.hide();
          }
        }, (error) => {
          this.progressService.hide();
        })
      );
    }
  }
}

