import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmploymentActionService } from 'src/@core/services/employment/employment-action.service';
import { NotificationService } from 'src/@core/services/notification.service';


@Component({
  selector: 'hour4u-admin-activate-employment',
  templateUrl: './activate-employment.component.html',
  styleUrls: ['./activate-employment.component.scss']
})

export class ActivateEmploymentComponent implements OnInit {

  submitted: boolean = false;
  notify = {
    notifyEmployer: true,
    notifyJobSeeker: true
  }
  constructor(public dialogRef: MatDialogRef<ActivateEmploymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: EmploymentActionService,
    private notification: NotificationService,
  ) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.submitted = true;
    if (!this.data || !this.data.id) {
      this.notification.showMessage('Something went wrong!', 2000);
      this.submitted = false;
      return;
    }

    this.service.activate(this.data.id, this.notify).subscribe(data => {
    }, (error) => {
      this.submitted = false;
      this.notification.showMessage('Could not activate the employment!', 2000);
    }, () => {
      this.submitted = false;
      this.notification.showMessage('Employment Activated Successfully!', 2000);
      this.dialogRef.close(true);
    })
  }

}

