import { Component, OnInit } from '@angular/core';
import { EmploymentService } from 'src/@core/services/employment/employment.service';
import { Employment } from 'src/@core/interfaces/employment/employment.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Job } from 'src/@core/interfaces/job/job.model';
import { JobService } from 'src/@core/services/job/job.service';
import { EmployerService } from 'src/@core/services/employer/employer.service';
import { Employer } from 'src/@core/interfaces/employer/employer.model';
import { fadeInRight400ms } from 'src/@core/animations/fade-in-right.animation';
import { scaleIn400ms } from 'src/@core/animations/scale-in.animation';
import { AccountService } from 'src/@core/services/account.service';
import { EmploymentAdministration } from 'src/@core/interfaces/employment/employment-administration.model';
import { UserAccount } from 'src/@core/interfaces/account.model';
import { EmploymentAdministrationService } from 'src/@core/services/employment/employment-administration.service';
import { roles } from 'src/app/shared/helpers/roles';

@Component({
  selector: 'hour4u-admin-view-employment',
  templateUrl: './view-employment.component.html',
  styleUrls: ['./view-employment.component.scss'],
  animations: [fadeInRight400ms, scaleIn400ms]
})
export class ViewEmploymentComponent implements OnInit {

  data: Employment = new Employment();
  empAdmin: EmploymentAdministration;
  job: Job;
  employer: Employer;
  //account: Account;
  user: UserAccount;
  roles = roles;


  constructor(
    private service: EmploymentService,
    private jobService: JobService,
    private employerService: EmployerService,
    private accountService: AccountService,
    private empService: EmploymentAdministrationService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(data => {
      this.data.id = data.id;
      this.loadData();
    });
  }

  /**
   * load employment data according to the id from params
   */
  async loadData() {
    this.service.getById(this.data.id).subscribe(employment => {
      if (employment) {
        this.data = employment;
        this.loadJobs();
        this.loadEmployers();
      }
    });
    await this.loadEmploymentAdminisData();
  }

  async loadEmploymentAdminisData() {
    await this.empService.getByEmploymentId(this.data.id).toPromise()
      .then(async data => {
        this.empAdmin = data;
        if (this.empAdmin != null) {
          this.accountService.getById(this.empAdmin.jobAdminId).subscribe(jobAdmin => {
            this.empAdmin.jobAdmin = jobAdmin;
          });
          this.accountService.getById(this.empAdmin.supervisorId).subscribe(sup => {
            this.empAdmin.jobSupervisor = sup;
          });
        }
      });

  }

  /**
   * Get job from server
   */
  loadJobs() {
    this.jobService.getById(this.data.jobId).subscribe(job => {
      this.job = job;
    });
  }

  /**
   * Get emplyer from server to show
   */
  loadEmployers() {
    this.employerService.getById(this.data.employerId).subscribe(employer => {
      this.employer = employer;
    });
  }

  /**
   * Get employment rates
   */
  getRates(gender) {
    let val = 0;
    this.data.employerPaymentInfos.forEach(x => {
      if (x.gender == gender) {
        if (this.data.flatRate) {
          val = x.flatRate;
        } else {
          val = x.maxRate;
        }
      }
    });
    return val;
  }

  checkDays() {
    const arr = this.data.recurringDays.filter(x => x == 'ALL');
    return arr.length > 0;
  }

  redirectToJobSeekerList(id: string, date: Date) {
    this.router.navigate(['/employment/active/' + this.data.id, {
      date: date
    }])
  }
}


