import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddEditJobComponent } from './add-edit-job/add-edit-job.component';
import { NotificationService } from 'src/@core/services/notification.service';
import { ProgressBarService } from 'src/@core/services/progress-bar.service';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
  MatPaginator,
  MatSort,
  MatTableDataSource
} from '@angular/material';
import { BehaviorSubject, merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { fadeInUp400ms } from 'src/@core/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@core/animations/stagger.animation';
import { ColumnVisibilityDialogComponent } from 'src/app/shared/components/column-visibility-dialog/column-visibility-dialog.component';
import { Search } from 'src/@core/interfaces/search';
import { fadeInRight400ms } from 'src/@core/animations/fade-in-right.animation';
import { JobService } from 'src/@core/services/job/job.service';
import { Job } from 'src/@core/interfaces/job/job.model';
import { AppService } from 'src/@core/services/app.service';
import { Employer } from 'src/@core/interfaces/employer/employer.model';
import { AddEditEmploymentComponent } from '../../employment/employment/add-edit-employment/add-edit-employment.component';
import { roles } from 'src/app/shared/helpers/roles'
import { JobTypeService } from 'src/@core/services/job/job-type.service';
import { JobCategoryService } from 'src/@core/services/job/job-category.service';
import { NavigationExtras, Router } from '@angular/router';
import { LocalStorageService } from 'src/@core/services/local-storage.service';

// export let paginator: MatPaginator;

@Component({
  selector: 'hour4u-admin-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms,
    fadeInRight400ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    }
  ]
})

export class JobComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  term = '';
  isSearch = true;
  isEmployerLogin = this.appService.getIsEmployerLogin();
  dataSource: MatTableDataSource<Job>;
  dataChange: BehaviorSubject<any>;
  roles = roles

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  resultsLength = 0;

  title = 'Job';
  columns = [
    { columnDef: 'position', visible: true, label: 'Position' },
    { columnDef: 'title', visible: true, label: 'Title' },
    { columnDef: 'categoryId', visible: true, label: 'Category' },
    { columnDef: 'employerId', visible: true, label: 'Employer' },
    { columnDef: 'createdOn', visible: true, label: 'Created' },
    { columnDef: 'action', visible: true, label: 'Action' },
  ];
  displayedColumns = this.columns.map(x => x.columnDef);

  // employer: Employer = this.appService.getEmployer();
  profile = this.appService.getCurrentAccount();
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  prevUrl: String;

  searchParams: Array<Search> = [];
  currentUserRole: any = null;
  loginUserDetails: any = null;
  row: any;
  sort_field: string = '';
  showTemplates: boolean = false;

  diameter = 40;
  color: string;
  mode = 'indeterminate';

  constructor(private dialog: MatDialog,
    private service: JobService,
    private jobTypeService: JobTypeService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private notify: NotificationService,
    private progress: ProgressBarService,
    private appService: AppService,
    private _localStorageService: LocalStorageService) {

    if (this.router.getCurrentNavigation().previousNavigation) {
      this.prevUrl = this.router.getCurrentNavigation().previousNavigation.finalUrl.toString();
    }
  }

  ngOnInit() {
    this.dataChange = new BehaviorSubject(0);
    this.dataSource = new MatTableDataSource();
    this.getCurrentRole();
  }

  getCurrentRole() {
    this.appService.currentUser.subscribe(currentUser => {
      this.loginUserDetails = currentUser;
      currentUser.roles.forEach(ele => {
        this.currentUserRole = ele;
      })
    })
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterViewInit(): void {
    if (this.isEmployerLogin || this.profile.user_details.employerId != null) {
      this.searchParams.push(new Search('employerId', this.profile.user_details.employerId));
    }
    else {
      setTimeout(() => {
        if (this.prevUrl && this.prevUrl.includes('job/view') && this._localStorageService.getJobListPage()) {
          this.paginator.pageIndex = this._localStorageService.getJobListPage();
        }
        else {
          this.paginator.pageIndex = 0;
          this._localStorageService.setJobListPage(0);
        }

        this.reloadTable();
      }, 0);
    }

    if (this._localStorageService.getTSortParams()) {
      this.sort_field = this._localStorageService.getTSortParams();
    }

    if (this._localStorageService.getTSearchParams().length > 0) {
      this.searchParams = this._localStorageService.getTSearchParams();
      this.setSearchPrams();
    }

    this.searchParams.push(new Search('isDelete', false));
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
    merge(this.sort.sortChange, this.paginator.page, this.dataChange)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.progress.show();
          // tslint:disable-next-line:no-non-null-assertion
          return this.service!.searchAndSort(
            this.paginator.pageIndex, this.paginator.pageSize,
            this.sort.active, this.sort.direction, this.searchParams, 'Job');
        }),
        map(data => {
          this.progress.hide();
          this.resultsLength = data.totalElements;
          return data.content;
        }),
        catchError(() => {
          this.progress.hide();
          return observableOf([]);
        })
      ).subscribe((data: any) => {
        this._localStorageService.setJobListPage(this.paginator.pageIndex);
        this._localStorageService.setTSearchParams(this.searchParams);
        this._localStorageService.setTSortParams(this.sort.direction);
        this.dataSource.data = data;
        console.log("$%%%%%%%%%", data)
        this.showTemplates = true;
        this.progress.hide();
      });

    if (this.profile.roles.indexOf(roles.EMPLOYER_STAFF) > -1 || this.profile.roles.indexOf(roles.EMPLOYER_ADMIN) > -1) {
      this.columns = this.columns.map(x => {
        if (x.columnDef == 'employerId')
          x.visible = false;
        return x;
      });
      this.displayedColumns = this.columns.filter(x => x.visible).map(x => x.columnDef);
    }

  }

  reloadTable() {
    this.dataChange.next(1);
  }

  search(data?) {
    this.searchParams = [];
    if (data) {
      this.searchParams.push(new Search('title', data));
    }
    // this.searchParams.push(new Search('code', data));
    this.dataChange.next(1);
  }
  sortData(direction) {
    this.sort.active = 'createdOn';
    this.sort.direction = direction.value;
    this.sort.sortChange.next();
  }

  create(): void {
    this.router.navigate(['/add-edit-job-template']);
    return;
    const data = {
      title: 'Create Job Template'
    };

    const dialogRef = this.dialog.open(AddEditJobComponent, {
      width: '100vw',
      height: '100vh',
      disableClose: true,
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataChange.next(result.id);
      }
    });
  }

  clone(object: any): void {
    object.id = null;
    object.status = null;
    object.cloned = true;
    const data = {
      title: 'Clone Template',
      data: object
    };
    const navigationExtras: NavigationExtras = {
      state: { data: JSON.stringify(data) },
    };
    this.router.navigate(['add-edit-job-template'], navigationExtras);
    return;
    const dialogRef = this.dialog.open(AddEditJobComponent, {
      width: '700px',
      disableClose: true,
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      this.dataChange.next(1);
    });
  }

  update(object: any): void {
    console.log(object)
    this.router.navigate(['/add-edit-job-template/' + object.id]);
    return;
    const data = {
      title: 'Modify Job',
      data: object
    };
    const dialogRef = this.dialog.open(AddEditJobComponent, {
      width: '700px',
      disableClose: true,
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataChange.next(result.id);
      }
    });
  }


  openDialogColumnOptions(): void {
    let col = this.columns;
    if (this.profile.roles.indexOf(roles.EMPLOYER_STAFF) > -1 || this.profile.roles.indexOf(roles.EMPLOYER_ADMIN) > -1) {
      col = this.columns.filter(x => x.columnDef != 'employerId')
    }

    const dialogRef = this.dialog.open(ColumnVisibilityDialogComponent, {
      width: '300px',
      data: col,
    });
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.displayedColumns = [];
          this.columns.forEach((value, key) => {
            if (value.visible) {
              this.displayedColumns.push(value.columnDef);
            }
          });
        }
      }
    );
  }

  ngOnDestroy(): void {
    // throw new Error("Method not implemented.");
    if (!this.router.url.includes('job/view')) {
      this._localStorageService.setJobListPage(0);
      this._localStorageService.setJobListPage(0);
      this._localStorageService.setTSearchParams([]);
      this._localStorageService.setTSortParams(null);
    }
  }

  createEmployment(data: any): void {
    let obj = Object.assign({}, data);
    obj.jobId = data.id;
    obj.cloned = true;
    obj.id = null;
    obj.from = 'Job Template'
    const dialogRef = this.dialog.open(AddEditEmploymentComponent, {
      width: '770px',
      disableClose: true,
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataChange.next(1);
      }
    });
  }

  viewJob(id) {
    this.router.navigateByUrl('/job/view/' + id);
  }

  // Delete template
  deleteTemplate(row) {
    this.service.deleteJobTemplate(row.id).subscribe(res => {
      this.dataChange.next(1);
    })
  }

  setSearchPrams() {
    this.searchParams.forEach((search) => {
      if (search.column && search.content) {
        if (search.column == "title") {
          this.term = search.content
          this.isSearch = true;
        }
      }
    })
  }
}

