import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { API_EMPLOYEE_ATTENDANCE } from "../util/api.endpoints";
import { Attendance } from 'src/@core/interfaces/job/attendance.model';
import { BaseService } from '../base.service';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AttendanceService extends BaseService<Attendance> {
  constructor(private http: HttpClient) {
    super(http, API_EMPLOYEE_ATTENDANCE);
  }

  SAupdate(id: string, request: Attendance): Observable<any> {
    const url = API_EMPLOYEE_ATTENDANCE + `/SAupdate/${id}`;
    return this.http.put<any>(url, request).pipe(share());
  }

  SAcreate(request: Attendance): Observable<any> {
    const url = API_EMPLOYEE_ATTENDANCE + `/SAcreate`;
    return this.http.post<any>(url, request);
  }

}
