import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { JobService } from 'src/@core/services/job/job.service';
import { JobTypeService } from 'src/@core/services/job/job-type.service';

@Pipe({
  name: 'getJobType'
})
export class JobTypePipe implements PipeTransform {

  constructor(
    private service: JobTypeService
  ) { }

  transform(val: any | null) {
    if (!val) {
      return;
    }
    return this.service.getById(val).pipe(
      map((data) => {
        if (data) {
          return data.jobCategoryName;
        }
      })
    );
  }
}
