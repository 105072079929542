import { Component, OnInit } from '@angular/core';
import { trackById } from '../../../utils/track-by';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icNotificationsOff from '@iconify/icons-ic/twotone-notifications-off';
import icClearAll from '@iconify/icons-ic/twotone-clear-all';
import { ToolbarNotificationService, NOTIFICATIONS } from 'src/@core/services/toolbar-notification.service';
import { ToolbarNotification } from 'src/@core/interfaces/toolbar-notification.model';
import { Router } from '@angular/router';

@Component({
  selector: 'codemiro-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss']
})
export class ToolbarNotificationsDropdownComponent implements OnInit {

  icSettings = icSettings;
  icChevronRight = icChevronRight;
  icClearAll = icClearAll;
  icNotificationsOff = icNotificationsOff;
  trackById = trackById;

  notifications: ToolbarNotification[];

  constructor(private notificationService: ToolbarNotificationService,
    private router: Router) { }

  ngOnInit() {
    this.notifications = this.notificationService.getLocalNotif();
  }

  markAllRead() {
    this.notificationService.markAllRead().subscribe(data => {
      localStorage.removeItem(NOTIFICATIONS);
      this.notifications = [];
    });
  }

}
