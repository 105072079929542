import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IconModule } from '@visurel/iconify-angular';
import { ColorFadeModule } from 'src/@core/pipes/color/color-fade.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseTableViewComponent } from './base-table/base-table.component';
import { BaseMatTableComponent } from './base-mat-table.component';
import { BaseDialogCurdComponent } from './base-dialog-curd';
import { CodemiroHeaderComponent } from './codemiro-header/codemiro-header.component';
import { MaterialShared } from '../material-shared.module';
import { SharedModule } from '../shared.module';

@NgModule({
  declarations:[
    BaseTableViewComponent,
    BaseMatTableComponent,
    BaseDialogCurdComponent,
    CodemiroHeaderComponent,
  ],
  imports: [
    FlexLayoutModule,
    IconModule,
    ColorFadeModule,
    MaterialShared,
    FormsModule,
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
  ],
  exports: [
    FlexLayoutModule,
    IconModule,
    ColorFadeModule,
    FormsModule,
    ReactiveFormsModule,

    // components
    BaseTableViewComponent,
    BaseMatTableComponent,
    BaseDialogCurdComponent,
    CodemiroHeaderComponent,    
  ],
  entryComponents: [
    BaseDialogCurdComponent
  ]
})

export class CurdBaseModule { }


