export const STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  INPROGRESS: 'Inprogress',
  COMPLETED: 'Completed',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
  DRAFT: 'Draft',
  ALL: 'All',
  CANCELLED: 'Cancelled'
};

export const PAYMENTSTATUSNAMES = {
  ADVANCE_PAYMENT_PENDING: 'No advanced paid',
  ADVANCE_PAYMENT_SUCCESS: 'Advanced paid',
  WORKLOG_SENT: 'Worklog Sent',
  INVOICE_GENERATED: 'Invoice Generated',
  INVOICE_PAID: 'Invoice Paid',
  REFUND_INITIATED: 'Refund Initiated',
  REFUND_COMPLETED: 'Refund Completed',
};

export const PAYMENTSTATUS = {
  ADVANCE_PAYMENT_PENDING: 'ADVANCE_PAYMENT_PENDING',
  ADVANCE_PAYMENT_SUCCESS: 'ADVANCE_PAYMENT_SUCCESS',
  WORKLOG_SENT: 'WORKLOG_SENT',
  INVOICE_GENERATED: 'INVOICE_GENERATED',
  INVOICE_PAID: 'INVOICE_PAID',
  REFUND_INITIATED: 'REFUND_INITIATED',
  REFUND_COMPLETED: 'REFUND_COMPLETED',
};
