import { Address } from "../address.model";

export class Attendance {
  
    id: string;
    checkIn: Date;
    checkOut: Date;
    createdBy: string;
    createdOn: Date;
    deleteBy: true;
    employmentId: string;
    employerId: string;
    isCompleted: true;
    isDelete: true;
    jobSeekerId: string;
    jobSeekerName: string;
    totalRecordedTime: any;
    updatedBy: string;
    updatedOn: Date;
    address: Address;

  }