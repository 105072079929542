import {
  Component,
  Input
} from '@angular/core';

@Component({
  selector: 'icon',
  styleUrls: ['./icon.component.scss'],
  template: `
      <svg>
        <use attr.xlink:href="assets/symbol-defs.svg#{{icon}}"></use>
      </svg>
    `
})
export class IconComponent {
  @Input() icon: string;
}
