import { Component, OnInit } from '@angular/core';
import { Job } from 'src/@core/interfaces/job/job.model';
import { JobService } from 'src/@core/services/job/job.service';
import { ActivatedRoute } from '@angular/router';
import { fadeInRight400ms } from 'src/@core/animations/fade-in-right.animation';
import { scaleIn400ms } from 'src/@core/animations/scale-in.animation';
import { Employer } from 'src/@core/interfaces/employer/employer.model';
import { EmployerService } from 'src/@core/services/employer/employer.service';
import { JobType } from 'src/@core/interfaces/job/job-type.model';
import { JobTypeService } from 'src/@core/services/job/job-type.service';
import { roles } from 'src/app/shared/helpers/roles';

@Component({
  selector: 'hour4u-admin-view-job',
  templateUrl: './view-job.component.html',
  styleUrls: ['./view-job.component.scss'],
  animations: [fadeInRight400ms, scaleIn400ms]
})
export class ViewJobComponent implements OnInit {

  data: Job = new Job();
  employer: Employer;
  jobType: JobType;
  roles = roles;
  constructor(
    private jobService: JobService,
    private employerService: EmployerService,
    private jobTypeService: JobTypeService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(data => {
      this.data.id = data.id;
      this.loadData();
    });
  }

  /**
   * Get job from server
   */
  loadData() {
    this.jobService.getById(this.data.id).subscribe(job => {
      this.data = job;
      this.loadEmployers();
      this.loadJobType();
    })
  }

  /**
   * Get emplyer from server to show
   */
  loadEmployers() {
    if (this.data.employerId) {
      this.employerService.getById(this.data.employerId).subscribe(employer => {
        this.employer = employer;
      })
    }
  }

  /**
   * Get emplyer from server to show
   */
  loadJobType() {
    this.jobTypeService.getById(this.data.jobTypeId).subscribe(type => {
      this.jobType = type;
    })
  }

}
