import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { API_JOB_SEEKER_RATING} from "../util/api.endpoints";
import { BaseService } from '../base.service';
import { Rating } from 'src/@core/interfaces/rating/rating.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobSeekerRatingService extends BaseService<Rating> {
  constructor(private http: HttpClient) {
    super(http, API_JOB_SEEKER_RATING);
  }

  getJSRatingForShift(employmentId: String, jobSeekerId: String): Observable<any> {
    let url = API_JOB_SEEKER_RATING + `/getRateForShift/${employmentId}/${jobSeekerId}`;
    return this.http.get<any>(url);
  }
  
  updateJSRating(id, request): Observable<Rating> {
    let url = API_JOB_SEEKER_RATING + `/${id}`;
    return this.http.put<Rating>(url, request);
  }

  createBulkRating(request: Rating): Observable<Rating> {
    let url = API_JOB_SEEKER_RATING + '/bulkrating';
    return this.http.post<Rating>(url, request);
  }

  getRatingStatus(id): Observable<any> {
    let url = API_JOB_SEEKER_RATING + `/stats/${id}`;
    return this.http.get<any>(url);
  }
}
