import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { CREATE_EMPLOYEMENT_ORDER } from '../util/api.endpoints';
import { Injectable } from '@angular/core';
import { EmployementPaymentOrder } from 'src/@core/interfaces/employment/employement-payment-order.model';
/**
 * Service response for fetch, create, update and delete the Employment.
 */
@Injectable({
  providedIn: 'root'
})

export class EmployeerPaymentOrderService extends BaseService<EmployementPaymentOrder> {
  constructor(private http: HttpClient) {
    super(http, CREATE_EMPLOYEMENT_ORDER);
  }
}
