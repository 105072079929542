import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Contact } from '../job-seeker-list/job-seeker-list.component';
import theme from 'src/@core/utils/tailwindcss';
import { JobSeeker } from '../../../../@core/interfaces/job-seeker/job-seeker.model';
import { MatDialog } from '@angular/material';
import { ProfileApprovalDialogComponent } from '../profile-approval-dialog/profile-approval-dialog.component';
import { ProfileRejectDialogComponent } from '../profile-reject-dialog/profile-reject-dialog.component';
import { scaleIn400ms } from 'src/@core/animations/scale-in.animation';
import { fadeInRight400ms } from 'src/@core/animations/fade-in-right.animation';
import { stagger40ms } from 'src/@core/animations/stagger.animation';
import { fadeInUp400ms } from 'src/@core/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@core/animations/scale-fade-in.animation';
import { roles } from 'src/app/shared/helpers/roles';
import { API_CHAMP } from 'src/@core/services/util/api.endpoints';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'codemiro-job-seeker-card',
  templateUrl: './job-seeker-card.component.html',
  styleUrls: ['./job-seeker-card.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms,
    stagger40ms,
    fadeInUp400ms,
    scaleFadeIn400ms
  ]
})
export class JobSeekerCardComponent implements OnInit {

  @Input() jobSeeker: JobSeeker;
  @Input() mode: string;
  @Output() viewJobSeeker = new EventEmitter();
  //@Output() reloadData = new EventEmitter();
  @Output() jsSelectionChange = new EventEmitter();

  color = theme.colors.green;
  redColor = theme.colors.red;
  purpleColor = theme.colors.purple;
  yellowColor = theme.colors.orange;

  roles = roles;
  baseImageUrl = environment.image_url;




  constructor(
    private dialog: MatDialog,
    private http: HttpClient
  ) { }

  ngOnInit() {

  }
  deleteChamp(champId: string): Observable<void> {
    return this.http.delete<void>(`${API_CHAMP}/${champId}`);
  }
  deleteJobSeeker(id: string) {
    // Call the service function to delete the champ
    this.deleteChamp(id).subscribe(
      () => {
        // Successfully deleted
        console.log(`Successfully deleted champ with id: ${id}`);
      },
      error => {
        // Handle any errors here
        console.error('An error occurred while deleting the champ:', error);
      }
    );
  }


  getColor(status) {
    switch (status) {
      case 'Pending': this.purpleColor['500']; break;
      case 'Rejected': this.redColor['500']; break;
      case 'INVITED': this.yellowColor['500']; break;
      default: this.color['500']; break;
    }
  }

  /*openApproveDialog() {
    const dialogRef =  this.dialog.open(ProfileApprovalDialogComponent, {
      width: '600px',
      disableClose: true,
      data: this.jobSeeker
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reloadData.emit();
      } 
    });
  }

  openRejectDialog() {
    const dialogRef =  this.dialog.open(ProfileRejectDialogComponent, {
      width: '500px',
      disableClose: true,
      data: this.jobSeeker
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reloadData.emit();
      }
    });
  }*/

  selectedJS(event, id) {
    this.jsSelectionChange.emit(event);
  }

  imageErrorHandler(event) {
    if (this.jobSeeker.gender === 'Male') {
      event.target.src = './assets/img/avatars/male_avatar.svg';
    } else {
      event.target.src = './assets/img/avatars/female_avatar.svg';
    }
  }
}
