import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from '../@core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { MainLayoutModule } from './main-layout/main-layout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialShared } from './shared/material-shared.module';
import { IconModule } from '@visurel/iconify-angular';
import { AsyncPipe } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { JobPipe } from 'src/@core/pipes/data/job.pipe';
import * as Chart from 'chart.js';
import { ChartModule } from 'src/@core/components/chart/chart.module';

import { BrowserModule } from '@angular/platform-browser';

import { environment } from '../environments/environment';
import { CheckoutComponent } from './modules/employment/employment/checkout/checkout.component';
import { HelpVideosModule } from './modules/help-videos/help-videos.module';
import { AddChampsComponent } from './modules/supervisor-admin/champs/add-champs/add-champs.component';
import { AddEditShiftComponent } from './modules/employment/employment/add-edit-shift/add-edit-shift.component';
import { InvoicePayComponent } from './modules/employment/invoice-pay/invoice-pay.component';
import { SuccessScreenComponent } from './modules/modals/success-screen/success-screen.component';
import { FailScreenComponent } from './modules/modals/fail-screen/fail-screen.component';
import { SocietiesComponent } from './modules/societies/societies.component';
import { PageLayoutModule } from 'src/@core/components/page-layout/page-layout.module';
import { SecondaryToolbarModule } from 'src/@core/components/secondary-toolbar/secondary-toolbar.module';
import { SocietiesModule } from './modules/societies/societies.module';
import { AvailablePermissionsComponent } from './modules/available-permissions/available-permissions.component';
import { LightboxModule } from 'ngx-lightbox';
import { BookSocietyDialogComponent } from './modules/societies/book-society-dialog/book-society-dialog.component';
import { OrderAndInvoicesComponent } from './modules/order-and-invoices/order-and-invoices/order-and-invoices.component';
import { ViewOrderComponent } from './modules/order-and-invoices/order-and-invoices/view-order/view-order.component';


@NgModule({
  declarations: [AppComponent, CheckoutComponent, AddChampsComponent, AddEditShiftComponent, InvoicePayComponent, SuccessScreenComponent, FailScreenComponent, SocietiesComponent, AvailablePermissionsComponent, OrderAndInvoicesComponent, ViewOrderComponent],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    IconModule,
    FormsModule,
    MaterialShared,
    ReactiveFormsModule,

    // Core
    CoreModule,
    MainLayoutModule,
    //Chart
    ChartModule,
    BrowserModule,
    HelpVideosModule,
    PageLayoutModule,
    SecondaryToolbarModule,
    SocietiesModule,
    LightboxModule,

  ],
  exports: [
    CoreModule,
    AgmCoreModule
  ],
  providers: [AsyncPipe, DatePipe, JobPipe,
    //{ provide: CONFIG, useValue: { sendPageView: true } }
  ],
  entryComponents: [CheckoutComponent, AddChampsComponent, InvoicePayComponent, BookSocietyDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
