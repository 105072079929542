import { ActivatedRouteSnapshot } from '@angular/router';
import { CodemiroRouteData } from '../interfaces/theme/codemiro-route.interface';

export function checkRouterChildsData(route: ActivatedRouteSnapshot & { data?: CodemiroRouteData }, compareWith: (data: CodemiroRouteData) => boolean) {
  if (compareWith(route.data)) {
    return true;
  }

  if (!route.firstChild) {
    return false;
  }

  return checkRouterChildsData(route.firstChild, compareWith);
}
