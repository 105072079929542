import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from '@angular/material';
import { BehaviorSubject, merge, of as observableOf} from 'rxjs';
import { BaseService } from 'src/@core/services/base.service';
import { NotificationService } from 'src/@core/services/notification.service';
import { ProgressBarService } from 'src/@core/services/progress-bar.service';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { DeleteEvent } from 'src/@core/interfaces/delete-event';
import { ConfirmDeleteDialogComponent } from '../components/confirm-delete-dialog/confirm-delete-dialog.component';

@Component({
  selector: 'codemiro-base-mat-list',
  template: ''
})
export class BaseMatTableComponent<T, U> implements OnInit, OnDestroy {

  dataSource: MatTableDataSource<T>;
  dataChange: BehaviorSubject<number>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  
  resultsLength: number;
  dialog: MatDialog;
  service: BaseService<T>;
  notify: NotificationService;
  progress: ProgressBarService;
  

  

  constructor( 
    dialog: MatDialog,
    service: BaseService<T>,
    notify: NotificationService,
    progress: ProgressBarService,
    paginator: MatPaginator
     ) { 
      this.dialog = dialog;
      this.service = service;
      this.notify = notify;
      this.progress = progress; 
      this.paginator = paginator;
     }

  ngOnInit() {
    this.dataChange = new BehaviorSubject(0);
    this.dataSource = new MatTableDataSource();
  }

  reloadTable() {
    this.dataChange.next(1);
  }
  
  ngAfterViewInit(): void {
    this.sort = new MatSort();
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator!.page, this.dataChange)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.service.searchAndSort(
            this.paginator.pageIndex, this.paginator.pageSize,
            this.sort.active, this.sort.direction, null);
        }),
        map(data => {
          this.resultsLength = data.totalElements;
          return data.content;
        }),
        catchError((error) => {
          return observableOf([]);
        })
      ).subscribe(data => this.dataSource.data = data );
  }

  openDialogNewObject(component): void {
    const data = {};
    const dialogRef = this.dialog.open(component, {
      width: '700px',
      disableClose: true,
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataChange.next(result.id);
      }
    });
  }

  // openDialogColumnOptions(): void {
  //   const dialogRef = this.dialog.open(ColumnOptionsStoreComponent, {
  //     data: this.columns
  //   });
  //   dialogRef.afterClosed().subscribe(
  //     result => {
  //       if (result) {
  //         this.displayedColumns = [];
  //         _.forOwn(this.columns, (value, key) => {
  //           if (value.selected) {
  //             this.displayedColumns.push(key);
  //           }
  //         });
  //       }
  //       localStorage.setItem(COLMN_OPTN, JSON.stringify(this.displayedColumns));
  //     }
  //   );
  // }

  openDialogEditing(component, data: any): void {
    const dialogRef = this.dialog.open(component, {
      width: '700px',
      disableClose: true,
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataChange.next(result.id);
      }
    });
  }


  openDialogConfirmDelete(object: any): void {
    const data = new DeleteEvent();
    data.setId(object.id);
    // data.setType('Tax');
    data.setName(object.taxCode);
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      disableClose: true,
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._delete(result);
      }
    });
  }

  _delete(t: any): void {
    this.service.delete(t.id).subscribe(
      data => {
        this.dataChange.next(0);
      },
      error => {
        this.dataChange.next(error);
      },
      () => {
        this.dataChange.next(1);
        this.notify.showMessage('Deleted !', 3000);
      }
    );
  }

  ngOnDestroy() {
    this.dataChange.unsubscribe();
  }

}
