import { Pipe, PipeTransform } from '@angular/core';
import { validateExtension } from 'showdown';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
  transform(val: any | null): any {
    if (!val || val.length < 3) {
      return '';
    }

    if (typeof val === 'string') {
      val = val.split(',');
    }

    let newDate;
    try {
      newDate = new Date(val[0], val[1] - 1, val[2]);
    } catch {
      return;
    }
    return newDate;
  }

}
