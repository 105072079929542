import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/@core/services/notification.service';
import { HttpClient } from '@angular/common/http';
import { API_PROPERTY, API_PROPERTY_DOCUMENT } from 'src/@core/services/util/api.endpoints';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'hour4u-admin-add-edit-societies',
  templateUrl: './add-edit-societies.component.html',
  styleUrls: ['./add-edit-societies.component.scss']
})
export class AddEditSocietiesComponent implements OnInit {
  form: FormGroup;
  errorInstepOne: boolean;
  submitted: boolean;
  selectedImages: File[] = [];
  thumbnails: { file: HTMLImageElement, id: number }[] = [];
  batchIndex: number = 0;
  isEditMode: boolean = false;
  societyId: string | null = null;
  uploadedImageUrls: string[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _fb: FormBuilder,
    private notify: NotificationService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.route.params.subscribe((params) => {
      this.societyId = params['id'];
      if (this.societyId) {
        this.isEditMode = true;
        this.fetchSocietyDetails(this.societyId);
      }
    });
  }

  initializeForm() {
    this.form = this._fb.group({
      name: ['', Validators.required],
      numberOfFlats: ['', Validators.required],
      location: [{}],
      rentPerDay: ['', Validators.required],
      contactName: ['', Validators.required],
      contactMobile: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      email: [''],
      numberOfGates: [''],
      startDate: [''],
      endDate: [''],
      timeFrom: [''],
      timeTo: [''],
      images: [[]],
      availableSlots: this._fb.array([this.createAvailableSlotsFormGroup()])
    });
  }

  createAvailableSlotsFormGroup() {
    return this._fb.group({
      startDate: [''],
      endDate: [''],
      timeFrom: [''],
      timeTo: [''],
    });
  }

  returnToSocieties() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  onSave() {
    if (this.form.valid) {
      const formData = this.form.value;
      formData.images = [...this.selectedImages, ...this.uploadedImageUrls];

      if (this.isEditMode) {
        this.http.put(API_PROPERTY + `/${this.societyId}`, formData).subscribe(
          (response) => {
            console.log('Society updated successfully:', response);
            this.submitted = true;
            this.router.navigate(['../../'], { relativeTo: this.route });
          },
          (error) => {
            console.error('Error updating society:', error);
            this.notify.error('Failed to update society. Please try again later.');
          }
        );
      } else {
        this.http.post(API_PROPERTY, formData).subscribe(
          (response) => {
            console.log('Society added successfully:', response);
            this.submitted = true;
            this.returnToSocieties();
          },
          (error) => {
            console.error('Error adding society:', error);
            this.notify.error('Failed to add society. Please try again later.');
          }
        );
      }
    } else {
      this.notify.error('Please correct the form errors.');
    }
  }

  setAddress(data) {
    this.form.get('location').setValue(data);
  }

  onFileSelected(event: any) {
    const files: FileList = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (file && file.type.startsWith('image/')) {
        this.displayThumbnail(file);
        this.compressAndUpload(file);
      }
    }
    this.batchIndex++;
  }

  // compressAndUpload(file: File) {
  //   const canvas = document.createElement('canvas');
  //   const ctx = canvas.getContext('2d');
  //   const maxWidth = 1800;
  //   const maxHeight = 1800;
  //   const image = new Image();

  //   image.onload = () => {
  //     let width = image.width;
  //     let height = image.height;

  //     if (width > maxWidth || height > maxHeight) {
  //       const ratio = Math.min(maxWidth / width, maxHeight / height);
  //       width *= ratio;
  //       height *= ratio;
  //     }

  //     canvas.width = width;
  //     canvas.height = height;

  //     ctx.drawImage(image, 0, 0, width, height);

  //     canvas.toBlob(
  //       (blob) => {
  //         const compressedFile = new File([blob], file.name, { type: 'image/jpeg' });

  //         this.uploadProfileImage(compressedFile, this.batchIndex).subscribe(
  //           (response) => {
  //             console.log(response)
  //             this.selectedImages.push(response.key);
  //             const selectedIndex = this.selectedImages.indexOf(response.key);
  //             this.removeUploadingText(selectedIndex);
  //           },
  //           (error) => {
  //             console.error('Error uploading image:', error);
  //             const selectedIndex = this.selectedImages.length - 1;
  //             this.removeUploadingText(selectedIndex);
  //           }
  //         );
  //       },
  //       'image/jpeg',
  //       0.6
  //     );
  //   };

  //   image.src = URL.createObjectURL(file);
  // }


  compressAndUpload(file: File) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const maxWidth = 1800;
    const maxHeight = 1800;
    const image = new Image();
    const uploadingTextDiv = this.createUploadingTextElement();

    image.onload = () => {
      let width = image.width;
      let height = image.height;

      if (width > maxWidth || height > maxHeight) {
        const ratio = Math.min(maxWidth / width, maxHeight / height);
        width *= ratio;
        height *= ratio;
      }

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(image, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          const compressedFile = new File([blob], file.name, { type: 'image/jpeg' });

          this.uploadProfileImage(compressedFile, this.batchIndex).subscribe(
            (response) => {
              console.log('S3 Upload Success:', response);
              this.selectedImages.push(response.key);
              const selectedIndex = this.selectedImages.indexOf(response.key);

              // Remove the uploading text after receiving a response from S3
              uploadingTextDiv.remove();
            },
            (error) => {
              console.error('Error uploading image to S3:', error);

              // Remove the uploading text in case of an error
              uploadingTextDiv.remove();
            }
          );
        },
        'image/jpeg',
        0.6
      );
    };

    image.src = URL.createObjectURL(file);
  }

  createUploadingTextElement(): HTMLElement {
    const uploadingTextDiv = document.createElement('div');
    uploadingTextDiv.className = 'uploading-text';
    uploadingTextDiv.textContent = 'Uploading...';

    const thumbnailContainer = document.querySelector('.image-thumbnails');
    if (thumbnailContainer) {
      thumbnailContainer.appendChild(uploadingTextDiv);
    }

    return uploadingTextDiv;
  }

  removeUploadingText(index: number) {
    const thumbnailContainer = document.querySelector('.image-thumbnails');
    if (thumbnailContainer) {
      const thumbnailElement = thumbnailContainer.children[index];
      if (thumbnailElement) {
        const uploadingTextElement = thumbnailElement.querySelector('.uploading-text');
        if (uploadingTextElement) {
          uploadingTextElement.remove();
        }
      }
    }
  }

  deleteThumbnail(index: number) {
    // Remove the image at the specified index from the selectedImages array
    this.selectedImages.splice(index, 1);

    // Remove the thumbnail from the thumbnails array
    this.thumbnails.splice(index, 1);
  }


  displayThumbnail(file: File) {
    const thumbnailContainer = document.querySelector('.image-thumbnails');
    if (thumbnailContainer) {
      const thumbnailDiv = document.createElement('div');
      thumbnailDiv.className = 'image-thumbnail';

      const uploadingTextDiv = document.createElement('div');
      uploadingTextDiv.className = 'uploading-text';
      //uploadingTextDiv.textContent = 'Uploading...';

      const image = document.createElement('img');
      image.src = URL.createObjectURL(file);
      image.alt = 'Thumbnail';
      image.style.width = '84px';
      image.style.height = '84px';
      image.style.objectFit = 'cover';
      image.style.borderRadius = '12px';

      // const deleteButton = document.createElement('button');
      // deleteButton.className = 'mat-icon-button';
      // deleteButton.style.color = 'warn';
      // deleteButton.textContent = 'delete';
      // deleteButton.addEventListener('click', (event) => {
      //   const index = this.thumbnails.length - 1; // Capture the correct index here
      //   console.log('Delete button clicked for newly selected image at index:', index);
      //   this.deleteImage(index);
      // });

      thumbnailDiv.appendChild(uploadingTextDiv);
      thumbnailDiv.appendChild(image);
      //thumbnailDiv.appendChild(deleteButton);

      thumbnailContainer.appendChild(thumbnailDiv);

      // image.onload = () => {
      //   uploadingTextDiv.remove();
      // };

      // deleteButton.addEventListener('click', () => {
      //   this.deleteImage(this.thumbnails.length - 1);
      // });
    }
  }

  // deleteImage(index: number) {
  //   if (index >= 0 && index < this.thumbnails.length) {
  //     // Remove the image at the specified index from the selectedImages array
  //     this.selectedImages.splice(index, 1);

  //     // Remove the thumbnail from the thumbnails array
  //     this.thumbnails.splice(index, 1);

  //     // Remove the thumbnail from the HTML
  //     const thumbnailContainer = document.querySelector('.image-thumbnails');
  //     if (thumbnailContainer) {
  //       const thumbnailElement = thumbnailContainer.children[index];
  //       if (thumbnailElement) {
  //         thumbnailElement.remove();
  //       }
  //     }
  //   }
  // }


  uploadProfileImage(file: File, index): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('profile', file, file.name);

    return this.http.post(API_PROPERTY_DOCUMENT, formData, {
      reportProgress: true
    });
  }

  fetchSocietyDetails(societyId: string) {
    this.http.get<any>(`${API_PROPERTY}/${societyId}`).subscribe(
      (response) => {
        this.form.patchValue({
          name: response.name,
          numberOfFlats: response.numberOfFlats,
          email: response.email || '',
          numberOfGates: response.numberOfGates || '',
          location: response.location,
          rentPerDay: response.rentPerDay,
          contactName: response.contactName,
          contactMobile: response.contactMobile,
          startDate: response.startDate,
          endDate: response.endDate,
          timeFrom: response.timeFrom,
          timeTo: response.timeTo,
          availableSlots: response.availableSlots
        });

        this.loadImagesFromS3(response.images);
      },
      (error) => {
        console.error('Error fetching society details:', error);
        this.notify.error('Failed to fetch society details. Please try again later.');
      }
    );
  }

  loadImagesFromS3(imageUrls: string[]) {
    this.thumbnails = [];
    this.uploadedImageUrls = [...imageUrls];

    imageUrls.forEach((imageUrl, index) => {
      const img = new Image();
      img.onload = () => {
        this.thumbnails.push({ file: img, id: index });
        // this.addDeleteButton(index);
      };
      img.src = environment.image_url + imageUrl;
      this.displayS3Image(img.src);
    });
  }

  // addDeleteButton(index: number) {
  //   const thumbnailContainer = document.querySelector('.image-thumbnails');
  //   if (thumbnailContainer) {
  //     const s3ImageDiv = thumbnailContainer.children[index];
  //     if (s3ImageDiv) {
  //       const deleteButton = document.createElement('button');
  //       deleteButton.className = 'mat-icon-button';
  //       deleteButton.style.color = 'warn';
  //       deleteButton.textContent = 'delete';
  //       deleteButton.addEventListener('click', () => {
  //         this.deleteImage(index);
  //       });
  //       s3ImageDiv.appendChild(deleteButton);
  //     }
  //   }
  // }

  displayS3Image(imageUrl: string) {
    const s3ImageHtml = `
      <div class="s3-images">
        <img src="${imageUrl}" alt="Thumbnail"
          style="width: 84px; height: 84px; object-fit: cover; border-radius: 12px;">
      </div>
    `;

    const thumbnailContainer = document.querySelector('.image-thumbnails');
    if (thumbnailContainer) {
      thumbnailContainer.insertAdjacentHTML('beforeend', s3ImageHtml);
    }
  }
}
