/// <reference types="@types/googlemaps" />
import { Employment } from 'src/@core/interfaces/employment/employment.model';
import { EmploymentHistory } from 'src/@core/interfaces/employment/employment-history.model';
import { ChangeDetectorRef, Component, Inject, Input, NgZone, OnInit } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { NotificationService } from 'src/@core/services/notification.service';
import { JobApplication } from 'src/@core/interfaces/job/job-application.model';
// import {} from 'googlemaps';
declare var google: any;


@Component({
  selector: 'hour4u-admin-employment-map-view',
  templateUrl: './employment-map-view.component.html',
  styleUrls: ['./employment-map-view.component.scss']
})
export class EmploymentMapViewComponent implements OnInit {

  @Input('employment') employment: Employment;
  @Input('jobApplications') jobApplications: JobApplication[];

  zoom: number;
  isSnazzyInfoWindowOpened = false;

  markers: any[] = [];

  private geoCoder;
  lat = 18.5204;
  lng = 73.8567;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private notify: NotificationService,
    @Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {

    this.markers.push({
      title: 'Job : ' + this.employment.job.title,
      lat: this.employment.location.latitude,
      lng: this.employment.location.longitude
    });


    // set markers 
    this.jobApplications.forEach(js => {
      if (js.history != null) {
        if (js.history.locationHistories != null && js.history.locationHistories.length > 0) {
          this.markers.push({
            title: js.tempJobSeeker.name,
            lat: js.history.locationHistories[0].latitude,
            lng: js.history.locationHistories[0].longitude,
          });

          if (js.history.locationHistories.length > 1) {
            this.markers.push({
              title: js.tempJobSeeker.name,
              lat: js.history.locationHistories[js.history.locationHistories.length - 1].latitude,
              lng: js.history.locationHistories[js.history.locationHistories.length - 1].longitude,
            });
          }
        }
      }

    });

  }

  onMouseOver(infoWindow, gm) {

    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }

    gm.lastOpen = infoWindow;

    infoWindow.open();
  }

}
