import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { JobSeekerRatingService } from 'src/@core/services/rating/job-seeker-rating.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatCheckboxChange } from '@angular/material';
import { JobSeekerService } from 'src/@core/services/job-seeker/job-seeker.service';
import { JobSeeker } from 'src/@core/interfaces/job-seeker/job-seeker.model';
import { NotificationService } from 'src/@core/services/notification.service';
import { PaymentService } from 'src/@core/services/payment/payment.service';
import { Router } from '@angular/router';

@Component({
  selector: 'hour4u-admin-give-bulk-rating-dialog',
  templateUrl: './give-bulk-rating-dialog.component.html',
  styleUrls: ['./give-bulk-rating-dialog.component.scss']
})
export class GiveBulkRatingDialogComponent implements OnInit {

  starList: boolean[] = [true,true,true,true,true];       // create a list which contains status of 5 stars
  rate:number;  
  jobSeeker: JobSeeker;
  level = ''
  form: FormGroup;
  reviewTypes: string[] = [];
  jobSeekerList:JobSeeker[] = [];

  constructor(
    public dialogRef: MatDialogRef<GiveBulkRatingDialogComponent>,
    private _fb: FormBuilder,
    private service: JobSeekerRatingService,
    private jobSeekerService: JobSeekerService,
    private notify: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private paymentService: PaymentService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loadJobSeekerData()
    this.createForm();
  }

  loadJobSeekerData() {
    this.data.jobApplication.forEach(application => {
      this.jobSeekerService.getById(application.jobSeekerId).subscribe(data => {
        this.jobSeekerList.push(data);
      })
    });
    this.jobSeekerList.map(x => x.selected = false);
    
  }

  /**
   * Creating for using form builder for Job
   */
  createForm() {
    this.form = this._fb.group({
      rating: [[], [Validators.required, Validators.min(1)]],
      jobSeekerIds: [[], [Validators.required, Validators.min(1)]],
      employerId: ['', Validators.required],
      employmentId: ['', Validators.required],
      review: ['', Validators.required],
      reviewTypes: [[]],
    });
  }

  setStar(data:any){
    this.rate=data+1;                               
    for(var i=0;i<=4;i++){  
      if(i<=data){  
        this.starList[i]=false;  
      }  
      else{  
        this.starList[i]=true;  
      }  
   }  

   if(data > -1 && data < 2) this.level = 'Fair' 
   else if(data > 1 && data < 4) this.level = 'Good' 
   else this.level = 'Excellent' 
   this.form.controls.rating.setValue(data + 1); 

} 

  async onSubmit() {

    if(!this.data.employment.employer.id) return;
    
    let data = this.form.value;
    data.employerId = this.data.employment.employer.id
    data.employmentId = this.data.employment.id
    data.reviewTypes = this.reviewTypes;
    data.jobSeekerIds = [];
    this.jobSeekerList.forEach(seek => {
      if(seek.selected) {
        data.jobSeekerIds.push(seek.id)
      }
    });


    if(data.jobSeekerIds.length == 0) {
      this.notify.showMessage("Select minmum one Job Seeker", 2000);
      return;
    }

    await this.service.createBulkRating(data).toPromise().then(res => {
      this.notify.showMessage("Rating added successfully !", 2000);
        //approve paymennts
        this.paymentService.approvePayments({
          employmentId: data.employmentId,
          jobSeekerIds: data.jobSeekerIds
        }).subscribe(approvalResponse => {
          this.notify.showMessage("Payments approved ... ", 2000);
          this.close();
        }, error => {
          this.notify.showMessage("Failed to approve payments !", 2000);
        })
        
    }).catch(error => {
      this.notify.showMessage("Failed to add rating !", 2000);
    });

  }

  close() {
    this.dialogRef.close();
  }


  reviewTypeChang(event: MatCheckboxChange, type: string) {
    if(event.checked) {
      // add into array
      this.reviewTypes.push(type);
    } else {
      // remove value from array
      this.reviewTypes = this.reviewTypes.splice(this.reviewTypes.indexOf('type'), 1);
    }
  }

  jobSeekerSelected(event: MatCheckboxChange, id: string) {
    // this.jobSeekerList.map(x => x.id == id ? { ...x,  selected: event.checked } : x )
    let seekerList = [];
      this.jobSeekerList.forEach( seeker => {
        if(seeker.id == id) {
          seeker.selected = event.checked;
        }
        seekerList.push(seeker);
      })
      this.jobSeekerList = seekerList;
  }

  selectAllJobSeeker(event: MatCheckboxChange) {
      this.jobSeekerList.map(x => x.selected = event.checked)
  }
}
