import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { JobSeekerService } from 'src/@core/services/job-seeker/job-seeker.service';

@Pipe({
  name: 'getJobSeeker'
})
export class JobSeekerPipe implements PipeTransform {

  constructor(
    private service: JobSeekerService
  ) { }

  transform(val: any | null) {
    if (!val) {
      return;
    }
    return this.service.getById(val).pipe(
      map((data) => {
        return data ? data.name : '';
      })
    );
  }
}
