import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Rating } from 'src/@core/interfaces/rating/rating.model';
import { NotificationService } from 'src/@core/services/notification.service';
import { PaymentService } from 'src/@core/services/payment/payment.service';
import { JobSeekerRatingService } from 'src/@core/services/rating/job-seeker-rating.service';

@Component({
  selector: 'hour4u-admin-payment-status-dialog',
  templateUrl: './payment-status-dialog.component.html',
  styleUrls: ['./payment-status-dialog.component.scss']
})
export class PaymentStatusDialogComponent implements OnInit {

  settledAmount = 0;
  rate: number;
  level = '';
  starList: boolean[] = [true, true, true, true, true];
  isSubmitted: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PaymentStatusDialogComponent>,
    private service: PaymentService,
    private jobSeekerRatingService: JobSeekerRatingService,
    private notify: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.settledAmount = data.settledAmount == null || data.settledAmount == 0 ?
      data.expectedAmount == null || data.expectedAmount == 0 ?
        data.amount : data.expectedAmount : data.settledAmount;
    this.rate = data.rate;
    this.setStar(this.rate - 1);
  }

  ngOnInit() {
  }

  setStar(data: any) {
    this.rate = data + 1;
    for (var i = 0; i <= 4; i++) {
      if (i <= data) {
        this.starList[i] = false;
      }
      else {
        this.starList[i] = true;
      }
    }

    if (data > -1 && data < 2) this.level = 'Fair'
    else if (data > 1 && data < 4) this.level = 'Good'
    else this.level = 'Excellent'

  }


  onSubmit() {
    this.isSubmitted = true;
    if (!this.settledAmount) {
      this.notify.showMessage("Settled amount should not be empty.", 2000);
      this.isSubmitted = false;
      return;
    }
    console.log(this.data, this.settledAmount)
    this.service.updatePaymentStatus(this.data.id, 'Approved', this.settledAmount).subscribe(data => {
      this.isSubmitted = false;
      this.notify.showMessage("Status updated successfully !", 2000);
      let ratingData: Rating = new Rating();
      ratingData.employmentId = this.data.employmentId;
      ratingData.employerId = this.data.employerId;
      ratingData.jobSeekerId = this.data.jobSeekerId;
      ratingData.rating = this.rate;
      if (this.rate) {
        if (this.data.ratingId != null) {
          this.jobSeekerRatingService.updateJSRating(this.data.ratingId, ratingData).subscribe(rdata => { })
        }
        else
          this.jobSeekerRatingService.create(ratingData).subscribe(rdata => { })
      }
    }, (error) => {
      this.isSubmitted = false;
      this.notify.showMessage("Failed to update status !", 2000);
    }, () => {
      this.isSubmitted = false;
      let obj = {
        settledAmount: this.settledAmount,
      }
      this.dialogRef.close(obj);
    })
  }

  close() {
    this.dialogRef.close();
  }

}
